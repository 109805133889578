import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getVendors } from '../actions/admin'
import { useHistory } from 'react-router-dom'
import '../styles/table.css'
import KuroLink from '../components/common/KuroLink'
import DeleteBox from '../components/DeleteBox'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const InwardInvoice = ({ match, user: { token, userDetails }, admin: { vendors, accesslevels, bgDetails }, getVendors }) => {

    let history = useHistory()
    const { params: { invoiceid, entity } } = match
    const [invoicedata, setinvoicedata] = useState(null)
    const [updatedinvdata, setupdatedinvdata] = useState({})
    const [editflag, seteditflag] = useState(false)
    const [loaddeletebox, setloaddeletebox] = useState(false)
    const [deleteid, setdeleteid] = useState("")
    const [tagselectall, settagselectall] = useState(false)
    const selectAllRef = useRef(null);
    const access = accesslevels.find(item => item.inward_invoices !== "NA")

    const invoicetypes = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Bank Charges",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    const tagOptionsHandler = (type) => {
        const tagOptions = {
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS Machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        };
        return tagOptions[type] || [];
    }
    useEffect(() => {
        if (!access) {
            history.push('/unauthorized')
        }
    }, [entity])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices?invoiceid=' + invoiceid, config).then(res => {
            setinvoicedata(res.data[0])
            setupdatedinvdata(res.data[0])
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [invoiceid, getVendors])

    useEffect(() => {
        if (selectAllRef.current && updatedinvdata) {
            if (updatedinvdata.tags.length === 0) {
                selectAllRef.current.indeterminate = false;
                selectAllRef.current.checked = false;
            } else if (updatedinvdata.tags.length === tagOptionsHandler(updatedinvdata.invoice_type).length) {
                selectAllRef.current.indeterminate = false;
                selectAllRef.current.checked = true;
            } else {
                selectAllRef.current.indeterminate = true;
            }
        }
    }, [updatedinvdata.tags, updatedinvdata.invoice_type]);



    const toggleselectall = () => {
        let tempinvoice = { ...updatedinvdata }
        let selectedTags = []
        if (tagselectall) {
            settagselectall(false);
            selectedTags = []
        } else {
            tagOptionsHandler(tempinvoice.invoice_type).map((tag) => {
                selectedTags.push(tag)
            })
            settagselectall(true)
        }
        tempinvoice.tags = selectedTags;
        setupdatedinvdata(tempinvoice)
    }
    const editinvoice = () => {
        setupdatedinvdata(invoicedata)
        seteditflag(true)
    }

    const updateTag = (tag, index) => {
        let selectedTags = updatedinvdata.tags
        if (selectedTags.includes(tag)) {
            selectedTags.splice(index, 1)
        } else {
            selectedTags.push(tag)
        }
        setupdatedinvdata((prev) => ({ ...prev, tags: selectedTags }))
    }


    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateinvoice = (key, e) => {
        let tempinvoice = { ...updatedinvdata }
        tempinvoice[key] = e.target.value
        if (key === "gstin") {
            tempinvoice.cgst = 0
            tempinvoice.sgst = 0
            tempinvoice.igst = 0
            tempinvoice.totalprice = 0
        }
        if (key === "invoice_type") {
            tempinvoice.tags = [];
            tempinvoice[key] = e.target.value
        }
        setupdatedinvdata(tempinvoice)
    }

    const updateinvoicedate = (key, date) => {
        let tempinvoice = { ...updatedinvdata }
        tempinvoice[key] = date
        setupdatedinvdata(tempinvoice)
    }

    const updateprice = (key, e) => {
        let tempinvoice = { ...updatedinvdata }
        tempinvoice[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempinvoice[key])) {
            return tempinvoice[key]
        }
        if (key === "totalprice" || key === "ittcs") {
            if (tempinvoice.gstin === "") {
                tempinvoice.cgst = 0
                tempinvoice.sgst = 0
                tempinvoice.igst = 0
            } else if (tempinvoice.gstin.startsWith("36")) {
                tempinvoice.cgst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.09, 2)
                tempinvoice.sgst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.09, 2)
                tempinvoice.igst = 0
            } else {
                tempinvoice.cgst = 0
                tempinvoice.sgst = 0
                tempinvoice.igst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempinvoice.sgst = e.target.value
        } else if (key === "igst") {
            tempinvoice.cgst = 0
            tempinvoice.sgst = 0
        }
        setupdatedinvdata(tempinvoice)
    }

    const submitinvoice = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoice = updatedinvdata

        for (let key of ["invoice_no", "desc"]) {
            invoice[key] = invoice[key].trim()
        }

        for (let key of ["totalprice", "ittcs", "cgst", "sgst", "igst"]) {
            if (invoice[key] === null) {
                invoice[key] = 0
            }
            invoice[key] = parseFloat(invoice[key])
        }

        if (invoice.invoice_no !== "" && invoice.vendor !== "" && invoice.invoice_date !== "") {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices?update=update&invoiceid=' + invoice.invoiceid, invoice, config)
            setinvoicedata(invoice)
            seteditflag(false)
        }
    }

    const cancelupdates = () => {
        setupdatedinvdata(invoicedata)
        seteditflag(false)
    }

    const deleteHandler = (invoiceid) => {
        setloaddeletebox(true)
        setdeleteid(invoiceid)
    }
    const deleteinvoice = (data) => {
        setloaddeletebox(false)
        setdeleteid("")
        history.push('/inward-invoices')

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.delete(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices?invoiceid=' + data.invoiceid, config)
    }

    const cancelHandler = () => {
        setloaddeletebox(false)
        setdeleteid("")
    }

    return (
        <div className="invoice txt-light">
            <h2 className="txt-light-grey">Purchase Invoice</h2>
            {editflag ? (
                [updatedinvdata !== null &&
                    <div className="edit-invoice">
                            <div className="entity">
                                <span>Invoice For:</span>
                                <select className="type" value={updatedinvdata.entity} onChange={(e) => updateinvoice("entity", e)} >
                                    {accesslevels.map((item, index) => (item.inward_invoices === "edit" || item.inward_invoices === "write") ? (<><option key={index} value={item.entity}>{bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null)}</option></>):(null))
                                    }
                                </select>
                            </div>
                        <table className='border' cellPadding="0" cellSpacing="0">
                            <tbody>
                            {userDetails.access === "Super" &&
                                <tr>
                                    <th>Invoice ID</th>
                                    <td>{updatedinvdata.invoiceid}</td>
                                </tr>
                            }
                                <tr>
                                    <th>PO Number</th>
                                    <td>{updatedinvdata.po_no}</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>{vendors !== null && vendors.filter(vendor => vendor.vendor_code === updatedinvdata.vendor)[0].name}</td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={updatedinvdata.gstin} onChange={(e) => updateinvoice("gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && updatedinvdata.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === updatedinvdata.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice No.</th>
                                    <td>
                                        <input value={updatedinvdata.invoice_no} onChange={(e) => updateinvoice("invoice_no", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Date</th>
                                    <td>
                                        <DatePicker selected={updatedinvdata.invoice_date === "" ? new Date() : new Date(updatedinvdata.invoice_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateinvoicedate("invoice_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Total</th>
                                    <td>
                                        <input value={updatedinvdata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Type</th>
                                    <td>
                                        <select value={updatedinvdata.invoice_type} onChange={(e) => updateinvoice("invoice_type", e)}>
                                            <option value="">Select invoice type</option>
                                            {invoicetypes.map((type, index) =>
                                                <option key={index} value={type} >
                                                    {type}
                                                </option>
                                            )}
                                        </select>
                                    </td>
                                </tr>

                                {updatedinvdata.invoice_type && (
                                    <tr>
                                        <th>Tags</th>
                                        <td>
                                            {tagOptionsHandler(updatedinvdata.invoice_type).length > 0 ? (
                                                <>{tagOptionsHandler(updatedinvdata.invoice_type).length > 1 && <div className="checkbox-container">
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            ref={selectAllRef}
                                                            checked={tagselectall}
                                                            onChange={() => toggleselectall()}
                                                        />
                                                    </span>
                                                    <span>Select All</span>
                                                </div>}


                                                    {tagOptionsHandler(updatedinvdata.invoice_type).map((tag, i) => (
                                                        <div key={i} className="checkbox-container-inner">
                                                            <span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={updatedinvdata.tags?.includes(tag)}
                                                                    onChange={() => updateTag(tag, i)}
                                                                />
                                                            </span>
                                                            <span>{tag}</span>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <p>No Options</p>
                                            )}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <th>ITC Received</th>
                                    <td>
                                        <select value={updatedinvdata.itc_received} onChange={(e) => updateinvoice("itc_received", e)}>
                                            <option value="NA">Not Applicable</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>IT TCS</th>
                                    <td>
                                        <input value={updatedinvdata.ittcs} onChange={(e) => updateprice("ittcs", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {(updatedinvdata.gstin.startsWith("36")) ? (
                                            <input value={updatedinvdata.cgst} onChange={(e) => updateprice("cgst", e)} />
                                        ) : (
                                            updatedinvdata.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {updatedinvdata.gstin === "" || updatedinvdata.gstin.startsWith("36") ? (
                                            updatedinvdata.igst
                                        ) : (
                                            <input value={updatedinvdata.igst} onChange={(e) => updateprice("igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Due Date</th>
                                    <td>
                                        <DatePicker selected={updatedinvdata.due_date === "" ? new Date() : new Date(updatedinvdata.due_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateinvoicedate("due_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>
                                        <select value={updatedinvdata.pay_status} onChange={(e) => updateinvoice("pay_status", e)}>
                                            <option value="Payment Pending">Payment Pending</option>
                                            <option value="Pre Paid">Pre Paid</option>
                                            <option value="Auto Paid">Auto Paid</option>
                                            <option value="Paid">Paid</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>
                                        <select value={updatedinvdata.settled} onChange={(e) => updateinvoice("settled", e)}>
                                            <option value="Yes">Yes</option>
                                            <option value="No" selected>No</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td><textarea className='large' value={updatedinvdata.desc} onChange={(e) => updateinvoice("desc", e)} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ul className="btns">
                            <li><button onClick={submitinvoice}>Submit</button></li>
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="invoice">
                    {accesslevels && accesslevels.find(item => item.inward_invoices === "edit") && <ul className="btns">
                        <li><button onClick={editinvoice}>Edit the Invoice Details</button></li>
                    </ul>}

                    <p>&nbsp;</p>
                    {invoicedata !== null &&
                        <table cellPadding="0" cellSpacing="0" className="border desktop-table ">
                            <tbody>
                                <tr>
                                    <th>Invoice ID</th>
                                    <td>{invoicedata.invoiceid}</td>
                                </tr>
                                <tr>
                                    <th>invoice for</th>
                                    <td>{bgDetails && bgDetails.entities.map( ent => ent.name === invoicedata.entity ? ent.title : null)}</td>
                                </tr>
                                <tr>
                                    <th>PO Number</th>
                                    <td>{invoicedata.po_no}</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>{vendors !== null && vendors.filter(vendor => vendor.vendor_code === invoicedata.vendor)[0]["name"] + ' [' + invoicedata.gstin + ']'}</td>
                                </tr>
                                <tr>
                                    <th>Invoice No.</th>
                                    <td>{invoicedata.invoice_no}</td>
                                </tr>
                                <tr>
                                    <th>Invoice Date</th>
                                    <td>{invoicedata.invoice_date === "" ? "" : moment(invoicedata.invoice_date).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>

                                    <th>Invoice Total</th>
                                    <td>{CurrencyFormat(invoicedata.totalprice)}</td>
                                </tr>
                                <tr>
                                    <th>Invoice Type</th>
                                    <td>{invoicedata.invoice_type}</td>
                                </tr>
                                {invoicedata.invoice_type && <tr>
                                    <th>Invoice Tags</th>
                                    <td>{invoicedata.tags.join(',')}</td></tr>}
                                <tr>
                                    <th>ITC Received</th>
                                    <td>{invoicedata.itc_received}</td>
                                </tr>
                                <tr>
                                    <th>IT TCS</th>
                                    <td>{invoicedata.ittcs}</td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>{invoicedata.cgst}</td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>{invoicedata.igst}</td>
                                </tr>
                                <tr>
                                    <th>Due Date</th>
                                    <td>{invoicedata.due_date === "" ? "" : moment(invoicedata.due_date).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>{invoicedata.pay_status}</td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>{invoicedata.settled}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{invoicedata.desc}</td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    <p>&nbsp;</p>
                    <ul className="btns">
                        {accesslevels && accesslevels.find(item => item.inward_invoices === "edit") && <li><button onClick={(e) => deleteHandler(invoicedata.invoiceid)}>Delete Invoice</button></li>}
                        <li><KuroLink to="/inward-invoices"><button>Back</button></KuroLink></li>
                    </ul>
                </div>
            )}
            {loaddeletebox && <DeleteBox msg="Are you sure you want to delete the invoice?" okhandler={deleteinvoice} cancelhandler={cancelHandler} data={{ "invoiceid": deleteid }} />}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(InwardInvoice)