import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { getPresets } from '../actions/products'
import { ToWords } from 'to-words'
import note from '../assets/img/button.png'
import '../styles/estimate.css'
import '../styles/react-select.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ImgArrow from '../assets/img/arrow.png'
import { mapData } from '../data/mappings'

moment.tz.setDefault('Asia/Kolkata')

const Estimate = ({ match, user: { token }, products: { prodData, presetData }, admin: { provinces, accesslevels, bgDetails }, getPresets }) => {

    const { params: { estimate_no, version } } = match
    const [estimateData, setestimateData] = useState(null)
    const [newEstimateData, setnewEstimateData] = useState(null)
    const [viewFlag, setviewFlag] = useState("default")
    const [showprice, setshowprice] = useState(true)
    const [showterms, setShowterms] = useState("")
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })
    const history = useHistory();
    const access = accesslevels.find(item => item.estimates !== "NA")

    const toWords = new ToWords()

    const handleBack = () => {
        history.goBack();
    }
    const defbuild = {
        "title": "Kuro Custom PC",
        "presets": [
            {
                "type": "cpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "mob",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ram",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "gpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "psu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ssd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "hdd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "cooler",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "tower",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "fans",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "wifi",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "os",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "shp_fees",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "build_fees",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "warranty",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "margin",
                "presetid": "PREMAR0004",
                "kmar": 5
            }
        ],
        "hsncode": "",
        "tax_rate": 18,
        "subtotal": 0,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "pricebgst": 0,
        "price": 0,
        "quantity": 1,
        "totalpricebgst": 0,
        "totalprice": 0
    }

    const compTypeHandler = (type) => {
        switch (type) {
            case "cpu":
                return "Processor:"
            case "mob":
                return "Motherboard:"
            case "ram":
                return "RAM:"
            case "gpu":
                return "Graphics Card:"
            case "ssd":
                return "SSD:"
            case "hdd":
                return "HDD:"
            case "psu":
                return "Power Supply:"
            case "cooler":
                return "CPU Cooler:"
            case "tower":
                return "Case:"
            case "fans":
                return "Fans:"
            case "wifi":
                return "WiFi:"
            case "os":
                return "Operating System:"
            default:
                return ""
        }
    }

    useEffect(() => {
        if (access.length <= 0) {
            history.push("/unauthorized");
            return;
        }
        getPresets()
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let url = process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no

        if (version) {
            url += '&version=' + version
        }

        axios(url, config).then(res => {
            setestimateData(res.data[0])
            setnewEstimateData(res.data[0])
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [estimate_no])

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempestimate) => {
        let roundoff = 0
        if (tempestimate.billadd.state === "Telangana") {
            roundoff = ((tempestimate.totalprice * 100) - (200 * tempestimate.cgst) - (100 * tempestimate.totalpricebgst))
        } else {
            roundoff = ((tempestimate.totalprice * 100) - (100 * tempestimate.gst) - (100 * tempestimate.totalpricebgst))
        }
        tempestimate.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return tempestimate
    }

    const updatetotal = (tempestimate) => {
        tempestimate.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i = 0; i < tempestimate.builds.length; i++) {
            totaldiscount += tempestimate.builds[i].totaldiscount
            subtotals[tempestimate.builds[i].tax_rate] += tempestimate.builds[i].totalprice
            totalprice += tempestimate.builds[i].totalprice
        }
        for (let i = 0; i < tempestimate.products.length; i++) {
            totaldiscount += tempestimate.products[i].totaldiscount
            subtotals[tempestimate.products[i].tax_rate] += tempestimate.products[i].totalprice
            totalprice += tempestimate.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (tempestimate.billadd.state === "Telangana") {
                tempestimate.taxes[key] = trcgst
            } else {
                tempestimate.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        tempestimate.totaldiscount = totaldiscount
        tempestimate.totalprice = totalprice
        tempestimate.totalpricebgst = round("round", totalpricebgst, 2)
        tempestimate.gst = round("round", gst, 2)
        tempestimate.cgst = round("round", cgst, 2)

        tempestimate = updateroundoff(tempestimate)
        return tempestimate
    }

    const updatevalidity = (date) => {
        let tempestimate = { ...newEstimateData }
        tempestimate["validity"] = (date === null) ? "" : date
        setnewEstimateData(tempestimate)
    }

    const updateentity = (e) => {
        let tempestimate = { ...newEstimateData }
        tempestimate["entity"] = e.target.value
        setnewEstimateData(tempestimate)
    }

    const updateuser = (key, e) => {
        let tempestimate = { ...newEstimateData }
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempestimate.user[key] = value
        tempestimate.billadd[key] = value
        setnewEstimateData(tempestimate)
    }

    const updatepangstin = (tempestimate) => {
        let tempmsg = { ...errmsg }
        if (tempestimate.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(tempestimate.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (tempestimate.billadd.gstin !== "" && (tempestimate.billadd.gstin.length !== 15 || tempestimate.billadd.gstin.slice(2, 12) !== tempestimate.billadd.pan || !(/^[0-9]*$/).test(tempestimate.billadd.gstin.slice(0, 2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempestimate = { ...newEstimateData }
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({ ...msg, state: "Please select the State" }))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            tempestimate[key][subkey] = value
            tempestimate = updatetotal(tempestimate)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempestimate[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempestimate[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempestimate)
        } else {
            tempestimate[key][subkey] = value
        }
        setnewEstimateData(tempestimate)
    }

    const updateaddressflag = (value) => {
        let tempestimate = { ...newEstimateData }
        tempestimate["addressflag"] = value
        if (value) {
            delete tempestimate["shpadd"]
        } else {
            tempestimate.shpadd = { 'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': '' }
        }
        setnewEstimateData(tempestimate)
    }

    const updatenotes = (key, e) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.notes[key] = e.target.value
        setnewEstimateData(tempestimate)
    }

    const buildtotal = (tempestimate, bindex) => {
        if (tempestimate.builds.length > 0) {
            let subtotal = 0
            let kmar = 5
            for (let preset of tempestimate.builds[bindex].presets) {
                if (preset.type === "margin") {
                    kmar = preset.kmar / 100
                } else {
                    subtotal += Number(preset.totalprice)
                }
            }
            let buildprice = round("round", subtotal * (1 + kmar))
            let price = buildprice - tempestimate.builds[bindex].discount
            let quantity = tempestimate.builds[bindex].quantity
            let totalprice = price * quantity
            tempestimate.builds[bindex].subtotal = subtotal
            tempestimate.builds[bindex].buildprice = buildprice
            tempestimate.builds[bindex].price = price
            tempestimate.builds[bindex].pricebgst = round("round", price / 1.18, 2)
            tempestimate.builds[bindex].totalprice = totalprice
            tempestimate.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempestimate = updatetotal(tempestimate)
        }
        return tempestimate
    }

    const addbuild = (bindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds.splice(bindex + 1, 0, defbuild)
        setnewEstimateData(tempestimate)
    }

    const removebuild = (bindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds.splice(bindex, 1)
        tempestimate = updatetotal(tempestimate)
        setnewEstimateData(tempestimate)
    }

    const updatebuild = (bindex, e, key, num = false) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "buildprice" || key === "quantity") {
            tempestimate.builds[bindex].discount = 0
            tempestimate.builds[bindex].totaldiscount = 0
            tempestimate.builds[bindex].price = tempestimate.builds[bindex].buildprice
            let totalprice = tempestimate.builds[bindex].price * tempestimate.builds[bindex].quantity
            tempestimate.builds[bindex].pricebgst = round("round", tempestimate.builds[bindex].price / 1.18, 2)
            tempestimate.builds[bindex].totalprice = totalprice
            tempestimate.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempestimate = updatetotal(tempestimate)
        }
        setnewEstimateData(tempestimate)
    }

    const updatebuilddiscount = (bindex, e) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex].discount = Number(e.target.value)
        tempestimate.builds[bindex].totaldiscount = Number(e.target.value) * tempestimate.builds[bindex].quantity
        tempestimate.builds[bindex].price = tempestimate.builds[bindex].buildprice - Number(e.target.value)
        tempestimate.builds[bindex].totalprice = tempestimate.builds[bindex].price * tempestimate.builds[bindex].quantity
        tempestimate.builds[bindex].pricebgst = round("round", tempestimate.builds[bindex].price / 1.18, 2)
        tempestimate.builds[bindex].totalpricebgst = round("round", tempestimate.builds[bindex].totalprice / 1.18, 2)
        tempestimate = updatetotal(tempestimate)
        setnewEstimateData(tempestimate)
    }

    const addpreset = (bindex, pindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex].presets.splice(pindex + 1, 0, { 'type': '', 'presetid': '', 'price': 0, 'quantity': 1, 'title': '', 'totalprice': 0 })
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        setnewEstimateData(tempestimate)
    }

    const removepreset = (bindex, pindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex].presets.splice(pindex, 1)
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        tempestimate = buildtotal(tempestimate, bindex)
        setnewEstimateData(tempestimate)
    }

    const updatepreset = (bindex, pindex, key, e, num = false) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex].presets[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key !== "title") {
            tempestimate.builds[bindex].discount = 0
            tempestimate.builds[bindex].totaldiscount = 0
            if (key === "price" || key === "quantity") {
                let totalprice = tempestimate.builds[bindex].presets[pindex].price * tempestimate.builds[bindex].presets[pindex].quantity
                tempestimate.builds[bindex].presets[pindex].totalprice = totalprice
                tempestimate = buildtotal(tempestimate, bindex)
            } else {
                tempestimate.builds[bindex].presets[pindex].presetid = ""
                tempestimate.builds[bindex].presets[pindex].title = ""
                tempestimate.builds[bindex].presets[pindex].price = 0
                tempestimate.builds[bindex].presets[pindex].quantity = 1
                tempestimate.builds[bindex].presets[pindex].totalprice = 0
            }
        }
        setnewEstimateData(tempestimate)
    }

    const updatepresetmodel = (bindex, pindex, e) => {
        let tempestimate = { ...newEstimateData }
        let presetid = e.value
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        if (e) {
            tempestimate.builds[bindex].presets[pindex].presetid = presetid
            let title = (presetid === "other") ? '' : presetData.filter(ps => ps.type === tempestimate.builds[bindex].presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].title
            let price = (presetid === "other") ? 0 : presetData.filter(ps => ps.type === tempestimate.builds[bindex].presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].price
            let totalprice = price * tempestimate.builds[bindex].presets[pindex].quantity
            tempestimate.builds[bindex].presets[pindex].title = title
            tempestimate.builds[bindex].presets[pindex].price = price
            tempestimate.builds[bindex].presets[pindex].totalprice = totalprice
            tempestimate = buildtotal(tempestimate, bindex)
        } else {
            tempestimate.builds[bindex].presets[pindex].presetid = ''
            tempestimate.builds[bindex].presets[pindex].title = ''
            tempestimate.builds[bindex].presets[pindex].price = 0
            tempestimate.builds[bindex].presets[pindex].totalprice = 0
            tempestimate = buildtotal(tempestimate, bindex)
        }
        setnewEstimateData(tempestimate)
    }

    const getselectedcomp = (comp) => {
        let label = ""

        if (comp.presetid !== "") {
            if (comp.presetid === "other") {
                label = "Other"
            } else {
                label = presetData !== null && presetData.filter(ps => ps.type === comp.type)[0].list.filter(item => item.presetid === comp.presetid)[0].kuro_title
            }
        }

        return { value: comp.presetid, label: label }
    }

    const updatemargin = (bindex, e) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.builds[bindex].presets.filter(ps => ps.type === "margin")[0].presetid = e.target.value
        tempestimate.builds[bindex].presets.filter(ps => ps.type === "margin")[0].kmar = presetData.filter(ps => ps.type === "margin")[0]["list"].filter(item => item.presetid === e.target.value)[0]["kmar"]
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        tempestimate = buildtotal(tempestimate, bindex)
        setnewEstimateData(tempestimate)
    }

    const addprod = (pindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.products.splice(pindex + 1, 0, { 'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18 })
        setnewEstimateData(tempestimate)
    }

    const removeprod = (pindex) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.products.splice(pindex, 1)
        tempestimate = updatetotal(tempestimate)
        setnewEstimateData(tempestimate)
    }

    const prodtotal = (tempestimate, pindex) => {
        if (tempestimate.products.length > 0) {
            const quantity = tempestimate.products[pindex].quantity
            const price = tempestimate.products[pindex].prodprice - tempestimate.products[pindex].discount
            const totalprice = price * quantity
            tempestimate.products[pindex].pricebgst = round("round", price / (1 + (tempestimate.products[pindex].tax_rate / 100)), 2)
            tempestimate.products[pindex].price = price
            tempestimate.products[pindex].totalpricebgst = round("round", totalprice / (1 + (tempestimate.products[pindex].tax_rate / 100)), 2)
            tempestimate.products[pindex].totalprice = totalprice
            tempestimate = updatetotal(tempestimate)
        }
        return tempestimate
    }

    const getoptionlabel = (pindex, key, value) => {
        let prod = newEstimateData.products[pindex]
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (pindex, key, e, num = false) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.products[pindex].discount = 0
        tempestimate.products[pindex].totaldiscount = 0
        tempestimate.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" || key === "type" || key === "category" || key === "maker") {
            tempestimate.products[pindex].productid = ""
            tempestimate.products[pindex].title = ""
            tempestimate.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            tempestimate = prodtotal(tempestimate, pindex)
        }
        setnewEstimateData(tempestimate)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let tempestimate = { ...newEstimateData }
        tempestimate.products[pindex].productid = prodid
        tempestimate.products[pindex].discount = 0
        tempestimate.products[pindex].totaldiscount = 0
        if (e) {
            tempestimate.products[pindex].title = (prodid === "other") ? "" : prodData[collection].filter(item => item.productid === prodid)[0].title
            tempestimate.products[pindex].prodprice = (prodid === "other") ? 0 : prodData[collection].filter(item => item.productid === prodid)[0].price.kuro_on
        } else {
            tempestimate.products[pindex].title = ''
            tempestimate.products[pindex].productid = ''
            tempestimate.products[pindex].prodprice = 0
        }
        tempestimate = prodtotal(tempestimate, pindex)
        setnewEstimateData(tempestimate)
    }

    const updateproddiscount = (pindex, e) => {
        let tempestimate = { ...newEstimateData }
        tempestimate.products[pindex].discount = Number(e.target.value)
        tempestimate.products[pindex].totaldiscount = tempestimate.products[pindex].discount * tempestimate.products[pindex].quantity
        tempestimate = prodtotal(tempestimate, pindex)
        setnewEstimateData(tempestimate)
    }

    const updateFlag = (flag) => {
        if (flag === "default") {
            setnewEstimateData(estimateData)
        }
        setviewFlag(flag)
    }

    const compOptions = (comp) => {
        const tempArray = []
        presetData !== null && comp.type !== "" && comp.type !== "other" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title })
            )
        })
        tempArray.push({ value: 'other', label: 'Other' })
        return tempArray
    }

    const prodOptions = (prod) => {
        let options = []
        if (prodData && prod.collection && prod.type) {
            options = prodData[prod.collection].filter(_ => _.type === prod.type && _.maker === prod.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
        }
        return options
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const updateEstimate = (version) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let estimate = newEstimateData
        for (let i = 0; i < estimate.builds.length; i++) {
            estimate.builds[i].price = parseFloat(estimate.builds[i].price)
        }
        let estimate_no = estimate.estimate_no

        if (estimate.billadd.state !== "") {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no + '&version=' + version, estimate, config)
                .then((res) => {
                    setestimateData(res.data[0])
                    updateFlag("preview")
                }).catch((err) => {
                    if (err.response.status === 401) {
                        history.push("/unauthorized")
                    }
                })
        }
    }

    var fileDownload = require('js-file-download')

    const downloadestimate = () => {
        const est_no = estimateData["estimate_no"]
        const version = estimateData["version"]

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + est_no + "&version=" + version + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, est_no + '.pdf')
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const updatevalue = (key, i, e) => {
        const temp = { ...estimateData }
        temp[key][i] = e.target.value
        setestimateData(temp)
    };

    const removefield = (key, bindex) => {
        const temp = { ...estimateData }
        temp[key] = temp.payment_terms.filter((_, idx) => idx !== bindex)
        setestimateData(temp)
    }

    const addfield = (key, bindex) => {
        const temp = { ...estimateData }
        if (key === 'payment_terms') {
            temp.payment_terms.splice(bindex, 0, '')
        }
        setestimateData(temp)
    }

    return (
        <div className="estimate txt-light-grey">
            {estimateData !== null &&
            <div>
                <div className="note">
                    <p>Instructions </p>
                    <img src={note} alt='instruction' className='note_img' />:
                    <span className="note_text">
                        <b>Edit:</b> Click on the "Edit Estimate" button at bottom to edit and "Update Estimate" to save the changes or "Cancle" to cancle the changes.
                    </span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
                {viewFlag === "default" &&
                    <div className="preset_estimate">
                        <div className="estimate_details">
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Estimate No</td>
                                        <td>Estimate Date</td>
                                        <td>Customer Name</td>
                                        <td>Mobile Number</td>
                                        <td>Estimate Total</td>
                                        <td>Estimate validity</td>
                                    </tr>
                                    <tr>
                                        <td>{estimateData.estimate_no}</td>
                                        <td>{moment(estimateData.estimate_date).format('DD-MM-YYYY')}</td>
                                        <td>{estimateData.user.name}</td>
                                        <td>{estimateData.user.phone}</td>
                                        <td>{round("round", estimateData.totalprice, 2)}</td>
                                        <td>{estimateData.validity !== "" && moment(estimateData.validity).format('DD-MM-YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Customer</td>
                                        {estimateData.notes.customer === "" ? (
                                            <td>No Instructions</td>
                                        ) : (
                                            <td>{estimateData.notes.customer}</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>Admin</td>
                                        {estimateData.notes.admin === "" ? (
                                            <td>No Instructions</td>
                                        ) : (
                                            <td>{estimateData.notes.admin}</td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>Billing Address</td>
                                        <td colSpan={2}>Shipping Address</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            {estimateData.billadd.company &&
                                                <>
                                                    {estimateData.billadd.company}<br />
                                                </>
                                            }
                                            {estimateData.billadd.name &&
                                                <>
                                                    {estimateData.billadd.name}
                                                </>
                                            }
                                            {estimateData.billadd.phone &&
                                                <>
                                                    <br />{estimateData.billadd.phone}
                                                </>}
                                            {estimateData.billadd.addressline1 &&
                                                <>
                                                    <br />{estimateData.billadd.addressline1}
                                                </>
                                            }
                                            {estimateData.billadd.addressline2 &&
                                                <>
                                                    ,&nbsp;{estimateData.billadd.addressline2}
                                                </>
                                            }
                                            {estimateData.billadd.city &&
                                                <>
                                                    <br />{estimateData.billadd.city}
                                                </>
                                            }
                                            {estimateData.billadd.state &&
                                                <>
                                                    <br />{estimateData.billadd.state}
                                                </>
                                            }
                                            {estimateData.billadd.pincode &&
                                                <>
                                                    &nbsp;- {estimateData.billadd.pincode}
                                                </>
                                            }
                                            {estimateData.billadd.pan &&
                                                <>
                                                    <br />PAN: {estimateData.billadd.pan}
                                                </>
                                            }
                                            {estimateData.billadd.gstin &&
                                                <>
                                                    <br />GSTIN: {estimateData.billadd.gstin}
                                                </>
                                            }
                                        </td>
                                        {estimateData.addressflag ? (
                                            <td colSpan={2}>
                                                {estimateData.billadd.company &&
                                                    <>
                                                        {estimateData.billadd.company}<br />
                                                    </>
                                                }
                                                {estimateData.billadd.name &&
                                                    <>
                                                        {estimateData.billadd.name}
                                                    </>
                                                }
                                                {estimateData.billadd.phone &&
                                                    <>
                                                        <br />{estimateData.billadd.phone}
                                                    </>}
                                                {estimateData.billadd.addressline1 &&
                                                    <>
                                                        <br />{estimateData.billadd.addressline1}
                                                    </>
                                                }
                                                {estimateData.billadd.addressline2 &&
                                                    <>
                                                        ,&nbsp;{estimateData.billadd.addressline2}
                                                    </>
                                                }
                                                {estimateData.billadd.city &&
                                                    <>
                                                        <br />{estimateData.billadd.city}
                                                    </>
                                                }
                                                {estimateData.billadd.state &&
                                                    <>
                                                        <br />{estimateData.billadd.state}
                                                    </>
                                                }
                                                {estimateData.billadd.pincode &&
                                                    <>
                                                        &nbsp;- {estimateData.billadd.pincode}
                                                    </>
                                                }
                                                {estimateData.billadd.pan &&
                                                    <>
                                                        <br />PAN: {estimateData.billadd.pan}
                                                    </>
                                                }
                                                {estimateData.billadd.gstin &&
                                                    <>
                                                        <br />GSTIN: {estimateData.billadd.gstin}
                                                    </>
                                                }
                                            </td>
                                        ) : (
                                            <td colSpan={2}>
                                                {estimateData.shpadd.company &&
                                                    <>
                                                        {estimateData.shpadd.company}<br />
                                                    </>
                                                }
                                                {estimateData.shpadd.name &&
                                                    <>
                                                        {estimateData.shpadd.name}
                                                    </>
                                                }
                                                {estimateData.shpadd.phone &&
                                                    <>
                                                        <br />{estimateData.shpadd.phone}
                                                    </>}
                                                {estimateData.shpadd.addressline1 &&
                                                    <>
                                                        <br />{estimateData.shpadd.addressline1}
                                                    </>
                                                }
                                                {estimateData.shpadd.addressline2 &&
                                                    <>
                                                        ,&nbsp;{estimateData.shpadd.addressline2}
                                                    </>
                                                }
                                                {estimateData.shpadd.city &&
                                                    <>
                                                        <br />{estimateData.shpadd.city}
                                                    </>
                                                }
                                                {estimateData.shpadd.state &&
                                                    <>
                                                        <br />{estimateData.shpadd.state}
                                                    </>
                                                }
                                                {estimateData.shpadd.pincode &&
                                                    <>
                                                        &nbsp;- {estimateData.shpadd.pincode}
                                                    </>
                                                }
                                                {estimateData.shpadd.pan &&
                                                    <>
                                                        <br />PAN: {estimateData.shpadd.pan}
                                                    </>
                                                }
                                                {estimateData.shpadd.gstin &&
                                                    <>
                                                        <br />GSTIN: {estimateData.shpadd.gstin}
                                                    </>
                                                }
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            <div className="entity">
                                <span>Estimate For:</span><span>{ bgDetails && bgDetails.entities.map( ent => ent.name === estimateData.entity ? ent.title : null )}</span>
                            </div>
                            <div className='view_price' style={{ marginBottom: "20px" }}>
                                <span style={{ color: "#f00", fontWeight: "400", marginRight: '0' }}>Restricted View</span><input type="checkbox" className='checkbox' onClick={() => setshowprice(!showprice)} />
                            </div>
                        </div>
                        {estimateData.builds.length > 0 &&
                            <div className="build_wrap">
                                <h3>Builds</h3>
                                {estimateData.builds.map((build, i) =>
                                    <div key={i}>
                                        <table className="border prod_details  pg_mh even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>Price</td>
                                                    <td>Quantity</td>
                                                    <td>Total Price</td>
                                                </tr>
                                                <tr>
                                                    <td>{build.title}</td>
                                                    <td>{build.price}</td>
                                                    <td>{build.quantity}</td>
                                                    <td>{round("round", build.totalprice, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="border inventory border even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="5">Components</td>
                                                </tr>
                                                <tr>
                                                    <td>Type</td>
                                                    <td>Title</td>
                                                    {showprice && <td>Price</td>}
                                                    <td>Quantity</td>
                                                    {showprice && <td>Total Price</td>}
                                                </tr>
                                                {build.presets.map((comp, j) =>
                                                    comp.type !== "margin" &&
                                                    <tr key={j}>
                                                        <td>{(comp.type).toUpperCase()}</td>
                                                        <td>{comp.title}</td>
                                                        {showprice && <td>{comp.price}</td>}
                                                        <td>
                                                            {comp.type !== "os" && comp.type !== "shp_fees" && comp.type !== "build_fees" && comp.type !== "warranty" ? (
                                                                comp.quantity
                                                            ) : (
                                                                ""
                                                            )}</td>
                                                        {showprice && <td>{comp.totalprice}</td>}
                                                    </tr>
                                                )}
                                                {showprice ? (
                                                    <>
                                                        <tr>
                                                            <td colSpan="4">Sub Total</td>
                                                            <td>{build.subtotal}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">KM(%)</td>
                                                            <td>{round("round", (build.price - build.subtotal) * 100 / build.subtotal, 2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">KM(Rs)</td>
                                                            <td>{build.price - build.subtotal}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">KM Pre-GST(Rs)</td>
                                                            <td>{round("round", (build.price - build.subtotal) / 1.18, 2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">Build Price</td>
                                                            <td>{build.buildprice}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">Discount</td>
                                                            <td>{build.discount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">Total</td>
                                                            <td>{build.price}</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan="2">Build Price</td>
                                                            <td>{build.buildprice}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">Discount</td>
                                                            <td>{build.discount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">Total</td>
                                                            <td>{build.price}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        <p>&nbsp;</p>
                                    </div>
                                )}
                            </div>
                        }
                        {estimateData.products.length > 0 &&
                            <div className="products">
                                <h3>Peripherals</h3>
                                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Product Id</td>
                                            <td>Title</td>
                                            <td>Price</td>
                                            <td>Quantity</td>
                                            <td>Total Price</td>
                                        </tr>
                                        {estimateData.products.map((prod, j) =>
                                            <tr key={j}>
                                                <td>{prod.productid}</td>
                                                <td>{prod.title}</td>
                                                <td>{prod.price}</td>
                                                <td>{prod.quantity}</td>
                                                <td>{prod.totalprice}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        }
                        <table className="border even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Taxable Amount</td>
                                    <td>{estimateData.totalpricebgst}</td>
                                </tr>
                                {estimateData.billadd.state === "Telangana" ? (
                                    <>
                                        <tr>
                                            <td>SGST</td>
                                            <td>{estimateData.cgst}</td>
                                        </tr>
                                        <tr>
                                            <td>CGST</td>
                                            <td>{estimateData.cgst}</td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td>IGST</td>
                                        <td>{estimateData.gst}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Round Off</td>
                                    <td>{estimateData.roundoff}</td>
                                </tr>
                                <tr>
                                    <td>Total Price</td>
                                    <td>{estimateData.totalprice}</td>
                                </tr>
                                <tr>
                                    <td>Total Discount</td>
                                    <td>{estimateData.totaldiscount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className='btns'>
                            <li>
                                <button onClick={downloadestimate}>Download Estimate</button>
                            </li>
                            {accesslevels.find(item => item.estimates === "edit" && item.entity === estimateData.entity) && <li>
                                <button onClick={() => updateFlag("edit")}>Edit Estimate</button>
                            </li>}

                            <li>
                                <button onClick={() => updateFlag("preview")}>Preview Estimate</button>
                            </li>
                            <li>
                                <button onClick={handleBack}> Back</button>
                            </li>
                        </ul>
                    </div>
                }
                {viewFlag === "preview" &&
                    <div className="preset_estimate wide_table">
                        <h2>Preview</h2>
                        <div className='estimate_details'>
                            <table className="border bg_black ph_20" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td className='txt-center'>
                                            <svg className='logo' viewBox="200 250 1300 700"><title>Kuro Gaming</title><polygon fill="#ee2d30" points="850.68 688.03 885.99 611.01 1180.3 611.01 1264.86 556.53 838.61 553.03 850.68 688.03" /><polygon fill="#ee2d30" points="433.12 715.79 274.77 541.21 753.1 54.3 1029.11 54.3 549.72 541.21 706.98 712.95 433.12 715.79" /><polygon fill="#ee2d30" points="809.78 831.25 850.75 879 1099.35 563.66 850.68 563.95 850.68 688.03 756.3 541.21 1426.01 541.21 850.75 1194.56 532.77 830.61 809.78 831.25" /></svg>
                                        </td>
                                        <td><p>Name: &nbsp;&nbsp;<span className='txt-light'>{estimateData.user.name}</span></p>
                                            <span>Mobile: </span><span className='txt-light'>{estimateData.user.phone}</span></td>
                                        <td className='txt-right'>
                                            <p>Estimate No: <span className='txt-light'>{estimateData.estimate_no}</span></p>
                                            <p>Estimate Date: <span className='txt-light'>{moment(estimateData.estimate_date).format('DD-MM-YYYY')}</span></p>
                                            {estimateData.validity !== "" &&
                                                <p>Validity Upto: <span className='txt-light'>{moment(estimateData.validity).format('DD-MM-YYYY')}</span></p>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th colSpan="6">Product Description</th>
                                    <th>QTY</th>
                                    <th colSpan="3">Unit Price</th>
                                    <th colSpan="3">Total Amount</th>
                                </tr>
                                {estimateData.builds.map((build, i) =>
                                    <tr key={i}>
                                        <td colSpan="6">
                                            <b>{build.title}</b><br />
                                            {build.presets.map((preset, i) =>
                                                preset.type !== "margin" && preset.type !== "shp_fees" && preset.type !== "build_fees" && preset.type !== "warranty" &&
                                                <span>{(preset.type !== "" && preset.title !== "") && compTypeHandler(preset.type)} {preset.quantity > 1 && <span>{preset.quantity} x </span>}{preset.title !== "" && <>{preset.title}<br /></>}</span>
                                            )}
                                        </td>
                                        <td>{build.quantity}</td>
                                        <td colSpan="3">{build.price}</td>
                                        <td className="text_right" colSpan="3">{build.totalprice}</td>
                                    </tr>
                                )}
                                {estimateData.products.map((prod, i) =>
                                    <tr key={i}>
                                        <td colSpan="6">{prod.title}</td>
                                        <td>{prod.quantity}</td>
                                        <td colSpan="3">{prod.price}</td>
                                        <td className="text_right" colSpan="3">{prod.totalprice}</td>
                                    </tr>
                                )}
                                {estimateData.totaldiscount > 0 &&
                                    <tr>
                                        <td className="text_right" colSpan="6"></td>
                                        <td className="text_right" colSpan="4"><b>Total Discount</b></td>
                                        <td className="text_right" colSpan="3"><b>Rs.{estimateData.totaldiscount}</b></td>
                                    </tr>
                                }
                                <tr>
                                    <td className="text_right" colSpan="6"></td>
                                    <td className="text_right" colSpan="4"><b>Total Price</b></td>
                                    <td className="text_right" colSpan="3"><b>Rs.{estimateData.totalprice}</b></td>
                                </tr>
                                <tr>
                                    <td className="text_right" colSpan="6"></td>
                                    <td className="text_right" colSpan="4">
                                        Tax Summary<br />
                                        {estimateData.billadd.state === "Telangana" ? (
                                            <>
                                                CGST @ 9%<br />SGST @ 9%
                                            </>
                                        ) : (
                                            "IGST @ 18%"
                                        )}
                                    </td>
                                    <td className="text_right" colSpan="3">
                                        &nbsp;<br />
                                        {estimateData.billadd.state === "Telangana" ? (
                                            <>
                                                Rs.{estimateData.cgst}<br />
                                                Rs.{estimateData.cgst}
                                            </>
                                        ) : (
                                            estimateData.gst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text_right" colSpan="13"><b>Amount in Words: {toWords.convert(estimateData.totalprice)} Rupees Only</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <span className='txt-light'>Advance Amount (60%): {Math.ceil((estimateData.totalprice) * (60 / 100) / 1000) * 1000}</span>
                        <p>&nbsp;</p>
                        <ul className='btns'>
                            <li>
                                <button onClick={() => updateFlag("default")}>Back</button>
                            </li>
                            <li>
                                <button onClick={downloadestimate}>Download</button>
                            </li>
                        </ul>
                    </div>
                }
                {viewFlag === "edit" && newEstimateData !== null &&
                    <div className="preset_estimate">
                        <div className='estimate_details'>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Estimate No</td>
                                        <td>Estimate Date</td>
                                        <td>Customer Name</td>
                                        <td>Mobile Number</td>
                                        <td>Estimate Total</td>
                                        <td>Estimate Validity</td>
                                    </tr>
                                    {newEstimateData !== null &&
                                        <tr>
                                            <td>{newEstimateData.estimate_no}</td>
                                            <td>{moment(newEstimateData.estimate_date).format('DD-MM-YYYY')}</td>
                                            <td><input className='small' value={newEstimateData.user.name} onChange={(e) => updateuser("name", e)} /></td>
                                            <td><input className='small' value={newEstimateData.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                                            <td>{round("round", newEstimateData.totalprice, 2)}</td>
                                            <td><DatePicker value={moment(newEstimateData.validity).format('DD-MM-YYYY')} onChange={date => updatevalidity(date)} /></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            Shipping Address same as Billing Address
                                            <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!newEstimateData.addressflag)} checked={newEstimateData.addressflag} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Billing Address</th>
                                    </tr>
                                    <tr>
                                        <td >Company Name</td>
                                        <td><input value={newEstimateData.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td><input value={newEstimateData.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td><input value={newEstimateData.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Address Line1</td>
                                        <td><input value={newEstimateData.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Address Line2</td>
                                        <td><input value={newEstimateData.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>City</td>
                                        <td><input value={newEstimateData.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>
                                            <select value={newEstimateData.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                                <option value="">Select State</option>
                                                {provinces.map((province, i) =>
                                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td><input value={newEstimateData.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>PAN</td>
                                        <td><input value={newEstimateData.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                                    </tr>
                                    <tr>
                                        <td>GSTIN</td>
                                        <td><input value={newEstimateData.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                                    </tr>
                                    {!newEstimateData.addressflag &&
                                        <>
                                            <tr>
                                                <th colSpan={2}>Shipping Address</th>
                                            </tr>
                                            <tr>
                                                <td>Company Name</td>
                                                <td><input value={newEstimateData.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>Name</td>
                                                <td><input value={newEstimateData.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td><input value={newEstimateData.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>Address Line1</td>
                                                <td><input value={newEstimateData.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>Address Line2</td>
                                                <td><input value={newEstimateData.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td><input value={newEstimateData.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>
                                                    <select className="state" value={newEstimateData.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                                        <option value="">Select State</option>
                                                        {provinces.map((province, i) =>
                                                            <option key={i} value={province["state"]}>{province["state"]}</option>
                                                        )}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pincode</td>
                                                <td><input value={newEstimateData.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>PAN</td>
                                                <td><input value={newEstimateData.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                                            </tr>
                                            <tr>
                                                <td>GSTIN</td>
                                                <td><input value={newEstimateData.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                            <table className='border even_odd' cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td>Customer Instructions</td>
                                    <td><textarea className='large input_width' value={newEstimateData.notes.customer} onChange={(e) => updatenotes("customer", e)} /></td>
                                </tr>
                                <tr>
                                    <td>Admin Notes</td>
                                    <td><textarea className='large input_width' value={newEstimateData.notes.admin} onChange={(e) => updatenotes("admin", e)} /></td>
                                </tr>
                            </table>
                            <div className="entity">
                                    <span>Estimate For:</span>
                                    <select className="type" value={newEstimateData.entity} onChange={(e) => updateentity(e)} >
                                        {accesslevels.map((item, index) => (item.estimates === "edit" || item.estimates === "write") ? (<><option key={index} value={item.entity}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option></>):(null))
                                        }
                                    </select>
                                </div>
                        </div>
                        {Object.keys(errmsg).map((err) =>
                            <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                        )}
                        <p>&nbsp;</p>
                        <div className="build_wrap">
                            <div className='title_wrap col_mob'>
                                <p>Builds</p>
                                <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                            </div>
                            <div className='view_price' style={{ marginBottom: "20px" }}>
                                <span style={{ color: "#f00", fontWeight: "400", marginRight: '0' }}>Restricted View</span><input type="checkbox" className='checkbox' onClick={() => setshowprice(!showprice)} />
                            </div>
                            {newEstimateData.builds.map((build, i) =>
                                <table key={i} className="border even_odd build" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                        <>
                                            <tr>
                                                <td colSpan="3">Build Title</td>
                                                <td>Price</td>
                                                <td>Quantity</td>
                                                <td>Total Price</td>
                                                <td>
                                                    <span className="prod-span" onClick={() => removebuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                    <span className="prod-span" onClick={() => addbuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3"><textarea onChange={(e) => updatebuild(i, e, "title")} value={build.title} /></td>
                                                <td><input type="number" min="0" className='small' onWheel={disableScroll} value={build.buildprice} onChange={(e) => updatebuild(i, e, "buildprice")} /></td>
                                                <td><input type="number" min="1" max="100" className='small' onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(i, e, "quantity", true)} /></td>
                                                <td>{round("round", build.totalprice, 2)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Type</td>
                                                <td>Component</td>
                                                <td>Title</td>
                                                {showprice && <td>Price</td>}
                                                <td>Quantity</td>
                                                {showprice && <td>Total Price</td>}
                                                <td><span className="prod-span" onClick={() => addpreset(i, -1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                            </tr>
                                            {build.presets.map((comp, j) =>
                                                <>
                                                    {comp.type !== "margin" &&
                                                        <tr key={j}>
                                                            <td>
                                                                <select value={comp.type} onChange={(e) => updatepreset(i, j, "type", e)}>
                                                                    <option value="">Select Type</option>
                                                                    <option value="cpu">Processor</option>
                                                                    <option value="mob">Motherboard</option>
                                                                    <option value="ram">RAM</option>
                                                                    <option value="gpu">Graphics Card</option>
                                                                    <option value="psu">Power Supply</option>
                                                                    <option value="ssd">SSD</option>
                                                                    <option value="hdd">HDD</option>
                                                                    <option value="cooler">CPU Cooler</option>
                                                                    <option value="tower">Case</option>
                                                                    <option value="fans">Fan</option>
                                                                    <option value="wifi">WiFi</option>
                                                                    <option value="os">Operating System</option>
                                                                    <option value="shp_fees">Shipping Fees</option>
                                                                    <option value="build_fees">Build Fees</option>
                                                                    <option value="warranty">Warranty</option>
                                                                    <option value="other">Other</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions(comp)} value={getselectedcomp(comp)} onChange={(e) => updatepresetmodel(i, j, e)} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>
                                                                <textarea value={comp.title} className="medium" onChange={(e) => updatepreset(i, j, "title", e)} />
                                                            </td>
                                                            {showprice &&
                                                                <td>
                                                                    <input type="number" min="0" className='small' onWheel={disableScroll} value={comp.price} onChange={(e) => updatepreset(i, j, "price", e, true)} />
                                                                </td>
                                                            }
                                                            {comp.type !== "os" && comp.type !== "shp_fees" && comp.type !== "build_fees" && comp.type !== "warranty" ? (
                                                                <td>
                                                                    <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatepreset(i, j, "quantity", e, true)} />
                                                                </td>
                                                            ) : (
                                                                <td>NA</td>
                                                            )}
                                                            {showprice &&
                                                                <td>
                                                                    {round("round", comp.totalprice, 2)}
                                                                </td>
                                                            }
                                                            <td>
                                                                <span className="prod-span" onClick={() => addpreset(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                                <span className="prod-span" onClick={() => removepreset(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </>
                                            )}
                                            {showprice &&
                                                <>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td colSpan="2">Sub Total</td>
                                                        <td colSpan="2">{round("round", build.subtotal, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>KM(%)</td>
                                                        <td>
                                                            <select value={build.presets.filter(comp => comp.type === "margin")[0].presetid} onChange={(e) => updatemargin(i, e)}>
                                                                {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].map((item, j) =>
                                                                    <option key={j} value={item.presetid}>{item.title}</option>
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td></td>
                                                        <td colSpan="2">KM</td>
                                                        <td colSpan="2">{build.buildprice - build.subtotal}</td>
                                                    </tr>
                                                </>
                                            }
                                            {showprice ? (
                                                <>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td colSpan="2">Build Price</td>
                                                        <td colSpan="2">{build.buildprice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td colSpan="2">Discount</td>
                                                        <td colSpan="2"><input value={build.discount} onChange={(e) => updatebuilddiscount(i, e)} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td colSpan="2">Total</td>
                                                        <td colSpan="2">{build.price}</td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td>Build Price</td>
                                                        <td>{build.buildprice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td>Discount</td>
                                                        <td><input value={build.discount} onChange={(e) => updatebuilddiscount(i, e)} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3"></td>
                                                        <td>Total</td>
                                                        <td>{build.price}</td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className='title_wrap col_mob'>
                            <p>Peripherals</p>
                            <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                        </div>
                        {newEstimateData.products.length > 0 &&
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Collection</td>
                                        <td>Type</td>
                                        <td>Category</td>
                                        <td>Brand</td>
                                        <td>Model</td>
                                        <td>Title</td>
                                        <td>Price</td>
                                        <td>Tax Rate</td>
                                        <td>Quantity</td>
                                        <td>Discount (Per Unit)</td>
                                        <td>Total Price</td>
                                        <td></td>
                                    </tr>
                                    {newEstimateData.products.map((prod, i) =>
                                        <tr key={i}>
                                            <td>
                                                <select className='small' value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                                    <option value="">Select Collection</option>
                                                    {prodData && Object.keys(prodData).sort().map((coll, j) =>
                                                        <option key={j} value={coll}>{getoptionlabel(i, "collection", coll)}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td>
                                                <select className='small' value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                                    <option value="">Select Type</option>
                                                    {prod.collection && prodData &&
                                                        [...new Set(prodData[prod.collection].map(_ => _.type))].sort().map((type, j) =>
                                                        <option key={j} value={type}>{getoptionlabel(i, "type", type)}</option>
                                                        )
                                                    }
                                                </select>
                                            </td>
                                            <td>
                                                <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                                    <option value="">Select Category</option>
                                                {prod.collection && prod.type && prodData &&
                                                    [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(_ => _.category))].sort().map((cat, j) =>
                                                        cat !== "" &&
                                                    <option key={j} value={cat}>{getoptionlabel(i, "category", cat)}</option>
                                                    )
                                                }
                                                </select>
                                            </td>
                                            <td>
                                                <select className='small' value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                                    <option value="">Select Brand</option>
                                                    {prod.collection && prod.type && prodData &&
                                                        [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(item => item.maker))].sort().map((maker, j) =>
                                                        <option key={j} value={maker}>{maker}</option>
                                                        )
                                                    }
                                                </select>
                                            </td>
                                            <td>
                                                <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                                            </td>
                                            <td>
                                                <textarea className="large" value={prod.title} type="textarea" onChange={(e) => updateprod(i, "title", e)}></textarea>
                                            </td>
                                            <td>
                                                <input className="small" value={prod.prodprice} type="number" min="0" onWheel={disableScroll} onChange={(e) => updateprod(i, "prodprice", e, true)} />
                                            </td>
                                            <td>
                                                <select className="small" value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                                    <option value={5}>5</option>
                                                    <option value={12}>12</option>
                                                    <option value={18}>18</option>
                                                    <option value={28}>28</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input className='small' value={prod.quantity} type="number" min="1" max="100" onWheel={disableScroll} onChange={(e) => updateprod(i, "quantity", e, true)} />
                                            </td>
                                            <td>
                                                <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} />
                                            </td>
                                            <td>
                                                {round("round", prod.totalprice, 2)}
                                            </td>
                                            <td>
                                                <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        <p>&nbsp;</p>
                        <table className='border even_odd' cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Taxable Amount</td>
                                    <td>{newEstimateData.totalpricebgst}</td>
                                </tr>
                                {newEstimateData.billadd.state === "Telangana" ? (
                                    <>
                                        <tr>
                                            <td>SGST</td>
                                            <td>{newEstimateData.cgst}</td>
                                        </tr>
                                        <tr>
                                            <td>CGST</td>
                                            <td>{newEstimateData.cgst}</td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td>IGST</td>
                                        <td>{newEstimateData.gst}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Round Off</td>
                                    <td>{newEstimateData.roundoff}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>{newEstimateData.totalprice}</td>
                                </tr>
                                <tr>
                                    <td>Total Discount</td>
                                    <td>{newEstimateData.totaldiscount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ul className="btns">
                            <li>
                                <button onClick={() => updateFlag("default")}>Cancel</button>
                            </li>
                            <li>
                                <button onClick={() => updateEstimate("update")}>Update Estimate</button>
                            </li>
                        </ul>

                    </div>

                }
                <div className='title_wrap col_mob'>
                    <p>
                        Payment Terms
                        <img
                            style={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                filter: 'invert()',
                                marginLeft: '5px'
                            }}
                            onClick={() => setShowterms(showterms === 'payment_terms' ? '' : 'payment_terms')}
                            className={showterms === 'payment_terms' ? 'img_mob' : 'img_mob down'}
                            title="Invoice"
                            alt="Invoice"
                            src={ImgArrow}
                        />
                    </p>
                </div>
                <table className="build full_width border" cellPadding="0" cellSpacing="0">
                    {showterms === 'payment_terms' ? (
                        <>
                            {Array.isArray(estimateData.payment_terms) && estimateData.payment_terms.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <textarea
                                            className="admindata_width"
                                            value={item}
                                            onChange={e => updatevalue('payment_terms', i, e)}
                                        />
                                    </td>
                                    <td>
                                        <span className="prod-span" onClick={() => removefield('payment_terms', i)}>
                                            <svg fill="#92abcf" width="25" height="25" viewBox="0 0 24 24">
                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                            </svg>
                                        </span>
                                        <span className="prod-span" onClick={() => addfield('payment_terms', i)}>
                                            <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" />
                                            </svg>
                                        </span>

                                    </td>
                                </tr>
                            ))}</>) : null}
                </table>
                <div className='title_wrap col_mob'>
                    <p>Dispatch Schedule & Other Terms
                        <img
                            style={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                filter: 'invert()',
                                marginLeft: '5px'
                            }}
                            onClick={() => setShowterms(showterms === 'ds_ot' ? '' : 'ds_ot')}
                            className={showterms === 'ds_ot' ? 'img_mob' : 'img_mob down'}
                            title="Invoice"
                            alt="Invoice"
                            src={ImgArrow}
                        />
                    </p>
                </div>
                <table className="build full_width border" cellPadding="0" cellSpacing="0">
                    {showterms === 'ds_ot' ? (
                        <>
                            {Array.isArray(estimateData.ds_ot) && estimateData.ds_ot.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <textarea
                                            className="admindata_width"
                                            value={item}
                                            onChange={e => updatevalue('ds_ot', i, e)}
                                        />
                                    </td>
                                    <td>
                                        <span className="prod-span" onClick={() => removefield('ds_ot', i)}>
                                            <svg fill="#92abcf" width="25" height="25" viewBox="0 0 24 24">
                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                            </svg>
                                        </span>
                                        <span className="prod-span" onClick={() => addfield('ds_ot', i)}>
                                            <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" />
                                            </svg>
                                        </span>

                                    </td>
                                </tr>
                            ))}</>) : null}
                </table>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getPresets })(Estimate)