import React from "react"

const Unauthorized = () => {
    return(
        <>
        <img src="https://www.shutterstock.com/shutterstock/videos/1019666437/thumb/12.jpg?ip=x480" alt="unauthgorized" width={"500px"} height={"500px"}/>
        <h1>Access denied, You are not authorized </h1>
        </>
    )
}

export default Unauthorized