import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import ResponseBox from '../components/ResponseBox'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { mapData } from '../data/mappings'
import '../styles/table.css'
import '../styles/tabs.css'
import '../styles/react-select.css'
import '../styles/entry.css'
import note from '../assets/img/button.png'
import { getInwardInvoices } from '../actions/admin'


const Inventory = ({ user: { token }, products: { prodData }, admin: { inwardinvoices, accesslevels, bgDetails }, getInwardInvoices }) => {

    const [invdata, setinvdata] = useState({ 'collection': '', 'type': '', 'maker': '', 'productid': '', 'entity': accesslevels.find(item => (item.inventory === "edit" || item.inventory === "write")).entity, 'prod_no': '', 'upc_no': '', 'ean_no': '', 'quantity': 1, 'sr_no': [''], 'pricebg': 0, 'price': 0, 'mrp': 0, 'invoice_date': '', 'invoiceid': '' })
    const [makers, setMakers] = useState([])
    const [prodOptions, setprodOptions] = useState([])
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")
    const selectedProd = useRef()
    const access = accesslevels.find(item => item.inventory !== "NA")

    useEffect(() => {
        getInwardInvoices()
    }, [])
    const updateproddate = (date) => {
        const tempinv = { ...invdata }
        tempinv["invoice_date"] = date
        setinvdata(tempinv)
    }

    const getoptionlabel = (key, value) => {
        let prod = invdata
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (key, e) => {
        const tempinv = { ...invdata }
        tempinv[key] = e.target.value
        if (key === "collection") {
            setMakers([])
            setprodOptions([])
            tempinv.type = ""
            tempinv.maker = ""
            selectedProd.current.setValue('')
        } else if (key === "type") {
            setMakers([...new Set(prodData[tempinv.collection].filter(prod => prod.type === tempinv.type).map(item => item.maker))])
            tempinv.maker = ""
            setprodOptions([])
            selectedProd.current.setValue('')
        } else if (key === "maker") {
            let options = []
            options = prodData[tempinv.collection].filter(item => item.type === tempinv.type && item.maker === tempinv.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
            setprodOptions(options)
            selectedProd.current.setValue('')
        }
        setinvdata(tempinv)
    }

    const updateprodmodel = (key, e) => {
        const tempinv = { ...invdata }
        tempinv[key] = e.value
        setinvdata(tempinv)
    }

    const updatequantity = (e) => {
        const tempinv = { ...invdata }
        const tempsr = []
        for (let q = 0; q < e.target.value; q++) {
            tempsr.push('')
        }
        tempinv["sr_no"] = tempsr
        tempinv["quantity"] = e.target.value
        setinvdata(tempinv)
    }

    const updateserial = (e, ind) => {
        const tempinv = { ...invdata }
        tempinv["sr_no"][ind] = e.target.value
        setinvdata(tempinv)
    }

    const updateprodprice = (key, e) => {
        const tempinv = { ...invdata }
        if (key === "price") {
            tempinv[key] = Number(e.target.value)
            tempinv["pricebg"] = Number(e.target.value) / 1.18
        } else {
            tempinv[key] = Number(e.target.value)
            tempinv["price"] = Math.ceil(Number(e.target.value) * 1.18)
        }
        setinvdata(tempinv)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        delete invdata.pricebg
        delete invdata.maker

        let inv_data = []
        inv_data.push(invdata)

        const body = JSON.stringify({ inv_data })
        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory', body, config).then((res) => {
            setresstatus(res.status)
        })
        setsubmitted(true)
    }

    const okHandler = () => {
        setresstatus("")
        setsubmitted(false)
    }

    return (
        <div className="kuro-inventory entry txt-light-grey">
            <h2>Inventory Management</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">
                    <b>Invoice Date:</b> Date on the invoice for the purchase.  <br />
                    <b>Invoice No.:</b> Unique invoice number for tracking. <br />
                    <b>Entity:</b>: Name of the entity making the purchase. <br />
                    <b>UPC No. (12-Digit):</b> 12-digit Universal Product Code. <br />
                    <b>EAN No. (13-Digit):</b> 13-digit European Article Number. <br />
                    <b>Price excl. GST:</b> Price of the product before GST. <br />
                    <b>Price incl. GST:</b> Price of the product after GST. <br />
                    <b>Quantity:</b> Number of units being added. <br />
                    <b>Serial No(s):</b> Serial numbers of the products, if applicable. <br />
                </span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            <div className='col_2'>
                <KuroLink to="/stock/inventory/details"><button>Find Stock</button></KuroLink>
            </div>
            <div className="comps inventory txt-light">
                {submitted ? (
                    <div>
                        {resstatus === 200 ? (
                            <ResponseBox msg="Credit Notes has been Submitted Succesfully." okhandler={okHandler} />
                        ) : (
                            <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                        )}
                    </div>
                ) : (
                    prodData !== null &&
                    <>
                        <table className="home border table_mob" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Invoice Date</th>
                                    <td>
                                        <DatePicker className="medium" dateFormat='dd-MMM-yyyy' selected={invdata.invoice_date} onChange={date => updateproddate(date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice No.</th>
                                    <td>
                                        <select value={invdata.invoiceid} onChange={(e) => updateprod("invoiceid", e)}>
                                            <option value="">Select Invoice</option>
                                            {inwardinvoices && inwardinvoices[0].invoices.filter(item => new Date(item.invoice_date).toString() === invdata.invoice_date.toString()).map((invoice, i) =>
                                                <option key={i} value={invoice.invoiceid}>{invoice.invoice_no}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Entity</th>
                                    <td>
                                        <select className="type" name="entity" value={invdata.entity} onChange={(e) => updateprod("entity", e)} >
                                            {accesslevels.map((item, index) => (item.inventory === "edit" || item.inventory === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{ bgDetails && bgDetails.entities.map(ent => ent.name === item.entity ? ent.title : null )}</option>) : (null))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Collection</th>
                                    <td>
                                        <select value={invdata.collection} onChange={(e) => updateprod("collection", e)}>
                                            <option value="">Select Collection</option>
                                            {Object.keys(prodData).map((coll, i) =>
                                                <option key={i} value={coll}>{getoptionlabel("collection", coll)}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>
                                        <select value={invdata.type} onChange={(e) => updateprod("type", e)}>
                                            <option value="">Select Type</option>
                                            {invdata.collection &&
                                                [...new Set(prodData[invdata.collection].map(item => item.type))].map((type, i) =>
                                                    <option key={i} value={type}>{getoptionlabel("type", type)}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Brand</th>
                                    <td>
                                        <select value={invdata.maker} onChange={(e) => updateprod("maker", e)}>
                                            <option value="">Select Brand</option>
                                            {makers !== null && makers.sort().map((maker, i) =>
                                                <option key={i} value={maker}>{maker}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Model</th>
                                    <td>
                                        <Select classNamePrefix="kuro-search-select" options={prodOptions} defaultValue={{ value: invdata.productid, label: invdata.title }} onChange={(e) => updateprodmodel("productid", e)} placeholder="Select Model" className="react-select-container" ref={selectedProd} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Product No.</th>
                                    <td>
                                        <input value={invdata.prod_no} onChange={(e) => updateprod("prod_no", e, false)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>UPC No. (12-Digit)</th>
                                    <td>
                                        <input value={invdata.upc_no} onChange={(e) => updateprod("upc_no", e, false)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>EAN No. (13-Digit)</th>
                                    <td>
                                        <input value={invdata.ean_no} onChange={(e) => updateprod("ean_no", e, false)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Price<br />excl. GST</th>
                                    <td>
                                        <input type="number" className="small" value={invdata.pricebg} onChange={(e) => updateprodprice("pricebg", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Price<br />incl. GST</th>
                                    <td>
                                        <input type="number" className="small" value={invdata.price} onChange={(e) => updateprodprice("price", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Quantity</th>
                                    <td>
                                        <input value={invdata.quantity} type="number" className="small" min="1" max="100" onChange={(e) => updatequantity(e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Serial No(s)</th>
                                    <td>
                                        {invdata.sr_no.map((sr, i) =>
                                            <><input className="small" key={i} value={sr} onChange={(e) => updateserial(e, i)} /><br /></>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className='btns'>
                            <li><button onClick={submitHandler}>Submit</button></li>
                        </ul>
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getInwardInvoices })(Inventory)