import KuroLink from '../common/KuroLink'
import '../../styles/footer.css'

const Footer = () => {
    return (
        <div className="footer pg_mh">
            <div className="legal_footer">
                <div className="footer_links">
                    <div className="ft_cnt">
                        <div className="logo">
                            <KuroLink to="/"><svg fill="#ed2224" viewBox="0 0 1117 399.95"><title>Kuro Cadence Admin</title><path d="M314.08,695.75l-59.23,0L142.59,576.61V695.75H105V304.83h37.59V571.05L248.18,466.46l53.56.14L190,572.4Z" transform="translate(-105 -304.83)"/><path d="M620,616.29Q615.7,651,596.05,674t-40.71,26.69q-21.06,3.76-30.53,3.77-27.46,0-50.18-12.33t-35-37.52A132.91,132.91,0,0,1,432,621.33V462.7h41.35V601.2q0,27.28,14.14,47.76t37.78,20.47h5.57q19.47-2.53,33.61-18a51.93,51.93,0,0,0,14.14-36.23V462.7H620Z" transform="translate(-105 -304.83)"/><path d="M905.55,473.13,882.9,506.37q-12.27-11.58-25.48-11.58-18.4,0-33.5,19.39c-10.07,12.92-19.78,42.55-19.78,42.55V699.51H766.56v-233h33.83V522a123.32,123.32,0,0,1,22.82-42.05Q839,461,859.78,458,884.32,458,905.55,473.13Z" transform="translate(-105 -304.83)"/><path d="M1222,581.91q0,50.87-34.67,86.86t-83.29,36q-49.07,0-83.74-36T985.62,581.4q0-51.85,33.73-88.88t82.34-37q49.55,0,84.92,36.76T1222,581.91Zm-35.39,0q0-36.54-23.35-62.33t-56.85-25.79q-34.46,0-58.75,24.79t-24.3,60.32q0,35.55,23.6,61.59t57.08,26q33.51,0,58-24.54T1186.61,581.9Z" transform="translate(-105 -304.83)"/></svg></KuroLink>
                        </div>
                    </div>
                    <div className="disclaimer">
                        <p><strong>© 2020-2025, Kuro Cadence LLP or its affiliates.</strong></p>
                        <p><strong>W A R N I N G:</strong> This computer system is the property of Kuro Cadence LLP. It is restricted to authorized users only. If you are unauthorized, terminate access now. IT IS AN OFFENSE TO CONTINUE WITHOUT PROPER AUTHORIZATION. INDIVIDUALS WHO ATTEMPT UNAUTHORIZED ACCESS WILL BE PROSECUTED. By using this system, all users acknowledge notice of, and agree to comply with, the Internal Policies of Kuro Cadence LLP.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer