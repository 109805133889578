import { UPDATE_PRODUCTS, UPDATE_PRESETS } from '../actions/types'

const initialState = {
    prodData: null,
    presetData: null
}

const prods = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PRODUCTS:
            return {
                ...state,
                prodData: action.payload.prods,
                presetData: action.payload.presets
            }
        case UPDATE_PRESETS:
            return {
                ...state,
                presetData: action.payload
            }
        default:
            return state
    }
}

export default prods