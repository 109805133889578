import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getVendors } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import Select from 'react-select'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const CreatePO = ({match, user: { token }, admin: { vendors, accesslevels, bgDetails }, getVendors }) => {
    const { params: { batchid } } = match
    const [purchaseorder, setpurchaseorder] = useState({
        "vendor": "",
        "total_amount": 0,
        "type": "",
        "tags": [],
        "narration": [],
        "entity": accesslevels.find(item => (item.purchase_orders === "edit" || item.purchase_orders === "write")).entity,
        "products": []
    })
    const [submitted, setsubmitted] = useState(false)
    const [podata, setpodata] = useState(null)
    const selectAllRef = useRef(null);
    const [tagselectall, setTagselectall] = useState(false)
    const history = useHistory();
    const typesOfpv = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Rent",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Expenses - Petty Cash",
        "Bank Loan",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]
    const tagOptionsHandler = () => {
        const tagOptions = {
            "vendor": VendorOptions,
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Rent": ["Rent"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS Machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        }
        return tagOptions[purchaseorder.type] || []
    }
    const typeOptions = typesOfpv ? typesOfpv.map((type) => ({ value: type, label: type })) : [];

    const access = accesslevels.find(item => (item.purchase_orders === "edit" || item.purchase_orders === "write"));
    useEffect(() => {
        if (!access) {
            history.push("/unauthorized")
        }
        getVendors()
    }, [getVendors])

    useEffect(() => {
        if (batchid) {
            const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            };

            axios
            .get(`${process.env.REACT_APP_KC_API_URL}kurostaff/indent?batchid=`+ batchid, config)
            .then((response) => {
                const data = response.data[0]; 
                setpurchaseorder((prevState) => ({
                ...prevState, 
                vendor: data.vendor,
                total_amount: data.totalprice,
                entity: data.entity,
                products: data.products
                }));
            })
            .catch((error) => {
                console.error("Error fetching purchase order:", error); 
            });
        }
        }, [batchid, token]); 

    const updatepo = (e, key, num = false) => {
        let temppo = { ...purchaseorder }
        if (key === "vendor" || key === "type") {
            temppo[key] = e.value
        } else {
            temppo[key] = (num === true) ? Number(e.target.value) : e.target.value
        }
        setpurchaseorder(temppo)
    }

    const updateselectedpotype = (potype) => {
        let selectedpotype = [...purchaseorder.tags];

        if (selectedpotype.includes(potype)) {
            selectedpotype = selectedpotype.filter(type => type !== potype);
            setTagselectall(false);
        } else {
            selectedpotype.push(potype)
            setTagselectall(selectedpotype.length === tagOptionsHandler(purchaseorder.type).length);
        }

        setpurchaseorder({ ...purchaseorder, tags: [...selectedpotype] })
    }

    const getSelectedoption = (key) => {
        return { value: purchaseorder[key], label: purchaseorder[key] }
    }

    const updatenarration = (e, index) => {
        let temppo = { ...purchaseorder }
        temppo["narration"][index]["desc"] = e.target.value
        setpurchaseorder(temppo)
    }

    const addrow = () => {
        let temppo = { ...purchaseorder }
        temppo["narration"].push({ 'desc': '', 'narration_date': '', 'narration_by': '' })
        setpurchaseorder(temppo)
    }

    const removerow = (id) => {
        let temppo = { ...purchaseorder }
        temppo.narration = temppo.narration.filter((_, i) => i !== id)
        setpurchaseorder(temppo)
    }

    const toggleselectall = () => {
        let tempPv = { ...purchaseorder }
        let selectedTags = []
        if (tagselectall) {
            setTagselectall(false);
            selectedTags = []
        } else {
            tagOptionsHandler(purchaseorder.type).map((tag) => {
                selectedTags.push(tag)
            })
            setTagselectall(true)
        }
        tempPv.tags = [...selectedTags];
        setpurchaseorder(tempPv)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?batchid=' + batchid, purchaseorder, config)
            .then((res) => {
                setpodata(res.data[0])
                setsubmitted(true)
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const backHandler = () => {
        setpodata(null)
        setsubmitted(false)
    }

    const VendorOptions = vendors ? vendors.map((vendor) => ({ value: vendor.vendor_code, label: vendor.name })) : [];

    return (
        <div className="kuro-po txt-light">
            <h2 className="txt-light-grey">Purchase Order</h2>
            {submitted && podata !== null ? (
                <div>
                    <p>Purchase Order is Succesfully Created.</p>
                    <p>PO Number: <strong>{podata.po_no}</strong></p>
                    <p>Vendor: <strong>
                        {vendors.filter(vendor => vendor.vendor_code === podata.vendor).map((vendor, i) =>
                            vendor.name
                        )}
                    </strong></p>
                    <p>Amount: <strong>{podata.total_amount}</strong></p>
                    <ul className="btns">
                        <li>
                            <button onClick={backHandler}>Back</button>
                        </li>
                    </ul>
                </div>
            ) : (
                <>
                    <table className="border" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <th>Vendor</th>
                                <td>
                                    <Select
                                        classNamePrefix="kuro-search-select"
                                        options={VendorOptions}
                                        value={getSelectedoption("vendor")}
                                        onChange={(e) => updatepo(e, "vendor")}
                                        placeholder="Select po"
                                        className="react-select-container"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>
                                    <Select
                                        classNamePrefix="kuro-search-select"
                                        options={typeOptions}
                                        value={getSelectedoption("type")}
                                        onChange={(e) => updatepo(e, "type")}
                                        placeholder="Select Type Of PV"
                                        className="react-select-container"
                                    />
                                </td>
                            </tr>
                            <tr>
                                {purchaseorder.type ? (
                                    <>
                                        <th>Tags</th>
                                        <td>
                                            {tagOptionsHandler(purchaseorder.type) ? (
                                                <>
                                                    {(purchaseorder.type !== "Expenses - Salaries" && tagOptionsHandler(purchaseorder.type).length > 1) && <div className="checkbox-container">
                                                        <span>
                                                            <input
                                                                type="checkbox"
                                                                ref={selectAllRef}
                                                                checked={tagselectall}
                                                                onChange={() => toggleselectall()}
                                                            />
                                                        </span>
                                                        <span>Select All</span>
                                                    </div>}
                                                    {tagOptionsHandler(purchaseorder.type).map((po_type) => (
                                                        <div key={po_type} className="checkbox-container-inner" >
                                                            <span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={purchaseorder.tags.includes(po_type)}
                                                                    onChange={() => updateselectedpotype(po_type)}
                                                                />
                                                            </span>
                                                            <span>{po_type}</span>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <p>No Options</p>
                                            )}
                                        </td>
                                    </>
                                ) : null}


                            </tr>
                            <tr>
                                <th>PO Total</th>
                                <td>
                                    <input type="number" className="medium" value={purchaseorder.total_amount} onChange={(e) => updatepo(e, "total_amount", true)} />
                                </td>
                            </tr>
                            <tr>
                                <th>products</th>
                                <td>{purchaseorder.products.map((item, i)=> (
                                    <tr key={i}>{item.title}</tr>
                                ))}</td>
                            </tr>
                            <tr>
                                <th>PO Narration</th>
                                <td>
                                    {purchaseorder.narration.length > 0 ? (
                                        purchaseorder.narration.map((item, i) =>
                                            <div key={i}>
                                                <textarea value={item.desc} onChange={(e) => updatenarration(e, i)} />
                                                <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                {i === purchaseorder.narration.length - 1 && <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                                            </div>
                                        )
                                    ) : (
                                        <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Entity</th>
                                <td>
                                    <select className="type" name="entity" value={purchaseorder.entity} onChange={(e) => updatepo(e, "entity",)} >
                                        {accesslevels.map((item, index) => (item.purchase_orders === "edit" || item.purchase_orders === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option>) : (null))}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul className="btns">
                        <li>
                            <button onClick={submitHandler}>Submit</button>
                        </li>
                        <li>
                            <KuroLink to="/purchase-orders"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreatePO)