import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getVendors } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/tabs.css'
import note from '../assets/img/button.png'
import moment from 'moment-timezone'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')


const InwardDebitNotes = ({ user: { token, userDetails }, admin: { vendors, accesslevels }, getVendors }) => {
    const history = useHistory()
    const [tabIndex, setTabIndex] = useState(0)
    const [dnData, setdnData] = useState([])
    const access = accesslevels.find(item => item.inward_debitnotes !== "NA")
    const editaccess = accesslevels.find(item => item.inward_debitnotes === "edit")

    const getInwardDebitNotes = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes?foradmin=true', config).then(res => {
            setdnData(res.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }

    useEffect(() => {
        if (!access) {
            history.push("/unauthorized")
        }
        getInwardDebitNotes()
        getVendors()
    }, [])

    const approveHandler = (debitnoteid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "active": true }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes?debitnoteid=' + debitnoteid, data, config)
            .then((res) => {
                getInwardDebitNotes()
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    return (
        <div className="txt-m txt-light-grey">
            <h2 className="txt-light-grey">Purchase Debit Notes</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} className='note_img' />:
                <span className="note_text">lorem text</span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            {accesslevels.find(item => item.inward_debitnotes === "edit" || item.inward_debitnotes === "write") &&
                <div className='col_2'>
                    <KuroLink to="/create-inward-debitnote"><button>Add DebitNote</button></KuroLink>
                </div>
            }
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Pending Authorization</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Past Debit Notes</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    {dnData !== null && dnData.filter(debitnote => debitnote.active === false).length > 0 ? (
                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    {userDetails.access === "Super" ? <th>Debit Note Id</th> : null}
                                    <th className='show_tab'>Vendor</th>
                                    <th className='show_tab'>Debit Note No.</th>
                                    <th className='show_tab'>Debit Note Date</th>
                                    <th>Debit Note Total</th>
                                    <th className='show_tab'>CGST/SGST</th>
                                    <th className='show_tab'>IGST</th>
                                    <th className='show_tab'>Payment Status</th>
                                    <th className='show_tab'>Settled</th>
                                    {userDetails.access === "Super" && <th></th>}
                                </tr>
                                {dnData !== null && dnData.filter(debitnote => debitnote.active === false).map((debitnote, i) =>
                                    <tr key={i}>
                                        {userDetails.access === "Super" ? <td className='show_tab'>{debitnote.debitnoteid}</td> : null}
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === debitnote.vendor)[0].name + (debitnote.gstin === '' ? '' : ' [' + debitnote.gstin + ']')}</td>
                                        <td><KuroLink to={'inward-debitnotes/' + debitnote.debitnoteid}>{debitnote.debitnote_no}</KuroLink></td>
                                        <td className='show_tab'>{debitnote.debitnote_date === "" ? "" : moment(debitnote.debitnote_date).format('DD-MM-YYYY')}</td>
                                        <td>{CurrencyFormat(debitnote.totalprice)}</td>
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === debitnote.vendor)[0].name + (debitnote.gstin === '' ? '' : ' [' + debitnote.gstin + ']')}</td>
                                        <td className='show_tab'>{debitnote.cgst}</td>
                                        <td className='show_tab'>{debitnote.igst}</td>
                                        <td className='show_tab'>{debitnote.pay_status}</td>
                                        <td className='show_tab'>{debitnote.settled}</td>
                                        {userDetails.access === "Super" && <td>
                                            <ul className='btns'>
                                                <li>
                                                    <button onClick={(e) => approveHandler(debitnote.debitnoteid)}> Approve</button>
                                                </li>
                                            </ul>
                                        </td>}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Purchase DebitNotes to Approve</p>
                    )}
                </div>
                <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                    {dnData !== null && dnData.filter(debitnote => debitnote.active === true).length > 0 ? (
                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    {userDetails.access === "Super" ? <th className='show_tab'>Debit Note Id</th> : null}
                                    <th>Vendor</th>
                                    <th className='show_tab'>Debit Note No.</th>
                                    <th className='show_tab'>Debit Note Date</th>
                                    <th>Debit Note Total</th>
                                    <th className='show_tab'>CGST/SGST</th>
                                    <th className='show_tab'>IGST</th>
                                    <th className='show_tab'>Payment Status</th>
                                    <th className='show_tab'>Settled</th>
                                </tr>
                                {dnData !== null && dnData.filter(debitnote => debitnote.active === true).map((debitnote, i) =>
                                    <tr key={i}>
                                        {userDetails.access === "Super" ? <td className='show_tab'>{debitnote.debitnoteid}</td> : null}
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === debitnote.vendor)[0].name + (debitnote.gstin === '' ? '' : ' [' + debitnote.gstin + ']')}</td>
                                        <td className='show_tab'><KuroLink to={'inward-debitnotes/' + debitnote.debitnoteid}>{debitnote.debitnote_no}</KuroLink></td>
                                        <td className='show_tab'>{debitnote.debitnote_date === "" ? "" : moment(debitnote.debitnote_date).format('DD-MM-YYYY')}</td>
                                        <td>{CurrencyFormat(debitnote.totalprice)}</td>
                                        <td className='show_tab'>{debitnote.cgst}</td>
                                        <td className='show_tab'>{debitnote.igst}</td>
                                        <td className='show_tab'>{debitnote.pay_status}</td>
                                        <td className='show_tab'>{debitnote.settled}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Purchase DebitNotes to Show</p>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(InwardDebitNotes)