import React, { useEffect, useState } from 'react'
import KuroLink from '../components/common/KuroLink'
import { connect } from 'react-redux'
import axios from 'axios'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const StockColl = ({ match, user: { token, userDetails }, products: { prodData } }) => {

    const { params: { prodColl } } = match
    const [stockdata, setstockdata] = useState(null)
    const history = useHistory();

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?collection=' + prodColl, config).then(res => {
            setstockdata(res.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [prodColl])

    return (
        <div className="prods mx-width txt-light-grey">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/stock-register">Stock Register</KuroLink> &gt; {prodColl}</p>
            <div className="page-title">
                <h1 className='stock-reg-title' style={{ color: "white", fontWeight: 500 }}>{prodColl}</h1>
            </div>
            {stockdata !== null && stockdata.length > 0 ? (
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            {userDetails.access === "Super" && <th>Product ID</th>}
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Avg Price</th>
                            <th>Min Price</th>
                            <th>Max Price</th>
                            {userDetails.access === "Super" && <th>Total</th>}
                        </tr>
                    {stockdata.map((prod) =>
                        <tr>
                            {userDetails.access === "Super" && prodData !== null && <td>{prod.productid}</td>}
                            <td><KuroLink to={"/stock-register/prod/" + prod.productid}>{prodData !== null && prodData[prodColl].filter(item => item.productid === prod.productid)[0]["title"]}</KuroLink></td>
                            <td>{prod.quantity}</td>
                            <td>{prod.avgprice}</td>
                            <td>{prod.minprice}</td>
                            <td>{prod.maxprice}</td>
                            {userDetails.access === "Super" && <td>{prod.totalprice}</td>}
                        </tr>
                    )}
                    </tbody>
                </table>
            ) : (
                <p>No Products Found</p>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(StockColl)