import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { getPresets } from '../actions/products'
import { ToWords } from 'to-words'
import note from '../assets/img/button.png'
import KuroLink from '../components/common/KuroLink'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { mapData } from '../data/mappings'
import '../styles/products.css'
import '../styles/tabs.css'
import '../styles/table.css'
import '../styles/react-select.css'
import ImgArrow from '../assets/img/arrow.png'

moment.tz.setDefault('Asia/Kolkata')

const CreateEstimate = ({ user: { token }, products: { prodData, presetData }, admin: { provinces, accesslevels, bgDetails }, getPresets }) => {

    const [estimateData, setestimateData] = useState({ 'type': 'preset', 'builds': [], 'products': [], 'services': [], 'totalpricebgst': 0, 'taxes': { "5": 0, "12": 0, "18": 0, "28": 0 }, 'gst': 0, 'cgst': 0, 'totalprice': 0, 'totaldiscount': 0, 'validity': new Date(), 'roundoff': 0, 'notes': { 'customer': '', 'admin': '' }, 'estimate_date': new Date(), 'user': { 'name': '', 'phone': '' }, 'billadd': { 'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': '' }, 'addressflag': true, version: 1, 'terms': false, 'entity': accesslevels.find(item => item.estimates === "edit" || item.estimates === "write").entity, 'b2b': false })
    const [estimateInfo, setestimateInfo] = useState(null)
    const [preview, setpreview] = useState(false)
    const [showprice, setshowprice] = useState(false)
    const [showterms, setShowterms] = useState("")
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })
    const history = useHistory();
    const access = accesslevels.find(item => item.estimates === "edit" || item.estimates == "write");
    const toWords = new ToWords()

    const { location: { state } } = history;
    const [servicerequest, setservicerequest] = useState(state ? state : null)

    useEffect(() => {
        if (servicerequest !== null) {
            let tempEstimateData = estimateData;
            tempEstimateData.entity = servicerequest.entity
            tempEstimateData.user.name = servicerequest.name
            tempEstimateData.user.phone = servicerequest.phone
            tempEstimateData.notes.customer = servicerequest.logs[0].customer_query
            tempEstimateData.notes.admin = servicerequest.logs[0].admin_action

            setestimateData(tempEstimateData)
        }
    }, [servicerequest])

    const defbuild = {
        "title": "Kuro Custom PC",
        "presets": [
            {
                "type": "cpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "mob",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ram",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "gpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "psu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ssd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "hdd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "cooler",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "tower",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "fans",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "wifi",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "os",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "shp_fees",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "build_fees",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "warranty",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "margin",
                "presetid": "PREMAR0006",
                "kmar": 10
            }
        ],
        "hsncode": "",
        "tax_rate": 18,
        "subtotal": 0,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "pricebgst": 0,
        "price": 0,
        "quantity": 1,
        "totalpricebgst": 0,
        "totalprice": 0
    }

    const compTypeHandler = (type) => {
        switch (type) {
            case "cpu":
                return "Processor:"
            case "mob":
                return "Motherboard:"
            case "ram":
                return "RAM:"
            case "gpu":
                return "Graphics Card:"
            case "ssd":
                return "SSD:"
            case "hdd":
                return "HDD:"
            case "psu":
                return "Power Supply:"
            case "cooler":
                return "CPU Cooler:"
            case "tower":
                return "Case:"
            case "fans":
                return "Fans:"
            case "wifi":
                return "WiFi:"
            case "os":
                return "Operating System:"
            default:
                return ""
        }
    }
    const updatevalue = (key, i, e) => {
        const temp = { ...estimateData }
        temp[key][i] = e.target.value
        setestimateData(temp)
    };

    const removefield = (key, bindex) => {
        const temp = { ...estimateData }
        temp[key] = temp.payment_terms.filter((_, idx) => idx !== bindex)
        setestimateData(temp)
    }

    const addfield = (key, bindex) => {
        const temp = { ...estimateData }
        if (key === 'payment_terms') {
            temp.payment_terms.splice(bindex, 0, '')
        }
        setestimateData(temp)
    }

    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return;
        }
        getPresets()
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/adminportal`, config)
            .then((res) => {
                const temp_terms_data = { ...estimateData }
                temp_terms_data.payment_terms = res.data[0].payment_terms
                temp_terms_data.ds_ot = res.data[0].ds_ot
                setestimateData(temp_terms_data)
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    history.push("/unauthorized");
                }
            });
    }, [])

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempestimate) => {
        let roundoff = 0
        if (tempestimate.billadd.state === "Telangana") {
            roundoff = ((tempestimate.totalprice * 100) - (200 * tempestimate.cgst) - (100 * tempestimate.totalpricebgst))
        } else {
            roundoff = ((tempestimate.totalprice * 100) - (100 * tempestimate.gst) - (100 * tempestimate.totalpricebgst))
        }
        tempestimate.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return tempestimate
    }

    const updatetotal = (tempestimate) => {
        tempestimate.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i = 0; i < tempestimate.builds.length; i++) {
            totaldiscount += tempestimate.builds[i].totaldiscount
            subtotals[tempestimate.builds[i].tax_rate] += tempestimate.builds[i].totalprice
            totalprice += tempestimate.builds[i].totalprice
        }
        for (let i = 0; i < tempestimate.products.length; i++) {
            totaldiscount += tempestimate.products[i].totaldiscount
            subtotals[tempestimate.products[i].tax_rate] += tempestimate.products[i].totalprice
            totalprice += tempestimate.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (tempestimate.billadd.state === "Telangana") {
                tempestimate.taxes[key] = trcgst
            } else {
                tempestimate.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        tempestimate.totaldiscount = totaldiscount
        tempestimate.totalprice = totalprice
        tempestimate.totalpricebgst = round("round", totalpricebgst, 2)
        tempestimate.gst = round("round", gst, 2)
        tempestimate.cgst = round("round", cgst, 2)

        tempestimate = updateroundoff(tempestimate)
        return tempestimate
    }

    const updateestimatedate = (date) => {
        let tempestimate = { ...estimateData }
        tempestimate.estimate_date = (date === null) ? "" : date
        setestimateData(tempestimate)
    }

    const updatevalidity = (date) => {
        let tempestimate = { ...estimateData }
        tempestimate["validity"] = (date === null) ? "" : date
        setestimateData(tempestimate)
    }

    const updateentity = (e) => {
        let tempestimate = { ...estimateData }
        tempestimate["entity"] = e.target.value
        setestimateData(tempestimate)
    }

    const updateuser = (key, e) => {
        let tempestimate = { ...estimateData }
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempestimate.user[key] = value
        tempestimate.billadd[key] = value
        setestimateData(tempestimate)
    }

    const updatepangstin = (tempestimate) => {
        let tempmsg = { ...errmsg }
        if (tempestimate.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(tempestimate.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (tempestimate.billadd.gstin !== "" && (tempestimate.billadd.gstin.length !== 15 || tempestimate.billadd.gstin.slice(2, 12) !== tempestimate.billadd.pan || !(/^[0-9]*$/).test(tempestimate.billadd.gstin.slice(0, 2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempestimate = { ...estimateData }
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({ ...msg, state: "Please select the State" }))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            tempestimate[key][subkey] = value
            tempestimate = updatetotal(tempestimate)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempestimate[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempestimate[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempestimate)
        } else {
            tempestimate[key][subkey] = value
        }
        setestimateData(tempestimate)
    }

    const updateaddressflag = (value) => {
        let tempestimate = { ...estimateData }
        tempestimate["addressflag"] = value
        if (value) {
            delete tempestimate["shpadd"]
        } else {
            tempestimate.shpadd = { 'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': '' }
        }
        setestimateData(tempestimate)
    }

    const updatenotes = (key, e) => {
        let tempestimate = { ...estimateData }
        tempestimate.notes[key] = e.target.value
        setestimateData(tempestimate)
    }

    const buildtotal = (tempestimate, bindex) => {
        if (tempestimate.builds.length > 0) {
            let subtotal = 0
            let kmar = 10
            for (let preset of tempestimate.builds[bindex].presets) {
                if (preset.type === "margin") {
                    kmar = preset.kmar / 100
                } else {
                    subtotal += Number(preset.totalprice)
                }
            }
            let buildprice = round("round", subtotal * (1 + kmar))
            let price = buildprice - tempestimate.builds[bindex].discount
            let quantity = tempestimate.builds[bindex].quantity
            let totalprice = price * quantity
            tempestimate.builds[bindex].subtotal = subtotal
            tempestimate.builds[bindex].buildprice = buildprice
            tempestimate.builds[bindex].price = price
            tempestimate.builds[bindex].pricebgst = round("round", price / 1.18, 2)
            tempestimate.builds[bindex].totalprice = totalprice
            tempestimate.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempestimate = updatetotal(tempestimate)
        }
        return tempestimate
    }

    const createBuild = (buildpresets) => {
        let tempestimate = { ...estimateData }
        let builds = []
        let build = {}
        build.title = "Kuro Custom PC"
        build.presets = []
        for (let item in buildpresets) {
            let ps = {}
            const presetid = buildpresets[item]
            if (presetid !== "") {
                ps.type = item
                ps.presetid = presetid
                if (ps.type === "margin") {
                    ps.kmar = presetData.filter(ps => ps.type === item)[0].list.filter(item => item.presetid === presetid)[0].kmar
                } else {
                    ps.quantity = 1
                    ps.title = presetData.filter(ps => ps.type === item)[0].list.filter(item => item.presetid === presetid)[0].title
                    ps.price = presetData.filter(ps => ps.type === item)[0].list.filter(item => item.presetid === presetid)[0].price
                    ps.totalprice = presetData.filter(ps => ps.type === item)[0].list.filter(item => item.presetid === presetid)[0].price
                }
                build.presets.push(ps)
            }
        }
        build.hsncode = ""
        build.tax_rate = 18
        build.subtotal = 0
        build.buildprice = 0
        build.discount = 0
        build.totaldiscount = 0
        build.pricebgst = 0
        build.price = 0
        build.quantity = 1
        build.totalpricebgst = 0
        build.totalprice = 0
        builds.push(build)
        tempestimate.builds = builds
        tempestimate = buildtotal(tempestimate, 0)
        setestimateData(tempestimate)
    }

    const searchHandler = (e) => {
        const type = e.target.closest('.search_wrap').querySelector('.type').value
        let prodvalue = e.target.closest('.search_wrap').querySelector('.prodvalue').value
        if (prodvalue !== "") {
            if (type === "prod_url" && prodvalue.indexOf('/b/') > 0) {
                prodvalue = prodvalue.split("/b/")[1]
            }
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?purpose=estimates&' + type + '=' + prodvalue).then(res => {
                createBuild(res.data)
            })
        }
    }

    const clearHandler = () => {
        document.querySelector('#search_text').value = ''
    }

    const addbuild = (bindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds.splice(bindex + 1, 0, defbuild)
        setestimateData(tempestimate)
    }

    const removebuild = (bindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds.splice(bindex, 1)
        tempestimate = updatetotal(tempestimate)
        setestimateData(tempestimate)
    }

    const updatebuild = (bindex, e, key, num = false) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "buildprice" || key === "quantity") {
            tempestimate.builds[bindex].discount = 0
            tempestimate.builds[bindex].totaldiscount = 0
            tempestimate.builds[bindex].price = tempestimate.builds[bindex].buildprice
            let totalprice = tempestimate.builds[bindex].price * tempestimate.builds[bindex].quantity
            tempestimate.builds[bindex].pricebgst = round("round", tempestimate.builds[bindex].price / 1.18, 2)
            tempestimate.builds[bindex].totalprice = totalprice
            tempestimate.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempestimate = updatetotal(tempestimate)
        }
        setestimateData(tempestimate)
    }

    const updatebuilddiscount = (bindex, e) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex].discount = Number(e.target.value)
        tempestimate.builds[bindex].totaldiscount = Number(e.target.value) * tempestimate.builds[bindex].quantity
        tempestimate.builds[bindex].price = tempestimate.builds[bindex].buildprice - Number(e.target.value)
        tempestimate.builds[bindex].totalprice = tempestimate.builds[bindex].price * tempestimate.builds[bindex].quantity
        tempestimate.builds[bindex].pricebgst = round("round", tempestimate.builds[bindex].price / 1.18, 2)
        tempestimate.builds[bindex].totalpricebgst = round("round", tempestimate.builds[bindex].totalprice / 1.18, 2)
        tempestimate = updatetotal(tempestimate)
        setestimateData(tempestimate)
    }

    const addpreset = (bindex, pindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex].presets.splice(pindex + 1, 0, { 'type': '', 'presetid': '', 'price': 0, 'quantity': 1, 'title': '', 'totalprice': 0 })
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        setestimateData(tempestimate)
    }

    const removepreset = (bindex, pindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex].presets.splice(pindex, 1)
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        tempestimate = buildtotal(tempestimate, bindex)
        setestimateData(tempestimate)
    }

    const updatepreset = (bindex, pindex, key, e, num = false) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex].presets[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key !== "title") {
            tempestimate.builds[bindex].discount = 0
            tempestimate.builds[bindex].totaldiscount = 0
            if (key === "price" || key === "quantity") {
                let totalprice = tempestimate.builds[bindex].presets[pindex].price * tempestimate.builds[bindex].presets[pindex].quantity
                tempestimate.builds[bindex].presets[pindex].totalprice = totalprice
                tempestimate = buildtotal(tempestimate, bindex)
            } else {
                tempestimate.builds[bindex].presets[pindex].presetid = ""
                tempestimate.builds[bindex].presets[pindex].title = ""
                tempestimate.builds[bindex].presets[pindex].price = 0
                tempestimate.builds[bindex].presets[pindex].quantity = 1
                tempestimate.builds[bindex].presets[pindex].totalprice = 0
            }
        }
        setestimateData(tempestimate)
    }

    const updatepresetmodel = (bindex, pindex, e) => {
        let tempestimate = { ...estimateData }
        let presetid = e.value
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        if (e) {
            tempestimate.builds[bindex].presets[pindex].presetid = presetid
            let title = (presetid === "other") ? '' : presetData.filter(ps => ps.type === tempestimate.builds[bindex].presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].title
            let price = (presetid === "other") ? 0 : presetData.filter(ps => ps.type === tempestimate.builds[bindex].presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].price
            let totalprice = price * tempestimate.builds[bindex].presets[pindex].quantity
            tempestimate.builds[bindex].presets[pindex].title = title
            tempestimate.builds[bindex].presets[pindex].price = price
            tempestimate.builds[bindex].presets[pindex].totalprice = totalprice
            tempestimate = buildtotal(tempestimate, bindex)
        } else {
            tempestimate.builds[bindex].presets[pindex].presetid = ''
            tempestimate.builds[bindex].presets[pindex].title = ''
            tempestimate.builds[bindex].presets[pindex].price = 0
            tempestimate.builds[bindex].presets[pindex].totalprice = 0
            tempestimate = buildtotal(tempestimate, bindex)
        }
        setestimateData(tempestimate)
    }

    const getselectedcomp = (comp) => {
        let label = ""

        if (comp.presetid !== "") {
            if (comp.presetid === "other") {
                label = "Other"
            } else {
                label = presetData !== null && presetData.filter(ps => ps.type === comp.type)[0].list.filter(item => item.presetid === comp.presetid)[0].kuro_title
            }
        }

        return { value: comp.presetid, label: label }
    }

    const updatemargin = (bindex, e) => {
        let tempestimate = { ...estimateData }
        tempestimate.builds[bindex].presets.filter(ps => ps.type === "margin")[0].presetid = e.target.value
        tempestimate.builds[bindex].presets.filter(ps => ps.type === "margin")[0].kmar = presetData.filter(ps => ps.type === "margin")[0]["list"].filter(item => item.presetid === e.target.value)[0]["kmar"]
        tempestimate.builds[bindex].discount = 0
        tempestimate.builds[bindex].totaldiscount = 0
        tempestimate = buildtotal(tempestimate, bindex)
        setestimateData(tempestimate)
    }

    const addprod = (pindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.products.splice(pindex + 1, 0, { 'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18 })
        setestimateData(tempestimate)
    }

    const removeprod = (pindex) => {
        let tempestimate = { ...estimateData }
        tempestimate.products.splice(pindex, 1)
        tempestimate = updatetotal(tempestimate)
        setestimateData(tempestimate)
    }

    const prodtotal = (tempestimate, pindex) => {
        if (tempestimate.products.length > 0) {
            const quantity = tempestimate.products[pindex].quantity
            const price = tempestimate.products[pindex].prodprice - tempestimate.products[pindex].discount
            const totalprice = price * quantity
            tempestimate.products[pindex].pricebgst = round("round", price / (1 + (tempestimate.products[pindex].tax_rate / 100)), 2)
            tempestimate.products[pindex].price = price
            tempestimate.products[pindex].totalpricebgst = round("round", totalprice / (1 + (tempestimate.products[pindex].tax_rate / 100)), 2)
            tempestimate.products[pindex].totalprice = totalprice
            tempestimate = updatetotal(tempestimate)
        }
        return tempestimate
    }

    const getoptionlabel = (pindex, key, value) => {
        let prod = estimateData.products[pindex]
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (pindex, key, e, num = false) => {
        let tempestimate = { ...estimateData }
        tempestimate.products[pindex].discount = 0
        tempestimate.products[pindex].totaldiscount = 0
        tempestimate.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" && key === "type" && key === "category" && key === "maker") {
            tempestimate.products[pindex].productid = ""
            tempestimate.products[pindex].title = ""
            tempestimate.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            tempestimate = prodtotal(tempestimate, pindex)
        }
        setestimateData(tempestimate)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let tempestimate = { ...estimateData }
        tempestimate.products[pindex].productid = prodid
        tempestimate.products[pindex].discount = 0
        tempestimate.products[pindex].totaldiscount = 0
        if (e) {
            tempestimate.products[pindex].title = (prodid === "other") ? "" : prodData[collection].filter(item => item.productid === prodid)[0].title
            tempestimate.products[pindex].prodprice = (prodid === "other") ? 0 : prodData[collection].filter(item => item.productid === prodid)[0].price.kuro_on
        } else {
            tempestimate.products[pindex].title = ''
            tempestimate.products[pindex].productid = ''
            tempestimate.products[pindex].prodprice = 0
        }
        tempestimate = prodtotal(tempestimate, pindex)
        setestimateData(tempestimate)
    }

    const updateproddiscount = (pindex, e) => {
        let tempestimate = { ...estimateData }
        tempestimate.products[pindex].discount = Number(e.target.value)
        tempestimate.products[pindex].totaldiscount = tempestimate.products[pindex].discount * tempestimate.products[pindex].quantity
        tempestimate = prodtotal(tempestimate, pindex)
        setestimateData(tempestimate)
    }

    const updateFlag = (key, value) => {
        let tempestimate = { ...estimateData }
        tempestimate[key] = value
        setestimateData(tempestimate)
    }

    const compOptions = (comp) => {
        const tempArray = []
        presetData !== null && comp.type !== "" && comp.type !== "other" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title })
            )
        })
        tempArray.push({ value: 'other', label: 'Other' })
        return tempArray
    }

    const prodOptions = (prod) => {
        let options = []
        if (prodData && prod.collection && prod.type) {
            options = prodData[prod.collection].filter(_ => _.type === prod.type && _.maker === prod.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
        }
        return options
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const createestimate = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let estimate = estimateData
        if (estimate.billadd.state !== "") {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates', estimate, config)
                .then((res) => {
                    setestimateInfo(res.data[0])
                    setpreview(true)
                })
        }
    }

    var fileDownload = require('js-file-download')

    const downloadestimate = () => {

        const est_no = estimateInfo["estimate_no"]

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + est_no + "&download=true&version=1", config)
            .then((res) => {
                fileDownload(res.data, est_no + '.pdf')
            })
    }

    return (
        <div className="hp20 estimate-preset-creation txt-light-grey">
            {preview ? (
                estimateInfo !== null &&
                <div className="estimate_details wide_table">
                    <h2>Preview</h2>
                    <table className="bg_black ph_20" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className='txt-center'>
                                    <svg className='logo' viewBox="200 250 1300 700"><title>Kuro Gaming</title><polygon fill="#ee2d30" points="850.68 688.03 885.99 611.01 1180.3 611.01 1264.86 556.53 838.61 553.03 850.68 688.03" /><polygon fill="#ee2d30" points="433.12 715.79 274.77 541.21 753.1 54.3 1029.11 54.3 549.72 541.21 706.98 712.95 433.12 715.79" /><polygon fill="#ee2d30" points="809.78 831.25 850.75 879 1099.35 563.66 850.68 563.95 850.68 688.03 756.3 541.21 1426.01 541.21 850.75 1194.56 532.77 830.61 809.78 831.25" /></svg>
                                </td>
                                <td><p>Name: &nbsp;&nbsp;<span className='txt-light'>{estimateInfo.user.name}</span></p>
                                    Mobile: <span className='txt-light'>{estimateInfo.user.phone}</span></td>
                                <td className='txt-right'><p>Estimate No: <span className='txt-light'>{estimateInfo.estimate_no}</span></p>
                                    Estimate Date: <span className='txt-light'>{moment(estimateInfo.estimate_date).format('DD-MM-YYYY')}</span>
                                    {estimateInfo.validity !== "" &&
                                        <>
                                            Validity Upto: {moment(estimateInfo.validity).format('DD-MM-YYYY')}
                                        </>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="even_odd" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <th colSpan="6">Product Description</th>
                                <th>QTY</th>
                                <th colSpan="3">Unit Price</th>
                                <th colSpan="3">Total Amount</th>
                            </tr>
                            {estimateInfo.builds.map((build, i) =>
                                <tr key={i}>
                                    <td colSpan="6">
                                        <b>{build.title}</b><br />
                                        {build.presets.map((preset, j) =>
                                            preset.type !== "margin" && preset.type !== "shp_fees" && preset.type !== "build_fees" && preset.type !== "warranty" &&
                                            <span key={j}>{(preset.type !== "" && preset.title !== "") && <> {compTypeHandler(preset.type)}</>} {preset.title !== "" && <>{preset.title} <br /></>}</span>
                                        )}
                                    </td>
                                    <td>{build.quantity}</td>
                                    <td colSpan="3">{build.price}</td>
                                    <td className="text_right" colSpan="3">{build.totalprice}</td>
                                </tr>
                            )}
                            {estimateInfo.products.map((prod, i) =>
                                <tr key={i}>
                                    <td colSpan="6">{prod.title}</td>
                                    <td>{prod.quantity}</td>
                                    <td colSpan="3">{prod.price}</td>
                                    <td className="text_right" colSpan="3">{prod.totalprice}</td>
                                </tr>
                            )}
                            {estimateData.totaldiscount > 0 &&
                                <tr>
                                    <td className="text_right" colSpan="6"></td>
                                    <td className="text_right" colSpan="4"><b>Total Discount</b></td>
                                    <td className="text_right" colSpan="3"><b>Rs.{estimateInfo.totaldiscount}</b></td>
                                </tr>
                            }
                            <tr>
                                <td className="text_right" colSpan="6"></td>
                                <td className="text_right" colSpan="4"><b>Total Price</b></td>
                                <td className="text_right" colSpan="3"><b>Rs.{estimateInfo.totalprice}</b></td>
                            </tr>
                            <tr>
                                <td className="text_right" colSpan="6"></td>
                                <td className="text_right" colSpan="4">
                                    Tax Summary<br />
                                    {estimateInfo.billadd.state === "Telangana" ? (
                                        <>
                                            CGST @ 9%<br />SGST @ 9%
                                        </>
                                    ) : (
                                        "IGST @ 18%"
                                    )}
                                </td>
                                <td className="text_right" colSpan="3">
                                    &nbsp;<br />
                                    {estimateInfo.billadd.state === "Telangana" ? (
                                        <>
                                            Rs.{estimateInfo.cgst}<br />
                                            Rs.{estimateInfo.cgst}
                                        </>
                                    ) : (
                                        estimateInfo.gst
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="text_right" colSpan="13"><b>Amount in Words: {toWords.convert(estimateData.totalprice)} Rupees Only</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <ul className="btns">
                        <li>
                            <KuroLink to={"/estimates/" + estimateInfo.estimate_no}><button>Back</button></KuroLink>
                        </li>
                        <li>
                            <button onClick={downloadestimate}>Download</button>
                        </li>
                    </ul>
                </div>
            ) : (
                <>
                    <div className="estimate_preset">
                        <h2 className='txt-light'>Create Estimate</h2>
                        <div className="note">
                            <p>Instructions </p>
                            <img src={note} alt='instruction' className='note_img' />:
                            <span className="note_text">
                                <b>Loading existing builds:</b> Enter the URL of the existing build (from kurogaming.com) or enter the productid of the build. Now the components/details of build are loaded and editable.<br />
                                <b>Adding new builds:</b> Click '+' icon, then Enter the title of the build and select the components.<br />
                                <b>Adding new peripherals:</b> Click '+' icon, then Enter select Collection, Type, Category, Brand and Model along with the title of the peripheral.<br />
                            </span>
                        </div>
                        <div>
                            <p className='txt-right'>Note:lorem text</p><br />
                        </div>
                        <div className="prod-search">
                            <div className="search_wrap">
                                <select className="type">
                                    <option value="prod_url">ProductUrl</option>
                                    <option value="productid">ProductId</option>
                                </select>
                                <input id="search_text" className="prodvalue" />
                                <ul className='btns'>
                                    <li><button onClick={(e) => searchHandler(e)}>Search</button></li>
                                    <li><button onClick={clearHandler}>Clear</button></li>
                                </ul>
                            </div>
                        </div>
                        <div className='estimate_grid'>
                        <div className='preset_estimate'>
                                <div className="entity">
                                    <span>Estimate For:</span>
                                    <select className="type" value={estimateData.entity} onChange={(e) => updateentity(e)} >
                                        {accesslevels.map((item, i) => (item.estimates === "edit" || item.estimates === "write") ? (<><option key={i} value={item.entity}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option></>):(null))
                                        }
                                    </select>
                                </div>
                                <div className='view_price' style={{ marginBottom: '20px' }}>
                                    <span style={{ color: "#f00", fontWeight: "400" }}>Restricted View</span><input type="checkbox" checked={showprice ? "" : "checked"} className='checkbox' onClick={() => setshowprice(!showprice)} />
                                </div>
                                <div className='title_wrap col_mob'>
                                    <p>Builds</p>
                                    <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                </div>
                                {estimateData.builds.map((build, i) =>
                                    <table key={i} className="border full_width even_odd build" cellSpacing="0" cellPadding="0">
                                        <tbody>
                                            <>
                                                <tr>
                                                    <td colSpan="3">Build Title</td>
                                                    <td>Price</td>
                                                    <td>Quantity</td>
                                                    <td>Total Price</td>
                                                    <td>
                                                        <span className="prod-span" onClick={() => removebuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                        <span className="prod-span" onClick={() => addbuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3"><textarea onChange={(e) => updatebuild(i, e, "title")} value={build.title} /></td>
                                                    <td><input type="number" min="0" className='small' onWheel={disableScroll} value={build.buildprice} onChange={(e) => updatebuild(i, e, "buildprice", true)} /></td>
                                                    <td><input type="number" min="1" max="100" className='small' onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(i, e, "quantity", true)} /></td>
                                                    <td>{build.totalprice}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Type</td>
                                                    <td>Component</td>
                                                    <td>Title</td>
                                                    {showprice && <td>Price</td>}
                                                    <td>Quantity</td>
                                                    {showprice && <td>Total Price</td>}
                                                    <td><span className="prod-span" onClick={() => addpreset(i, -1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                                </tr>
                                                {build.presets.map((comp, j) =>
                                                    <>
                                                        {comp.type !== "margin" &&
                                                            <tr key={j}>
                                                                <td>
                                                                    <select value={comp.type} onChange={(e) => updatepreset(i, j, "type", e)}>
                                                                        <option value="">Select Type</option>
                                                                        <option value="cpu">Processor</option>
                                                                        <option value="mob">Motherboard</option>
                                                                        <option value="ram">RAM</option>
                                                                        <option value="gpu">Graphics Card</option>
                                                                        <option value="psu">Power Supply</option>
                                                                        <option value="ssd">SSD</option>
                                                                        <option value="hdd">HDD</option>
                                                                        <option value="cooler">CPU Cooler</option>
                                                                        <option value="tower">Case</option>
                                                                        <option value="fans">Fan</option>
                                                                        <option value="wifi">WiFi</option>
                                                                        <option value="os">Operating System</option>
                                                                        <option value="shp_fees">Shipping Fees</option>
                                                                        <option value="build_fees">Build Fees</option>
                                                                        <option value="warranty">Warranty</option>
                                                                        <option value="other">Other</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <Select classNamePrefix="kuro-search-select" options={compOptions(comp)} value={getselectedcomp(comp)} onChange={(e) => updatepresetmodel(i, j, e)} placeholder="Select Component" className="react-select-container" />
                                                                </td>
                                                                <td>
                                                                    <textarea value={comp.title} className="medium" onChange={(e) => updatepreset(i, j, "title", e)} />
                                                                </td>
                                                                {showprice &&
                                                                    <td>
                                                                        <input type="number" min="0" className='small' onWheel={disableScroll} value={comp.price} onChange={(e) => updatepreset(i, j, "price", e, true)} />
                                                                    </td>
                                                                }
                                                                {comp.type !== "os" && comp.type !== "shp_fees" && comp.type !== "build_fees" && comp.type !== "warranty" ? (
                                                                    <td>
                                                                        <input type="number" min="1" max="100" className='small' onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatepreset(i, j, "quantity", e, true)} />
                                                                    </td>
                                                                ) : (
                                                                    <td>NA</td>
                                                                )}
                                                                {showprice &&
                                                                    <td>
                                                                        {comp.totalprice}
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <span className="prod-span" onClick={() => addpreset(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                                    <span className="prod-span" onClick={() => removepreset(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </>
                                                )}
                                                {showprice &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td colSpan="2">Sub Total</td>
                                                            <td colSpan="2">{round("round", build.subtotal, 2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>KM(%)</td>
                                                            <td>
                                                                <select value={build.presets.filter(comp => comp.type === "margin")[0].presetid} onChange={(e) => updatemargin(i, e)}>
                                                                    {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].map((item, j) =>
                                                                        <option key={j} value={item.presetid}>{item.title}</option>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td></td>
                                                            <td colSpan="2">KM</td>
                                                            <td colSpan="2">{build.buildprice - build.subtotal}</td>
                                                        </tr>
                                                    </>
                                                }
                                                {showprice ? (
                                                    <>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td colSpan="2">Build Price</td>
                                                            <td colSpan="2">{build.buildprice}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td colSpan="2">Discount</td>
                                                            <td colSpan="2"><input value={build.discount} onChange={(e) => updatebuilddiscount(i, e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td colSpan="2">Total</td>
                                                            <td colSpan="2">{build.price}</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td>Build Price</td>
                                                            <td>{build.buildprice}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td>Discount</td>
                                                            <td><input value={build.discount} onChange={(e) => updatebuilddiscount(i, e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td>Total</td>
                                                            <td>{build.price}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </>
                                        </tbody>
                                    </table>
                                )}
                                <div className='title_wrap col_mob'>
                                    <p>Peripherals</p>
                                    <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                </div>
                                {estimateData.products.length > 0 &&
                                    <table className="border full_width even_odd products" cellSpacing="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td>Collection</td>
                                                <td>Type</td>
                                                <td>Category</td>
                                                <td>Brand</td>
                                                <td>Model</td>
                                                <td>Title</td>
                                                <td>Price</td>
                                                <td>Tax Rate</td>
                                                <td>Quantity</td>
                                                <td>Discount<br />(Per Unit)</td>
                                                <td>Total Price</td>
                                                <td></td>
                                            </tr>
                                            {estimateData.products.map((prod, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        <select className='small' value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                                            <option value="">Select Collection</option>
                                                            {prodData && Object.keys(prodData).sort().map((coll, j) =>
                                                                <option key={j} value={coll}>{getoptionlabel(i, "collection", coll)}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className='small' value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                                            <option value="">Select Type</option>
                                                            {prod.collection && prodData &&
                                                                [...new Set(prodData[prod.collection].map(_ => _.type))].sort().map((type, j) =>
                                                                <option key={j} value={type}>{getoptionlabel(i, "type", type)}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                                            <option value="">Select Category</option>
                                                        {prod.collection && prod.type && prodData &&
                                                            [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(_ => _.category))].sort().map((cat, j) =>
                                                                cat !== "" &&
                                                            <option key={j} value={cat}>{getoptionlabel(i, "category", cat)}</option>
                                                            )
                                                        }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className='small' value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                                            <option value="">Select Brand</option>
                                                            {prod.collection && prod.type && prodData &&
                                                                [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(item => item.maker))].sort().map((maker, j) =>
                                                                <option key={j} value={maker}>{maker}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                                                    </td>
                                                    <td>
                                                        <textarea value={prod.title} type="textarea" className="large" onChange={(e) => updateprod(i, "title", e)}></textarea>
                                                    </td>
                                                    <td>
                                                        <input value={prod.prodprice} type="number" className="small" min="0" onWheel={disableScroll} onChange={(e) => updateprod(i, "prodprice", e, true)} />
                                                    </td>
                                                    <td>
                                                        <select className="small" value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                                            <option value={5}>5</option>
                                                            <option value={12}>12</option>
                                                            <option value={18}>18</option>
                                                            <option value={28}>28</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input className='small' value={prod.quantity} type="number" min="1" max="100" onWheel={disableScroll} onChange={(e) => updateprod(i, "quantity", e, true)} />
                                                    </td>
                                                    <td>
                                                        <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} />
                                                    </td>
                                                    <td>
                                                        {round("round", prod.totalprice, 2)}
                                                    </td>
                                                    <td>
                                                        <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                        <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                                <div className='title_wrap col_mob'>
                                    <p>
                                        Payment Terms
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                                width: '30px',
                                                height: '30px',
                                                filter: 'invert()',
                                                marginLeft: '5px'
                                            }}
                                            onClick={() => setShowterms(showterms === 'payment_terms' ? '' : 'payment_terms')}
                                            className={showterms === 'payment_terms' ? 'img_mob' : 'img_mob down'}
                                            title="Invoice"
                                            alt="Invoice"
                                            src={ImgArrow}
                                        />
                                    </p>
                                </div>
                                <table className="build full_width border" cellPadding="0" cellSpacing="0">
                                    {showterms === 'payment_terms' ? (
                                        <>
                                            {Array.isArray(estimateData.payment_terms) && estimateData.payment_terms.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <textarea
                                                            className="admindata_width"
                                                            value={item}
                                                            onChange={e => updatevalue('payment_terms', i, e)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className="prod-span" onClick={() => removefield('payment_terms', i)}>
                                                            <svg fill="#92abcf" width="25" height="25" viewBox="0 0 24 24">
                                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                                            </svg>
                                                        </span>
                                                        <span className="prod-span" onClick={() => addfield('payment_terms', i)}>
                                                            <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" />
                                                            </svg>
                                                        </span>

                                                    </td>
                                                </tr>
                                            ))}</>) : null}
                                </table>
                                <div className='title_wrap col_mob'>
                                    <p>Dispatch Schedule & Other Terms
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                                width: '30px',
                                                height: '30px',
                                                filter: 'invert()',
                                                marginLeft: '5px'
                                            }}
                                            onClick={() => setShowterms(showterms === 'ds_ot' ? '' : 'ds_ot')}
                                            className={showterms === 'ds_ot' ? 'img_mob' : 'img_mob down'}
                                            title="Invoice"
                                            alt="Invoice"
                                            src={ImgArrow}
                                        />
                                    </p>
                                </div>
                                <table className="build full_width border" cellPadding="0" cellSpacing="0">
                                    {showterms === 'ds_ot' ? (
                                        <>
                                            {Array.isArray(estimateData.ds_ot) && estimateData.ds_ot.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <textarea
                                                            className="admindata_width"
                                                            value={item}
                                                            onChange={e => updatevalue('ds_ot', i, e)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className="prod-span" onClick={() => removefield('ds_ot', i)}>
                                                            <svg fill="#92abcf" width="25" height="25" viewBox="0 0 24 24">
                                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                                            </svg>
                                                        </span>
                                                        <span className="prod-span" onClick={() => addfield('ds_ot', i)}>
                                                            <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}</>) : null}
                                </table>
                            </div>
                            <div className='estimate-details'>
                                <table className="border full_width even_odd" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Estimate Date</td>
                                            <td>
                                                <DatePicker dateFormat='dd-MMM-yyyy' selected={estimateData.estimate_date} onChange={date => updateestimatedate(date)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td><input className="name" value={estimateData.user.name} onChange={(e) => updateuser("name", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td><input className="phone" value={estimateData.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="border full_width even_odd" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Taxable Amount</td>
                                            <td>{estimateData.totalpricebgst}</td>
                                        </tr>
                                        {estimateData.billadd.state === "Telangana" ? (
                                            <>
                                                <tr>
                                                    <td>SGST</td>
                                                    <td>{estimateData.cgst}</td>
                                                </tr>
                                                <tr>
                                                    <td>CGST</td>
                                                    <td>{estimateData.cgst}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td>IGST</td>
                                                <td>{estimateData.gst}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Round Off</td>
                                            <td>{estimateData.roundoff}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{estimateData.totalprice}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Discount</td>
                                            <td>{estimateData.totaldiscount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='border full_width even_odd'>
                                    <tbody>
                                        <tr>
                                            <td>Customer Instructions</td>
                                            <td><textarea value={estimateData.notes.customer} onChange={(e) => updatenotes("customer", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Admin Notes</td>
                                            <td><textarea value={estimateData.notes.admin} onChange={(e) => updatenotes("admin", e)} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul className='btns'>
                                    <li>
                                        <button onClick={createestimate}>Create Estimate</button>
                                    </li>
                                </ul>
                                {Object.keys(errmsg).map((err) =>
                                    <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                                )}
                                <table className="border full_width even_odd" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">Billing Address</td>
                                        </tr>
                                        <tr>
                                            <td>Company Name</td>
                                            <td><input className="company" value={estimateData.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td><input className="name" value={estimateData.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td><input className="phone" value={estimateData.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Address Line1</td>
                                            <td><textarea className="address" value={estimateData.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Address Line2</td>
                                            <td><textarea className="address" value={estimateData.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td><input className="city" value={estimateData.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Pincode</td>
                                            <td><input className="pincode" value={estimateData.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>
                                                <select className="state" value={estimateData.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                                    <option value="">Select State</option>
                                                    {provinces.map((province, i) =>
                                                        <option key={i} value={province["state"]}>{province["state"]}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PAN</td>
                                            <td><input className="pan" value={estimateData.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN</td>
                                            <td><input className="gstin" value={estimateData.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <label>Shipping Address same as Billing </label>
                                                <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!estimateData.addressflag)} checked={estimateData.addressflag} />
                                            </td>
                                        </tr>
                                        {!estimateData.addressflag &&
                                            <>
                                                <tr>
                                                    <td colSpan="2">Shipping Address</td>
                                                </tr>
                                                <tr>
                                                    <td>Company Name</td>
                                                    <td><input className="company" value={estimateData.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Name</td>
                                                    <td><input className="name" value={estimateData.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td><input className="phone" value={estimateData.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line1</td>
                                                    <td><textarea className="address" value={estimateData.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line2</td>
                                                    <td><textarea className="address" value={estimateData.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input className="city" value={estimateData.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Pincode</td>
                                                    <td><input className="pincode" value={estimateData.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>State</td>
                                                    <td>
                                                        <select className="state" value={estimateData.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                                            <option value="">Select State</option>
                                                            {provinces.map((province, i) =>
                                                                <option key={i} value={province["state"]}>{province["state"]}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>PAN</td>
                                                    <td><input className="pan" value={estimateData.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>GSTIN</td>
                                                    <td><input className="gstin" value={estimateData.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td>Estimate validity</td>
                                            <td>
                                                <DatePicker dateFormat='dd-MMM-yyyy' selected={estimateData.validity} onChange={date => updatevalidity(date)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Terms</td>
                                            <td><input type="checkbox" name="terms" onChange={(e) => updateFlag("terms", !estimateData.terms)} defaultChecked={estimateData.terms} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getPresets })(CreateEstimate)