import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import ResponseBox from './ResponseBox'
import '../styles/prod-addition.css'
import note from '../assets/img/button.png'


const AddProduct = ({ props, user: { token } }) => {

    const initProd = [{ productid: '', prod_url: '', title: '', collection: '', type: '', category: '', maker: '', images: [], summary: [], overview: { desc: [], images: [] }, specs: [], mrp: 0, price: { kuro_on: 0 }, supp_on: { md_on: 0, am_on: 0, vp_on: 0, sh_on: 0 }, status: 'Currently Unavailable', priority: 10, taxrate: 18, hsn_code: "", quantity: 0, delete_flag: true, active: false }]
    const [brands, setbrands] = useState([])
    const [product, setProduct] = useState(initProd)
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/brands', config).then(res => {
            setbrands(res.data)
        })
    }, [])

    const addItem = (index, key, overview = false) => {
        let tempProd = [...product]
        let items = overview ? tempProd[0]["overview"][key] : tempProd[0][key]
        items.splice(index, 0, '')
        if (overview) {
            tempProd[0]["overview"][key] = items
        } else {
            tempProd[0][key] = items
        }
        setProduct(tempProd)
    }

    const removeItem = (index, key, overview = false) => {
        let tempProd = [...product]
        let items = overview ? tempProd[0]["overview"][key] : tempProd[0][key]
        items.splice(index, 1)
        if (overview) {
            tempProd[0]["overview"][key] = items
        } else {
            tempProd[0][key] = items
        }
        setProduct(tempProd)
    }

    const addSpec = (index) => {
        let tempProd = [...product]
        let specs = tempProd[0]["specs"]
        specs.splice(index, 0, ['key', 'value'])
        tempProd[0]["specs"] = specs
        setProduct(tempProd)
    }

    const removeSpec = (index) => {
        let tempProd = [...product]
        let specs = tempProd[0]["specs"]
        specs.splice(index, 1)
        tempProd[0]["specs"] = specs
        setProduct(tempProd)
    }

    const updatespec = (index, e, key) => {
        let tempProd = [...product]
        let specs = tempProd[0]["specs"]
        specs[index][key] = e.target.value
        tempProd[0]["specs"] = specs
        setProduct(tempProd)
    }

    const updateprod = (e, key, num) => {
        let temp = [...product]
        temp[0][key] = num ? Number(e.target.value) : e.target.value
        if (key === "title") {
            temp[0]["prod_url"] = e.target.value.toLowerCase().replaceAll(' ', '-')
        }
        setProduct(temp)
        if (key !== "productid" && key !== "prod_url") {
            updateprodId()
        }
    }

    const updateprodId = () => {
        let temp = [...product]
        let prodid = ""
        if (temp[0]["type"] === "streaming-gear") {
            prodid = temp[0]["category"].substring(0, 3)
        } else if (temp[0]["type"] === "tower") {
            prodid = "CASE"
        } else {
            prodid = temp[0]["type"].substring(0, 3)
        }
        prodid += temp[0]["maker"].substring(0, 3)
        temp[0]["productid"] = prodid.toUpperCase()
        setProduct(temp)
    }

    const updateprodlist = (e, key, index, overview = false) => {
        let tempProd = [...product]
        let items = overview ? tempProd[0]["overview"][key] : tempProd[0][key]
        items[index] = e.target.value
        if (overview) {
            tempProd[0]["overview"][key] = items
        } else {
            tempProd[0][key] = items
        }
        setProduct(tempProd)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        let collData = product[0]
        let collection = product[0].collection

        const body = JSON.stringify({ collData, collection })
        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/createproduct', body, config).then((res) => {
            setresstatus(res.status)
        })
        setsubmitted(true)
    }

    const resetHandler = () => {
        setProduct(initProd)
    }

    const okHandler = () => {
        setresstatus("")
        setsubmitted(false)
    }


    return (
        <div className="add-prod txt-light-grey">
            <h2>Add Product</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">
                    <b>Adding Product:</b> Select the Collection, Type, Category and Brand. Enter the title Product URL, Id and Tax rate. <br />
                </span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            {submitted ? (
                <div>
                    {resstatus === 200 ? (
                        <ResponseBox msg="Product has been Submitted Succesfully." okhandler={okHandler} />
                    ) : resstatus === "" ? null : (
                        <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                    )}
                </div>
            ) : (
                <>
                    {product.map((prod, i) =>
                        <>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Collection</td>
                                        <td>
                                            <select className="large" value={prod.collection} onChange={(e) => updateprod(e, "collection")}>
                                                <option value="">Select Collection</option>
                                                <option value="components">Components</option>
                                                <option value="monitors">Monitors</option>
                                                <option value="accessories">Accessories</option>
                                                <option value="networking">Networking</option>
                                                <option value="external">External Devices</option>
                                                <option value="power_backup">Power and Backup</option>
                                                <option value="cables">Cables</option>
                                                <option value="services">Services</option>
                                                <option value="laptops">Laptops</option>
                                                <option value="peripherals">Peripherals</option>
                                            </select>
                                        </td>
                                        <td>Type</td>
                                        <td>
                                            <select className="large" value={prod.type} onChange={(e) => updateprod(e, "type")}>
                                                <option value="">Select Type</option>
                                                {prod.collection === "components" &&
                                                    <>
                                                        <option value="cpu">Processor</option>
                                                        <option value="mob">Motherboard</option>
                                                        <option value="gpu">Graphics</option>
                                                        <option value="ram">RAM</option>
                                                        <option value="psu">Power Supply</option>
                                                        <option value="ssd">SSD</option>
                                                        <option value="hdd">HDD</option>
                                                        <option value="cooler">CPU Cooler</option>
                                                        <option value="tower">Case</option>
                                                        <option value="fan">Fan</option>
                                                        <option value="custom_liquid">Custom Liquid Peripherals</option>
                                                    </>
                                                }
                                                {prod.collection === "monitors" &&
                                                    <>
                                                        <option value="monitor">Monitor</option>
                                                    </>
                                                }
                                                {prod.collection === "accessories" &&
                                                    <>
                                                        <option value="keyboard">Keyboard</option>
                                                        <option value="keyboard-accessories">Keyboard Accessories</option>
                                                        <option value="mouse">Mouse</option>
                                                        <option value="mouse-accessories">Mouse Accessories</option>
                                                        <option value="kbdmouse">Keyboard Mouse Combo</option>
                                                        <option value="streaming-gear">Streaming Gear</option>
                                                        <option value="thermalpaste">Thermal Paste</option>
                                                        <option value="isoprophile">Isoprophile Alcohol</option>
                                                        <option value="led_strips">LED Strips</option>
                                                        <option value="monitor_mounts">Monitor Mounts</option>
                                                        <option value="stands">Stands</option>
                                                        <option value="io_devices">External Case</option>
                                                        <option value="io_devices">External USB Hub</option>
                                                        <option value="io_devices">Internal USB Hub</option>
                                                        <option value="io_devices">RGB Hub</option>
                                                        <option value="pendrive">Pendrive</option>
                                                    </>
                                                }
                                                {prod.collection === "networking" &&
                                                    <>
                                                        <option value="wifi-card">WiFi Card</option>
                                                        <option value="routers">Routers</option>
                                                        <option value="switches">Switches</option>
                                                    </>
                                                }
                                                {prod.collection === "external" &&
                                                    <>
                                                        <option value="hdd">HDD</option>
                                                        <option value="expansion-card">Expansion Card</option>
                                                    </>
                                                }
                                                {prod.collection === "cables" &&
                                                    <>
                                                        <option value="power">Power Cord</option>
                                                        <option value="hdmi">HDMI Cable</option>
                                                        <option value="vga">VGA Cable</option>
                                                        <option value="displayport">Display Port Cable</option>
                                                        <option value="mdp_to_dp">MDP to DP Cable</option>
                                                        <option value="routers">Routers Cable</option>
                                                        <option value="splitter">Splitter Cable</option>
                                                        <option value="riser">Riser Cable</option>
                                                        <option value="ethernet">Ethernet Cable</option>
                                                        <option value="usb3_0">USB 3.0 Cable</option>
                                                        <option value="usb2_0">USB 2.0 Cable</option>
                                                        <option value="type_c">Type-C Cable</option>
                                                        <option value="aux">Aux Cable</option>
                                                        <option value="extension">Extension Cable</option>
                                                    </>
                                                }
                                                {prod.collection === "power_backup" &&
                                                    <>
                                                        <option value="ups">UPS</option>
                                                        <option value="surge_protector">Surge Protector</option>
                                                        <option value="adaptors">Adaptors</option>
                                                        <option value="power_bank">Power Bank</option>
                                                        <option value="cms_battery">CMS Battery</option>
                                                    </>
                                                }
                                                {prod.collection === "services" &&
                                                    <option value="softwares">Softwares</option>
                                                }
                                                {prod.collection === "laptops" &&
                                                    <>
                                                        <option value="laptop">Laptop</option>
                                                        <option value="cooling_pad">Cooling Pad</option>
                                                    </>
                                                }
                                                {prod.collection === "peripherals" &&
                                                    <>
                                                        <option value="printer">Printer</option>
                                                        <option value="scanner">Scanner</option>
                                                        <option value="barcode_scanner">Barcode Scanner</option>
                                                        <option value="qr_scanner">QR Scanner</option>
                                                        <option value="speaker">Speaker</option>
                                                    </>
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Category</td>
                                        <td>
                                            <select className="large" value={prod.category} onChange={(e) => updateprod(e, "category")}>
                                                <option value="">Select Category</option>
                                                {prod.type === "streaming-gear" &&
                                                    <>
                                                        <option value="capture-card">Capture Card</option>
                                                        <option value="gaming-chair">Gaming Chair</option>
                                                        <option value="streaming-mic">Streaming Mic</option>
                                                        <option value="webcam">Webcam</option>
                                                        <option value="gaming-headset">Gaming Headset</option>
                                                        <option value="desk">Desk</option>
                                                    </>
                                                }
                                            </select>
                                        </td>
                                        <td>Brand</td>
                                        <td>
                                            <select className="large" value={prod.maker} onChange={(e) => updateprod(e, "maker")}>
                                                {brands.map((br, i) =>
                                                    <option key={i} value={br}>{br}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Title</td>
                                        <td colSpan="3"><textarea className="x-large" onChange={(e) => updateprod(e, "title")} value={prod.title} /></td>
                                    </tr>
                                    <tr>
                                        <td>Product Url</td>
                                        <td colSpan="3"><input className="x-large" onChange={(e) => updateprod(e, "prod_url")} value={prod.prod_url} /></td>
                                    </tr>
                                    <tr>
                                        <td>Product Id</td>
                                        <td><input className="x-large" onChange={(e) => updateprod(e, "productid")} value={prod.productid} /></td>
                                        <td>Tax Rate</td>
                                        <td>
                                            <select className="large" value={prod.taxrate} onChange={(e) => updateprod(e, "taxrate", true)}>
                                                <option value="">Select Tax Rate</option>
                                                <option value={5}>5</option>
                                                <option value={12}>12</option>
                                                <option value={18}>18</option>
                                                <option value={28}>28</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Images <span className="prod-span" onClick={() => addItem(0, "images")}>[+]</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {prod.images.map((img, i) =>
                                                <>
                                                    <input className="large" key={i} value={img} onChange={(e) => updateprodlist(e, "images", i)} />
                                                    {prod.images.length > 0 && <span className="prod-span" onClick={() => removeItem(i, "images")}>[-]</span>}
                                                    <span className="prod-span" onClick={() => addItem(i + 1, "images")}>[+]</span>
                                                    <br />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Summary <span className="prod-span" onClick={() => addItem(0, "summary", false)}>[+]</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {prod.summary.map((sum, i) =>
                                                <>
                                                    <input className="large" key={i} value={sum} onChange={(e) => updateprodlist(e, "summary", i)} />
                                                    {prod.summary.length > 0 && <span className="prod-span" onClick={() => removeItem(i, "summary")}>[-]</span>}
                                                    <span className="prod-span" onClick={() => addItem(i + 1, "summary", false)}>[+]</span>
                                                    <br />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Overview</td>
                                    </tr>
                                    <tr>
                                        <td>Description <span className="prod-span" onClick={() => addItem(0, "desc", true)}>[+]</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {prod.overview.desc.map((desc, i) =>
                                                <>
                                                    <input className="large" key={i} value={desc} onChange={(e) => updateprodlist(e, "desc", i, true)} />
                                                    {prod.overview.desc.length > 0 && <span className="prod-span" onClick={() => removeItem(i, "desc", true)}>[-]</span>}
                                                    <span className="prod-span" onClick={() => addItem(i + 1, "desc", true)}>[+]</span>
                                                    <br />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Images <span className="prod-span" onClick={() => addItem(0, "images", true)}>[+]</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {prod.overview.images.map((img, j) =>
                                                <>
                                                    <input className="large" key={j} value={img} onChange={(e) => updateprodlist(e, "images", j, true)} />
                                                    {prod.overview.images.length > 0 && <span className="prod-span" onClick={() => removeItem(j, "images", true)}>[-]</span>}
                                                    <span className="prod-span" onClick={() => addItem(j + 1, "images", true)}>[+]</span>
                                                    <br />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">Specs <span className="prod-span" onClick={() => addSpec(0)}>[+]</span></td>
                                    </tr>
                                    <tr>
                                        <td>Brand</td>
                                        <td>{prod.maker}</td>
                                    </tr>
                                    {prod.specs.map((spec, i) =>
                                        <tr>
                                            <td>
                                                <input className="large" onChange={(e) => updatespec(i, e, 0)} value={spec[0]} />
                                            </td>
                                            <td>
                                                <input className="large" onChange={(e) => updatespec(i, e, 1)} value={spec[1]} />
                                                {prod.specs.length > 0 && <span className="prod-span" onClick={() => removeSpec(i)}>[-]</span>}
                                                <span className="prod-span" onClick={() => addSpec(i + 1)}>[+]</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <ul className='btns'>
                                <li><button id="reset" onClick={resetHandler}>Clear All</button></li>
                                <li><button id="submit" onClick={submitHandler}>Submit</button></li>
                                <li><button id="back" onClick={() => props.backHandler(false)}>Back</button></li>
                            </ul>
                        </>
                    )}
                </>
            )}
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(AddProduct)