import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import ResponseBox from '../components/ResponseBox'
import { getVendors } from '../actions/admin'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import '../styles/table.css'


const CreateOutwardDNote = ({ user: { token, userDetails }, admin: { vendors, accesslevels }, getVendors }) => {

    const [debitnotes, setdebitnotes] = useState([])
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")
    const history = useHistory();
    const access = accesslevels.find(item => item.outward_debitnotes === "edit" || item.outward_debitnotes === "write");
    useEffect(() => {
        if (access === undefined || access.length <= 0 ) {
            history.push("/unauthorized")
        }
        getVendors()
        addrow(true)
    }, [])

    const addrow = (first) => {
        if (first) {
            setdebitnotes([{ 'vendor': '', 'gstin': '', 'debitnote_date': '', 'totalprice': 0, 'cgst': 0, 'sgst': 0, 'igst': 0, 'reason': '' , 'entity': accesslevels.find(item => (item.outward_debitnotes === "edit" || item.outward_debitnotes === "write")).entity}])
        } else {
            setdebitnotes(prevState => [...prevState, { 'vendor': '', 'gstin': '', 'debitnote_date': '', 'totalprice': 0, 'cgst': 0, 'sgst': 0, 'igst': 0, 'reason': '' }])
        }
    }

    const updatedebitnote = (id, key, e) => {
        let tempdebitnotes = [...debitnotes]
        tempdebitnotes[id][key] = e.target.value
        setdebitnotes(tempdebitnotes)
    }

    const updatedebitnotedate = (id, key, date) => {
        let tempdebitnotes = [...debitnotes]
        tempdebitnotes[id][key] = date
        setdebitnotes(tempdebitnotes)
    }

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateprice = (id, key, e) => {
        let tempdebitnotes = [...debitnotes]
        tempdebitnotes[id][key] = Number(e.target.value)
        if (key === "totalprice" || key === "ittcs") {
            if (tempdebitnotes[0].gstin.substr(0, 2) === "36") {
                tempdebitnotes[id]["cgst"] = round("round", ((tempdebitnotes[id]["totalprice"] - tempdebitnotes[id]["ittcs"]) / 1.18) * 0.09, 2)
                tempdebitnotes[id]["sgst"] = round("round", ((tempdebitnotes[id]["totalprice"] - tempdebitnotes[id]["ittcs"]) / 1.18) * 0.09, 2)
                tempdebitnotes[id]["igst"] = 0
            } else {
                tempdebitnotes[id]["cgst"] = 0
                tempdebitnotes[id]["sgst"] = 0
                tempdebitnotes[id]["igst"] = round("round", ((tempdebitnotes[id]["totalprice"] - tempdebitnotes[id]["ittcs"]) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempdebitnotes[id]["sgst"] = Number(e.target.value)
        } else if (key === "igst") {
            tempdebitnotes[id]["cgst"] = 0
            tempdebitnotes[id]["sgst"] = 0
        }
        setdebitnotes(tempdebitnotes)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        if (debitnotes[0]["debitnote_no"] !== "" && debitnotes[0]["vendor"] !== "" && debitnotes[0]["totalprice"] !== "") {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwarddebitnotes', debitnotes, config)
                .then((res) => {
                    setresstatus(res.status)
                })
            setsubmitted(true)
            addrow(true)
        }
    }

    const resetHandler = () => {
        addrow(true)
    }

    const okHandler = () => {
        setresstatus("")
        setsubmitted(false)
    }

    return (
        <div className="kuro_inventory entry txt-light">
            <h2 className="txt-light-grey">Debit Notes</h2>
            {submitted ? (
                <div>
                    {resstatus === 200 ? (
                        <ResponseBox msg="Debit Notes has been Submitted Succesfully." okhandler={okHandler} />
                    ) : resstatus === "" ? null : (
                        <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                    )}
                </div>
            ) : (
                <>
                    {debitnotes.map((debitnote, i) =>
                        <table className="border" cellSpacing="0" cellPadding="0">
                            <tbody>
                            <tr>
                                    <th>Entity</th>
                                    <td>
                                        <select className="type" name="entity" value={debitnote.entity} onChange={(e) => updatedebitnote(i, "entity", e)} >
                                            {accesslevels.map((item, index) => (item.outward_debitnotes === "edit" || item.outward_debitnotes === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{item.entity}</option>) : (null))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>
                                        <select value={debitnote.vendor} onChange={(e) => updatedebitnote(i, "vendor", e)}>
                                            <option value="">Select Vendor</option>
                                            {vendors !== null &&
                                                vendors.map((vendor, j) =>
                                                    <option key={j} value={vendor.vendor_code}>{vendor.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={debitnote.gstin} onChange={(e) => updatedebitnote(i, "gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && debitnote.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === debitnote.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Debit Note Date</th>
                                    <td>
                                        <DatePicker dateFormat='dd-MMM-yyyy' selected={debitnote.debitnote_date} onChange={date => updatedebitnotedate(i, "debitnote_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Debit Note Total</th>
                                    <td>
                                        <input type="number" className="medium" value={debitnote.totalprice} onChange={(e) => updateprice(i, "totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {debitnote.gstin === "" || debitnote.gstin.substr(0, 2) === "36" ? (
                                            <input type="number" className="medium" value={debitnote.cgst} onChange={(e) => updateprice(i, "cgst", e)} />
                                        ) : (
                                            debitnote.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {debitnote.gstin === "" || debitnote.gstin.substr(0, 2) === "36" ? (
                                            debitnote.igst
                                        ) : (
                                            <input type="number" className="small" value={debitnote.igst} onChange={(e) => updateprice(i, "igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Reason</th>
                                    <td><textarea className="small" value={debitnote.reason} onChange={(e) => updatedebitnote(i, "reason", e)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <ul className='btns'>
                        <li>
                            <button onClick={resetHandler}>Clear All</button>
                        </li>
                        <li>
                            <button onClick={submitHandler}>Submit</button>
                        </li>
                        <li>
                            <KuroLink to="/outward-debitnotes"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreateOutwardDNote)