import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import KuroLink from '../components/common/KuroLink'
import '../styles/response.css'
import note from '../assets/img/button.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

moment.tz.setDefault('Asia/Kolkata')

const InwardPayments = ({user: {token, userDetails}, admin: { accesslevels }}) => {

    const [orderData, setorderData] = useState(null)
    const [limit, setlimit] = useState(30)
    const [banks, setBanks] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const history=useHistory();
    const access=accesslevels.find(item => item.inward_payments !== "NA");

    useEffect(()=>{
    if(access.length <= 0){
        history.push("/unauthorized")
    }
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/accounts?type=banks', config).then(res => {
            setBanks(res.data[0]['content'])
        })
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/inwardpayments?limit=' + limit, config).then(res => {
            setorderData(res.data[0])
            if (res.data[0].length < limit) {
                setlimit(0)
            }
        })
    }, [limit])

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 30)
        }
    }

    return (
        <div>
            <h3 className='txt-light'>Inward Payments</h3>
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Pending Payments</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>All Payments</button>
                <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>Sorted Data</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    {orderData !== null &&
                    <table className='txt-light border'>
                        <tbody>
                            <tr>
                                <th>Total Pending Payments</th>
                                <td><b>{orderData.total_due}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    }
                    {orderData !== null ? (
                    <table className="border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>OrderId</th>
                                <th>Name</th>
                                <th>Order Total</th>
                                <th>Amount Paid</th>
                                <th>Amount Due</th>
                                <th>Create Payment</th>
                            </tr>
                            {orderData.pending_payments.map((order, i) =>
                            <tr key={i}>
                                <td><KuroLink to={'inward-payments/' + order.orderid}>{order.orderid}</KuroLink></td>
                                <td>{order.name}</td>
                                <td>{order.total}</td>
                                <td>{order.amount_paid}</td>
                                <td>{order.amount_due}</td>
                                {(accesslevels.find(item => (item.inward_payments === "write" || item.inward_payments === "edit") && item.entity === order.entity)) ? (<td><KuroLink to={'/createPaymentLink/' + order.orderid}>Create Payment Link</KuroLink></td>):(<td>You Don`t Have Access</td>)}
                            </tr>
                            )}
                        </tbody>
                    </table>
                    ) : (
                        <p className='txt-light'>No Payments</p>
                    )}
                    {limit !== 0 && orderData !== null && orderData.pending_payments.length >= 30  &&
                    <div className='btns'>
                        <li>
                            <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                        </li>
                        <li>
                            <button className='btn'onClick={() => limitHandler(true)}>Show All</button>
                        </li>
                    </div>
                    }
                </div>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                    {orderData !== null ? (
                    <table className="border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>OrderId</th>
                                <th>Name</th>
                                <th>Order Total</th>
                                <th>Amount Paid</th>
                                <th>Amount Due</th>
                            </tr>
                            {orderData.payments.map((order, i) =>
                            <tr key={i}>
                                <td><KuroLink to={'inward-payments/' + order.orderid}>{order.orderid}</KuroLink></td>
                                <td>{order.name}</td>
                                <td>{order.total}</td>
                                <td>{order.amount_paid}</td>
                                <td>{order.amount_due}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    ) : (
                        <p className='txt-light'>No Payments</p>
                    )}
                    {limit !== 0 && orderData !== null && orderData.payments.length >= 30 &&
                    <div className='btns'>
                        <li>
                            <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                        </li>
                        <li>
                            <button className='btn'onClick={() => limitHandler(true)}>Show All</button>
                        </li>
                    </div>
                    }
                </div>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                    {banks && orderData !== null ? (
                    <table className="border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>OrderId</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Payment Date</th>
                                <th>Payment Mode</th>
                                <th>Account</th>
                                <th>Payment UTR</th>
                                <th>Remarks</th>
                            </tr>
                            {orderData.sortBydate.map((order, i) =>
                            <tr key={i}>
                                <td><KuroLink to={'inward-payments/' + order.orderid}>{order.orderid}</KuroLink></td>
                                <td>{order.name}</td>
                                <td>{order.amount}</td>
                                <td>{moment(order.payment_date).format('DD-MM-YYYY')}</td>
                                <td>{order.mode}</td>
                                <td>{banks.find(bid => bid.bankid === order.account)?.name || ""}</td>
                                <td>{order.utr}</td>
                                <td>{order.remark}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    ) : (
                        <p className='txt-light'>No Payments</p>
                    )}
                    {limit !== 0 && orderData !== null && orderData.sortBydate.length >= 30 &&
                    <div className='btns'>
                        <li>
                            <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                        </li>
                        <li>
                            <button className='btn'onClick={() => limitHandler(true)}>Show All</button>
                        </li>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(InwardPayments)