import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const OfflineOrderInventory = ({ match, user: {token}, admin: { accesslevels } }) => {

    const { params: { orderId } } = match

    let history = useHistory()

    const [orderData, setorderData] = useState(null)
    const [outwardData, setoutwardData] = useState(null)
    const [submitted, setsubmitted] = useState(false)
    const access = accesslevels.find(item => item.offline_orders !== "NA")

    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderId, config).then(res => {
            setorderData(res.data[0])
        })
    }, [])

    useEffect(() => {
        if (orderData !== null) {
            let tempoutward = {}
            tempoutward.builds = []
            tempoutward.products = []
            if (orderData.builds && orderData.builds.length > 0) {
                for (let build of orderData.builds) {
                    tempoutward.builds.push({"serials": []})
                    for (let comp of build.components) {
                        let sr_no = []
                        for (let i=0; i<build.quantity; i++) {
                            for (let j=0; j<comp.quantity; j++) {
                                sr_no.push("")
                            }
                        }
                        tempoutward.builds[tempoutward.builds.length - 1]["serials"].push({
                            "productid": comp.productid,
                            "sr_no": sr_no
                        })
                    }
                }
            }
            if (orderData.products && orderData.products.length > 0) {
                for (let prod of orderData.products) {
                    let sr_no = []
                    for (let i=0; i<prod.quantity; i++) {
                        sr_no.push("")
                    }
                    tempoutward.products.push({ "productid": prod.productid, "title": prod.title, "sr_no": sr_no })
                }
            }
            setoutwardData(tempoutward)
        }
    }, [orderData])

    const updateoutward = (type, e, index, ind, i) => {
        let tempoutward = {...outwardData}
        if (type === "builds") {
            tempoutward.builds[index].serials[ind].sr_no[i] = e.target.value.toUpperCase()
        } else {
            tempoutward.products[index].sr_no[ind] = e.target.value.toUpperCase()
        }
        setoutwardData(tempoutward)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let ordertype = "kgorders"
        let data = outwardData
        data["orderid"] = orderData.orderid
        data["ordertype"] = ordertype

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/outward?ordertype=' + ordertype, data, config)
        .then((res) => {
            setorderData(res.data)
        })
        setsubmitted(true)
    }

    const backHandler = () => {
        history.push("/offlineorders")
    }

    return (
        orderData !== null &&
        <div className="add-products txt-light-grey">
            <ul className="btns">
                <li><button onClick={backHandler}>Offline Orders</button></li>
            </ul>
            {submitted ? (
                <p>Product Serial Nos have been successfully attached to Order {orderData.orderid}</p>
            ) : (
                orderData.order_status === "Created" ? (
                    <p>Order is not yet Authorized</p>
                ) : (
                    orderData.order_status === "Authorized" ? (
                    <div className='inventory'>
                        <table cellPadding="0" cellSpacing="0" className="border">
                            <tbody>
                                <tr>
                                    <th>Order Id</th>
                                    <th>Order Date</th>
                                    <th>Customer Name</th>
                                    <th>Order Total</th>
                                    <th>Order Status</th>
                                    <th>Dispatch Due Date</th>
                                </tr>
                                <tr>
                                    <td>{orderData.orderid.toUpperCase()}</td>
                                    <td>{orderData.order_date === "" ? "" : moment(orderData.order_date).format('DD-MM-YYYY')}</td>
                                    <td>{orderData.user.name}</td>
                                    <td>{orderData.totalprice}</td>
                                    <td>{orderData.order_status}</td>
                                    <td>{orderData.dispatchby_date === "" ? "" : moment(orderData.dispatchby_date).format('DD-MM-YYYY')}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        {outwardData !== null &&
                        <div className='serials'>
                            {orderData.builds && outwardData.builds &&
                            <div>
                                <h3>Build Serial Numbers</h3>
                                {outwardData.builds.map((build, i) =>
                                <table key={i} className="build border" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Product Title</td>
                                            <td>Serial Number</td>
                                        </tr>
                                        {build.serials.map((comp, j) =>
                                        <tr key={j}>
                                            <td>
                                                {orderData.builds[i].components[j].title}
                                            </td>
                                            <td>
                                                {comp.sr_no.map((e, k) => {
                                                    return <><input value={e} className="large" onChange={(e) => updateoutward("builds", e, i, j, k)} /><br/></>
                                                })}
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                                )}
                            </div>
                            }
                            {orderData.products && outwardData.products && 
                            <div>
                                <h3>Product Serial Numbers</h3>
                                <table className="prod border" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Product Title</td>
                                            <td>Serial Number</td>
                                        </tr>
                                        {outwardData.products.map((prod, i) =>
                                        <tr key={i}>
                                            <td>
                                                {orderData.products[i].title}
                                            </td>
                                            <td>
                                                {prod.sr_no.map((e, j) => {
                                                    return <><input key={j} value={e} className="large" onChange={(e) => updateoutward("products", e, i, j)} /><br/></>
                                                })}
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                        }
                        <p>&nbsp;</p>
                        <ul className='btns'>
                            <li>
                                <button onClick={backHandler}>Back</button>
                            </li>
                            <li>
                                <button onClick={submitHandler}>Submit</button>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                    </div>
                    ) : (
                        <p>Product Serial Nos have already been added</p>
                    )
                )
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(OfflineOrderInventory)