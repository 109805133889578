import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Switchgroup from '../../pages/Switchgroup'

const AuthenticatedRoute = ({ component: Component, user, admin: { accesslevels }, ...rest }) => {
    const [bgCode, setBgCode] = useState(() => localStorage.getItem('bg-code'))
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setBgCode(localStorage.getItem('bg-code'));

    }, [localStorage.getItem('bg-code')]);

    useEffect(() => {
        if (accesslevels.length > 0 && bgCode) {
            setIsLoading(false);
        }
    }, [accesslevels, bgCode]);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (user.isLoading) {
                    return <h2>Loading...</h2>
                }
                if (user.isAuthenticated) {
                    if (!isLoading) {
                        return <Component {...props} />
                    }
                    return <Switchgroup {...props} />
                }

                return <Redirect to="/login" />
            }}
        />
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin,
})

export default connect(mapStateToProps)(AuthenticatedRoute);
