import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import ImgArrow from '../assets/img/arrow.png'
import axios from "axios";
import "../styles/service.css";
import admin from "../reducers/admin";



const ServiceRequest = ({ match, user: { token, userDetails }, admin: { accesslevels, bgDetails } }) => {
    const [serviceRequest, setServiceRequest] = useState(null);
    const [initialData, setInitialData] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [edit, setEdit] = useState(true);
    const [expandedRow, setExpandedRow] = useState([]);
    const history = useHistory()
    const { params: { srid  } } = match
    const fetchData = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }
        axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/servicerequest?limit=1&srid=' + srid , config)
            .then((res) => {
                setServiceRequest(res.data)
                setInitialData(JSON.parse(JSON.stringify(res.data)))
            })
            .catch((err) => {
                if (err) {
                    console.log(err)
                }
            })
    }
    const fetchEmployees = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_KC_API_URL}employeesdata`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                }
            );
            setEmployees(data);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
    }, [expandedRow])

    useEffect(() => {
        fetchData()
        fetchEmployees()
    }, [])

    useEffect(() => {
        const currentDataString = JSON.stringify(serviceRequest);
        const initialDataString = JSON.stringify(initialData);
        if (currentDataString !== initialDataString) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [serviceRequest, initialData]);

    const editRequest = (field, index, logIndex, value) => {
        let updatedData = [...serviceRequest];
        if (logIndex !== undefined) {
            updatedData[index].logs.slice().reverse()[logIndex][field] = value;
        } else {
            updatedData[index][field] = value;
        }
        setServiceRequest(updatedData);
    };
    const addNewLog = () => {
        const newLog = {
            remarks: '',
            status: '',
            updated_by: userDetails.userid,
            updated_date: new Date().toISOString(),
            assigned_to: serviceRequest[0].assigned_to
        };

        const updatedData = [...serviceRequest];
        updatedData[0].logs.push(newLog);
        setServiceRequest(updatedData);
        if (!expandedRow.includes(0)) {
            setExpandedRow([...expandedRow, 0]);
        }
    };

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/servicerequest?srid=' + srid, serviceRequest[0], config)
            .then((res) => {
                setServiceRequest(res.data)
                setInitialData(JSON.parse(JSON.stringify(res.data)))
            })
    }

    const removeLog = (index) => {
        const updatedData = [...serviceRequest];
        updatedData[0].logs.splice(index, 1);
        setServiceRequest(updatedData);
    };
    const checkInputAccesss = (logIndex) => {
        if (accesslevels.find(item => (item.service_request === "edit"))) {
            return edit
        } else {
            if (hasChanges) {
                return logIndex === 0 ? false : true
            } else {
                return true
            }
        }
    }

    return (
        <div className="kuro-service entry">
            <h1>Service Request</h1>
            <ul className="no_bullets">
                <li><button onClick={() => history.goBack()}>Back</button></li>
                {accesslevels.find(item => item.service_request === "edit") && <li><button onClick={() => setEdit(!edit)}>Edit</button></li>}
            </ul>
            {serviceRequest != null && serviceRequest.length > 0 && <table className="border even_odd table_mob service_request" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>SRID</th>
                        <th>Name</th>
                        <th>Entity</th>
                        <th>Phone</th>
                        <th>Service Type</th>
                        <th>Status</th>
                        <th>Assigned To</th>
                    </tr>
                </thead>
                <tbody>
                    {initialData != null && employees != null && serviceRequest != null && serviceRequest.length > 0 && serviceRequest.map((sr, index) => (
                        <tr key={sr.srid}>
                            <td><input type="text" readOnly={true} value={sr.srid} onChange={(e) => editRequest('srid', index, undefined, e.target.value)} /></td>
                            <td>{edit ? <input type="text" value={sr.name} onChange={(e) => editRequest('name', index, undefined, e.target.value)} /> : sr.name}</td>
                            <td>
                                {edit ?  <select className="type" value={sr.entity} onChange={(e) => editRequest('entity', index, undefined, e.target.value)} >
                                        {accesslevels.map((item, index) => (item.service_request === "edit" || item.service_request === "write") ? (<><option key={index} value={item.entity}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option></>):(null))
                                        }
                                    </select> : bgDetails && bgDetails.entities.map( ent => ent.name === sr.entity ? ent.title : null)}
                            </td>
                            <td>{edit ? <input type="number" value={sr.phone} onChange={(e) => editRequest('phone', index, undefined, e.target.value)} /> : sr.phone}</td>
                            <td>
                                {edit ? (
                                    <select
                                        value={sr.servicetype}
                                        onChange={(e) => editRequest('type', index, undefined, e.target.value)}
                                        disabled={edit}
                                    >
                                        <option value="Estimates">Estimates</option>
                                        <option value="IN-Kuro Warranty Service">IN-Kuro Warranty Service</option>
                                        <option value="Third Party Warranty Service">Third Party Warranty Service</option>
                                        <option value="Out of Warranty Service">Out of Warranty Service</option>
                                        <option value="Other">Other</option>
                                    </select>
                                ) : (
                                    sr.servicetype
                                )}
                            </td>
                            <td>
                                {edit ? (
                                    <select
                                        value={sr.status}
                                        onChange={(e) => editRequest('status', index, undefined, e.target.value)}
                                        disabled={true}
                                    >
                                        <option value="created">Created</option>
                                        <option value="processing">Processing</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                ) : (sr.status === 'created' ? 'Created' : sr.status === 'processing' ? 'Processing' : 'Completed')}
                            </td>
                            <td>
                                {employees.filter(emp => emp.userid === sr.assigned_to)[0] ? employees.filter(emp => emp.userid === sr.assigned_to)[0].userid__name : '-'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {hasChanges && <button style={{ width: '100px', marginBottom: '10px' }} onClick={submitHandler}>Submit</button>}
            <table className="border table_mob service_request" style={{ background: '#576463' }} cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Assigned To</th>
                        <th>Last Updated By</th>
                        <th>Last Updated Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {serviceRequest != null && serviceRequest.length > 0 && initialData != null && employees != null && serviceRequest[0].logs.slice().reverse().map((log, logIndex) => (
                        <>
                            <tr key={logIndex}>
                                <td>
                                    <select value={log.status} onChange={(e) => editRequest('status', 0, logIndex, e.target.value)} disabled={checkInputAccesss(logIndex)}>
                                        <option value="created">Created</option>
                                        <option value="pending from customer">Pending from Customer</option>
                                        <option value="work in progress">Work in Progress</option>
                                        <option value="resolved">Resolved</option>
                                    </select>
                                </td>
                                <td><select
                                    value={
                                        employees.filter(employee => employee.userid === log.assigned_to)[0]
                                            ? employees.filter(employee => employee.userid === log.assigned_to)[0].userid
                                            : '-'
                                    }
                                    onChange={(e) => editRequest('assigned_to', 0, logIndex, e.target.value)}
                                    disabled={accesslevels.find(item => item.service_request === "edit") && hasChanges && logIndex === 0 ? false : true}
                                >
                                    <option value="-"></option>
                                    {employees.map((employee) => (
                                        <option key={employee.userid} value={employee.userid}>
                                            {employee.userid__name}
                                        </option>
                                    ))}
                                </select>
                                </td>
                                <td><input type="text" readOnly={true} value={employees.filter(employee => employee.userid === log.updated_by)[0] ? employees.filter(employee => employee.userid === log.updated_by)[0].userid__name : '-'} onChange={(e) => editRequest('updated_by', 0, logIndex, e.target.value)} /></td>
                                <td>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        value={`${new Date(log.updated_date).getDate()}-${new Date(log.updated_date).getMonth() + 1}-${new Date(log.updated_date).getFullYear()}, ${new Date(log.updated_date).getHours()}:${new Date(log.updated_date).getMinutes()}:${new Date(log.updated_date).getSeconds()}`}
                                        onChange={(e) => editRequest('updated_date', 0, logIndex, e.target.value)}
                                    />
                                </td>
                                <td>
                                    {logIndex === 0 && <svg onClick={() => addNewLog()} fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" />
                                    </svg>}
                                    {accesslevels.find(item => item.service_request === "edit") &&
                                        <svg onClick={() => removeLog(logIndex)} fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                        </svg>
                                    }
                                </td>
                                <td>
                                    <img style={{ cursor: 'pointer', width: '25px', height: '25px', filter: 'invert()', marginLeft: '5px' }} onClick={() => { expandedRow.includes(logIndex) ? setExpandedRow(expandedRow.filter((expandedIndex) => expandedIndex !== logIndex)) : setExpandedRow([...expandedRow, logIndex]) }} className={expandedRow.includes(logIndex) ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} />
                                </td>
                            </tr>
                            {expandedRow.includes(logIndex) && <tr>
                                <td colSpan="8" style={{ padding: '0', background: '#4d4c4c' }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ background: '#4d4c4c', color: '#fff', width: '150px', }}>Customer Remark</th>
                                                <td colSpan="4" style={{ background: '#4d4c4c', color: '#fff', borderRight: 'none' }}>{!checkInputAccesss(logIndex) ? <input type="text" value={log.customer_query} onChange={(e) => editRequest('customer_query', 0, logIndex, e.target.value)} /> : log.customer_query} </td>
                                            </tr>
                                            <tr>
                                                <th style={{ background: '#4d4c4c', color: '#fff', borderBottom: 'none' }}>Admin Action</th>
                                                <td style={{ background: '#4d4c4c', color: '#fff', borderBottom: 'none', borderRight: 'none' }}>{!checkInputAccesss(logIndex) ? <input type="text" value={log.admin_action} onChange={(e) => editRequest('admin_action', 0, logIndex, e.target.value)} /> : log.admin_action} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>}
                        </>
                    ))}

                </tbody>
            </table>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})
export default connect(mapStateToProps)(ServiceRequest)