import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getVendors } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/tabs.css'
import Button from '../assets/img/button.png'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { CurrencyFormat } from '../components/common/CurrencyFormat'

moment.tz.setDefault('Asia/Kolkata')

const InwardCreditNotes = ({ user: { token, userDetails }, admin: { vendors, accesslevels }, getVendors }) => {
    const history = useHistory()
    const [tabIndex, setTabIndex] = useState(0)
    const [cnData, setcnData] = useState([])
    const access = accesslevels.find(item => item.inward_creditnotes !== "NA");
    const editaccess = accesslevels.find(item => item.inward_creditnotes === "edit");
    const getInwardCreditNotes = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?foradmin=true', config).then(res => {
            setcnData(res.data)
        })
    }

    useEffect(() => {
        if (!access) {
            history.push("/unauthorized")
            return;
        }
        getInwardCreditNotes()
        getVendors()
    }, [])

    const approveHandler = (creditnoteid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "active": true }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?creditnoteid=' + creditnoteid, data, config)
            .then((res) => {
                getInwardCreditNotes()
                setcnData(res.data)
            })
    }

    const submitPaidHandler = (creditnoteid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "pay_status": "Paid" }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?creditnoteid=' + creditnoteid, data, config)
            .then((res) => {
                getInwardCreditNotes()
                setcnData(res.data)
            })
    }

    const submitSettled = (creditnoteid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "settled": "Yes" }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?creditnoteid=' + creditnoteid, data, config)
            .then((res) => {
                getInwardCreditNotes()
                setcnData(res.data)
            })
    }


    return (
        <div className="txt-m txt-light-grey">
            <h2 className="txt-light-grey">Purchase Credit Notes</h2>
            <div className="note"><p>Instructions </p>
                <img src={Button} className='note_img' />:
                <span className="note_text">
                    <b>Payment Status:</b> Click "Mark Paid" if the payment has been completed.
                    <b>Settled:</b> Click "Mark Settled" if the credit note has been fully adjusted.
                </span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            {(accesslevels.find(item => item.inward_creditnotes === "edit" || item.inward_creditnotes === "write")) &&
                <ul className='btns'>
                    <li><KuroLink to="/create-inward-creditnote"><button>Add CreditNotes</button></KuroLink></li>
                </ul>
            }
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Pending Authorization</button>
                {userDetails.access === "Super" && <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Pending Payment</button>}
                {userDetails.access === "Super" && <button onClick={() => setTabIndex(2)} className={"tab txt-grey label " + (tabIndex === 2 ? " active" : "")} > Settlement Pending </button>}
                <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Past Credit Notes</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    {cnData !== null && cnData.filter(creditnote => creditnote.active === false).length > 0 ? (
                        <table className="home border even_odd table_mob" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    {userDetails.access === 'Super' ? <th className='show_tab'>Credit Note Id</th> : null}
                                    <th>Vendor</th>
                                    <th>Credit Note No.</th>
                                    <th className='show_tab'>Credit Note Date</th>
                                    <th>Credit Note Total</th>
                                    <th className='show_tab'>CGST/SGST</th>
                                    <th className='show_tab'>IGST</th>
                                    <th>Payment Status</th>
                                    <th className='show_tab'>Settled</th>
                                    {userDetails.access === "Super" && <th></th>}
                                </tr>
                                {cnData !== null && cnData.filter(creditnote => creditnote.active === false).map((creditnote, i) =>
                                    <tr key={i}>
                                        {userDetails.access === 'Super' ? <td className='show_tab'>{creditnote.creditnoteid}</td> : null}
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === creditnote.vendor)[0].name + (creditnote.gstin === '' ? '' : ' [' + creditnote.gstin + ']')}</td>
                                        <td className='show_tab'><KuroLink to={'inward-creditnotes/' + creditnote.creditnoteid}>{creditnote.creditnote_no}</KuroLink></td>
                                        <td className='show_tab'>{creditnote.creditnote_date === "" ? "" : moment(creditnote.creditnote_date).format('DD-MM-YYYY')}</td>
                                        <td>{CurrencyFormat(creditnote.totalprice)}</td>
                                        <td className='show_tab'>{creditnote.cgst}</td>
                                        <td className='show_tab'>{creditnote.igst}</td>
                                        <td>{(editaccess && creditnote.pay_status === "Payment Pending") ? <button className="button_mob" onClick={() => submitPaidHandler(creditnote.creditnoteid)}> Mark Paid</button> : <span>{creditnote.pay_status}</span>}</td>
                                        <td className='show_tab'>{(editaccess && creditnote.settled === "No") ? <button className="button_mob" onClick={() => submitSettled(creditnote.creditnoteid)}>Mark Settled</button> : <span> {creditnote.settled}</span>}</td>
                                        {userDetails.access === "Super" &&
                                            <td>
                                                <button className="button_mob" onClick={() => approveHandler(creditnote.creditnoteid)}>Approve</button>
                                            </td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Purchase CreditNotes to Approve</p>
                    )}
                </div>
                {userDetails.access === "Super" &&
                    <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                        {cnData !== null && cnData.filter(creditnote => creditnote.active === true && creditnote.pay_status === "Payment Pending").length > 0 ? (
                            <table className="border even_odd" cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr>
                                        <th className='show_tab'>Credit Note Id</th>
                                        <th>Vendor</th>
                                        <th className='show_tab'>Credit Note No.</th>
                                        <th className='show_tab'>Credit Note Date</th>
                                        <th>Credit Note Total</th>
                                        <th className='show_tab'>CGST/SGST</th>
                                        <th className='show_tab'>IGST</th>
                                        <th>Payment Status</th>
                                        <th className='show_tab'>Settled</th>
                                        <th></th>
                                    </tr>
                                    {cnData !== null && cnData.filter(creditnote => creditnote.active === true && creditnote.pay_status === "Payment Pending").map((creditnote, i) =>
                                        <tr key={i}>
                                            <td className='show_tab'>{creditnote.creditnoteid}</td>
                                            <td>{vendors !== null && vendors.filter(item => item.vendor_code === creditnote.vendor)[0].name + (creditnote.gstin === '' ? '' : ' [' + creditnote.gstin + ']')}</td>
                                            <td className='show_tab'><KuroLink to={'inward-creditnotes/' + creditnote.creditnoteid}>{creditnote.creditnote_no}</KuroLink></td>
                                            <td className='show_tab'>{creditnote.creditnote_date === "" ? "" : moment(creditnote.creditnote_date).format('DD-MM-YYYY')}</td>
                                            <td>{CurrencyFormat(creditnote.totalprice)}</td>
                                            <td className='show_tab'>{creditnote.cgst}</td>
                                            <td className='show_tab'>{creditnote.igst}</td>
                                            <td>{creditnote.pay_status}</td>
                                            <td className='show_tab'>{creditnote.settled}</td>
                                            <td><button className="button_mob" onClick={() => submitPaidHandler(creditnote.creditnoteid)}>Paid</button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <p>No Pending Payments</p>
                        )}
                    </div>}
                {userDetails.access === "Super" && <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                    {cnData !== null && cnData.filter(creditnote => creditnote.active === true && creditnote.settled === "No").length > 0 ? (
                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th className='show_tab'>Credit Note Id</th>
                                    <th>Vendor</th>
                                    <th className='show_tab'>Credit Note No.</th>
                                    <th className='show_tab'>Credit Note Date</th>
                                    <th>Credit Note Total</th>
                                    <th className='show_tab'>CGST/SGST</th>
                                    <th className='show_tab'>IGST</th>
                                    <th>Payment Status</th>
                                    <th className='show_tab'>Settled</th>
                                    <th></th>
                                </tr>
                                {cnData !== null && cnData.filter(creditnote => creditnote.active === true && creditnote.settled === "No").map((creditnote, i) =>
                                    <tr key={i}>
                                        <td className='show_tab'>{creditnote.creditnoteid}</td>
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === creditnote.vendor)[0].name + (creditnote.gstin === '' ? '' : ' [' + creditnote.gstin + ']')}</td>
                                        <td className='show_tab'><KuroLink to={'inward-creditnotes/' + creditnote.creditnoteid}>{creditnote.creditnote_no}</KuroLink></td>
                                        <td className='show_tab'>{creditnote.creditnote_date === "" ? "" : moment(creditnote.creditnote_date).format('DD-MM-YYYY')}</td>
                                        <td>{CurrencyFormat(creditnote.totalprice)}</td>
                                        <td className='show_tab'>{creditnote.cgst}</td>
                                        <td className='show_tab'>{creditnote.igst}</td>
                                        <td>{creditnote.pay_status}</td>
                                        <td className='show_tab'>{creditnote.settled}</td>
                                        <td><button className="button_mob" onClick={() => submitSettled(creditnote.creditnoteid)}>Settled</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Settled Pendings</p>
                    )}
                </div>}

                <div className={"tab-panel" + (tabIndex === 3 ? " active" : "")}>
                    {cnData !== null && cnData.length > 0 ? (
                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    {userDetails.access === 'Super' ? <th className='show_tab'>Credit Note Id</th> : null}
                                    <th>Vendor</th>
                                    <th className='show_tab'>Credit Note No.</th>
                                    <th className='show_tab'>Credit Note Date</th>
                                    <th>Credit Note Total</th>
                                    <th className='show_tab'>CGST/SGST</th>
                                    <th className='show_tab'>IGST</th>
                                    <th className='show_tab'>Payment Status</th>
                                    <th>Settled</th>
                                </tr>
                                {cnData !== null && cnData.map((creditnote, i) =>
                                    <tr key={i}>
                                        {userDetails.access === 'Super' ? <td className='show_tab'>{creditnote.creditnoteid}</td> : null}
                                        <td>{vendors !== null && vendors.filter(item => item.vendor_code === creditnote.vendor)[0].name + (creditnote.gstin === '' ? '' : ' [' + creditnote.gstin + ']')}</td>
                                        <td className='show_tab'><KuroLink to={'inward-creditnotes/' + creditnote.creditnoteid}>{creditnote.creditnote_no}</KuroLink></td>
                                        <td className='show_tab'>{creditnote.creditnote_date === "" ? "" : moment(creditnote.creditnote_date).format('DD-MM-YYYY')}</td>
                                        <td>{CurrencyFormat(creditnote.totalprice)}</td>
                                        <td className='show_tab'>{creditnote.cgst}</td>
                                        <td className='show_tab'>{creditnote.igst}</td>
                                        <td className='show_tab'>{creditnote.pay_status}</td>
                                        <td>{creditnote.settled}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Purchase CreditNotes to Show</p>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(InwardCreditNotes)