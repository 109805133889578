import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { mapData } from '../data/mappings'
import "react-datepicker/dist/react-datepicker.css"
import '../styles/tabs.css'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const OfflineOrder = ({ match, user: { token }, admin: { provinces, accesslevels }, products: { prodData } }) => {

    const { params: { orderId } } = match
    const [orderdata, setorderdata] = useState(null)
    const [updatedorder, setupdatedorder] = useState(null)
    const [editflag, seteditflag] = useState(false)
    const [submitted, setsubmitted] = useState(false)
    const history = useHistory();
    const access = accesslevels.find(item => item.offline_orders !== "NA")
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })

    const defbuild = {
        "title": "",
        "components": [
            {
                "collection": "components",
                "type": "cpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "mob",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "gpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ram",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ssd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "hdd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "psu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "tower",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "cooler",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "fan",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "networking",
                "type": "wifi-card",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            }
        ],
        "hsncode": "",
        "tax_rate": 18,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "price": 0,
        "pricebgst": 0,
        "quantity": 1,
        "totalprice": 0,
        "totalpricebgst": 0
    }

    const defser = {
        "serials": [
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            },
            {
                "productid": "",
                "sr_no": [""]
            }
        ]
    }

    useEffect(() => {
        if (access.length <= 0) {
            history.push("/unauthorized")
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderId, config).then(res => {
            setorderdata(res.data[0])
        })
    }, [])

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (temporder) => {
        let roundoff = 0
        if (temporder.billadd.state === "Telangana") {
            roundoff = ((temporder.totalprice * 100) - (200 * temporder.cgst) - (100 * temporder.totalpricebgst))
        } else {
            roundoff = ((temporder.totalprice * 100) - (100 * temporder.gst) - (100 * temporder.totalpricebgst))
        }
        temporder.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return temporder
    }

    const updatetotal = (temporder) => {
        temporder.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i = 0; i < temporder.builds.length; i++) {
            totaldiscount += temporder.builds[i].totaldiscount
            subtotals[temporder.builds[i].tax_rate] += temporder.builds[i].totalprice
            totalprice += temporder.builds[i].totalprice
        }
        for (let i = 0; i < temporder.products.length; i++) {
            totaldiscount += temporder.products[i].totaldiscount
            subtotals[temporder.products[i].tax_rate] += temporder.products[i].totalprice
            totalprice += temporder.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (temporder.billadd.state === "Telangana") {
                temporder.taxes[key] = trcgst
            } else {
                temporder.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        temporder.totaldiscount = totaldiscount
        temporder.totalprice = totalprice
        temporder.totalpricebgst = round("round", totalpricebgst, 2)
        temporder.gst = round("round", gst, 2)
        temporder.cgst = round("round", cgst, 2)

        temporder = updateroundoff(temporder)
        return temporder
    }

    const editorder = () => {
        setupdatedorder(orderdata)
        seteditflag(true)
    }

    const updateorder = (key, e) => {
        let temporder = { ...updatedorder }
        temporder[key] = e.target.value
        setupdatedorder(temporder)
    }

    const updateorderdate = (key, date) => {
        let temporder = { ...updatedorder }
        temporder[key] = (date === null) ? "" : date
        setupdatedorder(temporder)
    }

    const updateuser = (key, e) => {
        let temporder = { ...updatedorder }
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        temporder.user[key] = value
        temporder.billadd[key] = value
        setupdatedorder(temporder)
    }

    const updatepangstin = (order) => {
        let tempmsg = { ...errmsg }
        if (order.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(order.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (order.billadd.gstin !== "" && (order.billadd.gstin.length !== 15 || order.billadd.gstin.slice(2, 12) !== order.billadd.pan || !(/^[0-9]*$/).test(order.billadd.gstin.slice(0, 2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let temporder = { ...updatedorder }
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({ ...msg, state: "Please select the State" }))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            temporder[key][subkey] = value
            temporder = updatetotal(temporder)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                temporder[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                temporder[key][subkey] = value.toUpperCase()
            }
            updatepangstin(temporder)
        } else {
            temporder[key][subkey] = value
        }
        setupdatedorder(temporder)
    }

    const updateaddressflag = (value) => {
        let temporder = { ...updatedorder }
        temporder["addressflag"] = value
        if (value) {
            delete temporder["shpadd"]
        } else {
            temporder["shpadd"] = { 'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': '' }
        }
        setupdatedorder(temporder)
    }

    const updatenotes = (key, e) => {
        let temporder = { ...updatedorder }
        temporder.notes[key] = e.target.value
        setupdatedorder(temporder)
    }

    const addbuild = (bindex) => {
        let temporder = { ...updatedorder }
        if (temporder.builds.length === 0 && temporder.outward) {
            temporder.outward.builds = []
        }
        temporder.builds.splice(bindex + 1, 0, defbuild)
        if (temporder.outward) {
            temporder.outward.builds.splice(bindex + 1, 0, defser)
        }
        setupdatedorder(temporder)
    }

    const removebuild = (bindex) => {
        let temporder = { ...updatedorder }
        temporder.builds.splice(bindex, 1)
        if (temporder.outward) {
            if (temporder.builds.length === 0) {
                delete temporder.outward.builds
            } else {
                temporder.outward.builds.splice(bindex, 1)
            }
        }
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const updatebuild = (bindex, key, e, num = false) => {
        let temporder = { ...updatedorder }
        temporder.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            temporder.builds[bindex].discount = 0
            temporder.builds[bindex].totaldiscount = 0
            let totalprice = parseFloat(temporder.builds[bindex].price) * temporder.builds[bindex].quantity
            temporder.builds[bindex].pricebgst = round("round", parseFloat(temporder.builds[bindex].price) / 1.18, 2)
            temporder.builds[bindex].totalprice = totalprice
            temporder.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            temporder = updatetotal(temporder)
        }
        setupdatedorder(temporder)
    }

    const addcomp = (bindex, cindex) => {
        let temporder = { ...updatedorder }
        temporder.builds[bindex].components.splice(cindex + 1, 0, { "collection": "components", "type": "", "maker": "", "productid": "", "quantity": 1 })
        if (temporder.outward) {
            temporder.outward.builds[bindex].serials.splice(cindex + 1, 0, { "productid": "", "sr_no": [""] })
        }
        setupdatedorder(temporder)
    }

    const removecomp = (bindex, cindex) => {
        let temporder = { ...updatedorder }
        if (temporder.builds[bindex].components.length === 0) {
            temporder.builds.splice(bindex, 1)
            if (temporder.outward) {
                temporder.outward.builds.splice(bindex, 1)
            }
        } else {
            temporder.builds[bindex].components.splice(cindex, 1)
            if (temporder.outward) {
                temporder.outward.builds[bindex].serials.splice(cindex, 1)
            }
        }
        setupdatedorder(temporder)
    }

    const updatecomp = (bindex, cindex, key, e, num = false) => {
        let temporder = { ...updatedorder }
        temporder.builds[bindex].components[cindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "quantity") {
            let sr_list = []
            for (let i = 0; i < Number(e.target.value); i++) {
                sr_list.push("")
            }
            if (temporder.outward) {
                temporder.outward.builds[bindex].serials[cindex].sr_no = sr_list
            }
        }
        setupdatedorder(temporder)
    }

    const updatecompmodel = (bindex, cindex, e) => {
        let temporder = { ...updatedorder }
        temporder.builds[bindex].components[cindex].productid = e.value
        setupdatedorder(temporder)
    }

    const addprod = (pindex) => {
        let temporder = { ...updatedorder }
        if (temporder.products.length === 0 && temporder.outward) {
            temporder.outward.products = []
        }
        temporder.products.splice(pindex + 1, 0, { 'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18 })
        if (temporder.outward) {
            temporder.outward.products.splice(pindex + 1, 0, { "productid": "", "sr_no": [""] })
        }
        setupdatedorder(temporder)
    }

    const removeprod = (pindex) => {
        let temporder = { ...updatedorder }
        temporder.products.splice(pindex, 1)
        if (temporder.outward) {
            if (temporder.products.length === 0) {
                delete temporder.outward.products
            } else {
                temporder.outward.products.splice(pindex, 1)
            }
        }
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const prodtotal = (temporder, pindex) => {
        if (temporder.products.length > 0) {
            const quantity = temporder.products[pindex].quantity
            const price = temporder.products[pindex].prodprice - temporder.products[pindex].discount
            const totalprice = price * quantity
            temporder.products[pindex].pricebgst = round("round", price / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].price = price
            temporder.products[pindex].totalpricebgst = round("round", totalprice / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].totalprice = totalprice
            temporder = updatetotal(temporder)
        }
        return temporder
    }

    const getoptionlabel = (pindex, key, value) => {
        let prod = updatedorder.products[pindex]
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (pindex, key, e, num = false) => {
        let temporder = { ...updatedorder }
        temporder.products[pindex].discount = 0
        temporder.products[pindex].totaldiscount = 0
        temporder.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" && key === "type" && key === "category" && key === "maker") {
            temporder.products[pindex].productid = ""
            temporder.products[pindex].title = ""
            temporder.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            temporder = prodtotal(temporder, pindex)
        }
        setupdatedorder(temporder)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let temporder = { ...updatedorder }
        temporder.products[pindex].productid = prodid
        temporder.products[pindex].discount = 0
        temporder.products[pindex].totaldiscount = 0

        if (e) {
            temporder.products[pindex].title = (prodid === "other") ? "" : prodData[collection].filter(item => item.productid === prodid)[0].title
            temporder.products[pindex].prodprice = (prodid === "other") ? 0 : prodData[collection].filter(item => item.productid === prodid)[0].price.kuro_on
        } else {
            temporder.products[pindex].title = ''
            temporder.products[pindex].productid = ''
            temporder.products[pindex].prodprice = 0
        }
        temporder = prodtotal(temporder, pindex)
        setupdatedorder(temporder)
    }

    const updateoutward = (type, e, index, ind, i) => {
        let temporder = { ...updatedorder }
        if (type === "builds") {
            temporder.outward.builds[index].serials[ind].sr_no[i] = e.target.value.toUpperCase()
        } else {
            temporder.outward.products[index].sr_no[ind] = e.target.value.toUpperCase()
        }
        setupdatedorder(temporder)
    }

    const updateproddiscount = (pindex, e) => {
        let temporder = { ...orderdata }
        temporder.products[pindex].discount = Number(e.target.value)
        temporder.products[pindex].totaldiscount = temporder.products[pindex].discount * temporder.products[pindex].quantity
        temporder = prodtotal(temporder, pindex)
        setorderdata(temporder)
    }

    const prodOptions = (prod) => {
        let options = []
        if (prodData && prod.collection && prod.type) {
            options = prodData[prod.collection].filter(_ => _.type === prod.type && _.maker === prod.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
        }
        return options
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitOrder = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        if (updatedorder.billadd.state !== "") {
            let finalorder = updatedorder
            finalorder.totalprice = parseFloat(finalorder.totalprice)
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderId + '&limit=0', finalorder, config).then(res => {
                setorderdata(res.data[0])
            })
        }
        seteditflag(false)
    }

    const cancelupdates = () => {
        setupdatedorder(orderdata)
        seteditflag(false)
    }

    const backhandler = () => {
        seteditflag(false)
        setsubmitted(false)
    }

    return (
        <div className="tp-orders txt-light-grey">
            <h2>Kuro Gaming Order</h2>
            {editflag ? (
                submitted ? (
                    <>
                        <p>The Order {orderdata.orderid} updates have been successfully submitted.</p>
                        <ul className='btns'>
                            <li>
                                <button onClick={backhandler}>Order Details</button>
                            </li>
                        </ul>
                    </>
                ) : (
                    <>
                        {updatedorder !== null &&
                            <div className="order-details">
                                <table className="border" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Channel</td>
                                            <td>Kuro Gaming Offline</td>
                                        </tr>
                                        <tr>
                                            <td>PO/Ref</td>
                                            <td>{access.offline_orders === "edit" ? <input className="large" value={updatedorder.po_ref} onChange={(e) => updateorder("po_ref", e)} /> : <span>{updatedorder.po_ref}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Date</td>
                                            <td>
                                                {access.offline_orders === "edit" ? <DatePicker selected={updatedorder.order_date === "" ? new Date() : new Date(updatedorder.order_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("order_date", date)} /> : <span>{updatedorder.order_date}</span>}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Order Total</td>
                                            <td>{updatedorder.totalprice}</td>
                                        </tr>
                                        <tr>
                                            <td>Dispatch Due Date</td>
                                            <td>
                                                {access.offline_orders === "edit" ? <DatePicker selected={updatedorder.dispatchby_date === "" ? new Date() : new Date(updatedorder.dispatchby_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("dispatchby_date", date)} /> : <span>{updateorder.discount}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{access.offline_orders === "edit" ? <input className="name" value={updatedorder.user.name} onChange={(e) => updateuser("name", e)} /> : <span>{updatedorder.user.name}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{access.offline_orders === "edit" ? <input className="phone" value={updatedorder.user.phone} onChange={(e) => updateuser("phone", e)} /> : <span>{updatedorder.user.phone}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">Billing Address</td>
                                        </tr>
                                        <tr>
                                            <td>Company Name</td>
                                            <td>{access.offline_orders === "edit" ? <input className="company" value={updatedorder.billadd.company !== null ? updatedorder.billadd.company : ''} onChange={(e) => updateaddress("billadd", "company", e)} /> : <span>{updatedorder.billadd.company}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{access.offline_orders === "edit" ? <input className="name" value={updatedorder.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /> : <span>{updatedorder.billadd.name}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{access.offline_orders === "edit" ? <input className="phone" value={updatedorder.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /> : <span>{updatedorder.billadd.phone}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Address Line1</td>
                                            <td>{access.offline_orders === "edit" ? <textarea className="address" value={updatedorder.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /> : <span>{updatedorder.billadd.addressline1}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Address Line2</td>
                                            <td>{access.offline_orders === "edit" ? <textarea className="address" value={updatedorder.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /> : <span>{updatedorder.billadd.addressline2} </span>}</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>{access.offline_orders === "edit" ? <input className="city" value={updatedorder.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /> : <span>{updatedorder.billadd.city}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Pincode</td>
                                            <td>{access.offline_orders === "edit" ? <input className="pincode" value={updatedorder.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /> : <span>{updatedorder.billadd.pincode}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>
                                                {access.offline_orders === "edit" ? <select className="state" value={updatedorder.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                                    <option value="">Select State</option>
                                                    {provinces.map((province, i) =>
                                                        <option key={i} value={province["state"]}>{province["state"]}</option>
                                                    )}
                                                </select> : <span>{updatedorder.billadd.state}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PAN</td>
                                            <td>{access.offline_orders === "edit" ? <input className="pan" value={updatedorder.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /> : <span>{updatedorder.billadd.pan}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN</td>
                                            <td>{access.offline_orders === "edit" ? <input className="gstin" value={updatedorder.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /> : <span>{updatedorder.billadd.gstin}</span>}</td>
                                        </tr>
                                        {access.offline_orders === "edit" && <tr>
                                            <td colSpan="2">
                                                Shipping Address same as Billing Address
                                                <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!updatedorder.addressflag)} checked={updatedorder.addressflag} />
                                            </td>
                                        </tr>}
                                        {!updatedorder.addressflag &&
                                            <>
                                                <tr>
                                                    <td>Company Name</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="company" value={updatedorder.shpadd.company !== null ? updatedorder.shpadd.company : ''} onChange={(e) => updateaddress("shpadd", "company", e)} /> : <span>{updatedorder.shpadd.company}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="name" value={updatedorder.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /> : <span>{updatedorder.shpadd.name} </span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="phone" value={updatedorder.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /> : <span>{updatedorder.shpadd.phone}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line1</td>
                                                    <td>{access.offline_orders === "edit" ? <textarea className="address" value={updatedorder.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /> : <span>{updatedorder.shpadd.addressline1}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line2</td>
                                                    <td>{access.offline_orders === "edit" ? <textarea className="address" value={updatedorder.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /> : <span>{updatedorder.shpadd.addressline2}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="city" value={updatedorder.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /> : <span>{updatedorder.shpadd.city}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pincode</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="pincode" value={updatedorder.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /> : <span>{updatedorder.shpadd.pincode} </span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>State</td>
                                                    <td>
                                                        {access.offline_orders === "edit" ? <select className="state" value={updatedorder.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                                            <option value="">Select State</option>
                                                            {provinces.map((province, i) =>
                                                                <option key={i} value={province["state"]}>{province["state"]}</option>
                                                            )}
                                                        </select> : <span>{updatedorder.shpadd.state}</span>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>PAN</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="pan" value={updatedorder.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /> : <span>{updatedorder.shpadd.pan}</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>GSTIN</td>
                                                    <td>{access.offline_orders === "edit" ? <input className="gstin" value={updatedorder.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /> : <span>{updatedorder.shpadd.gstin}</span>}</td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>

                                {Object.keys(errmsg).map((err, i) =>
                                    errmsg[err] !== "" && <p key={i}>{errmsg[err]}</p>
                                )}
                                {access.offline_orders === "edit" &&
                                    <table className="border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Customer</td>
                                                <td>
                                                    <textarea className="small" type="text" value={updatedorder.notes.customer} onChange={(e) => updatenotes("customer", e)} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Admin</td>
                                                <td>
                                                    <textarea className="small" type="text" value={updatedorder.notes.admin} onChange={(e) => updatenotes("admin", e)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                            </div>}


                        <div className="products">
                            <div className='title_wrap col_mob'>
                                {access.offline_orders === "edit" ?
                                    <p>Builds</p> : (updatedorder.builds && updatedorder.builds.length > 0) && <p>Builds</p>}
                                {access.offline_orders === "edit" && <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                            </div>
                            {updatedorder.builds && updatedorder.builds.map((build, i) =>
                                < table key={i} className="build border" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className='title'>Title</td>
                                            <td colSpan="4">{access.offline_orders === "edit" ? <textarea className="x-large" value={build.title} onChange={(e) => updatebuild(i, "title", e, false)} /> : <span >{build.title}</span>}</td>
                                            <td>
                                                {access.offline_orders === "edit" && <><span className="prod-span" onClick={() => removebuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                    <span className="prod-span" onClick={() => addbuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span> </>}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='title'>Price</td>
                                            <td>{access.offline_orders === "edit" ? <input type="number" className="large" onWheel={disableScroll} value={build.price} onChange={(e) => updatebuild(i, "price", e, true)} /> : <span  >{build.price}</span>}</td>
                                            <td className='title'>Total Price</td>
                                            <td>{build.totalprice}</td>
                                            <td className='title'>Quantity</td>
                                            <td>{access.offline_orders === "edit" ? <input type="number" style={{ width: 100 }} onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(i, "quantity", e, true)} /> : <span>{build.quantity}</span>}</td>
                                        </tr>
                                        {build.components.map((comp, j) =>
                                            <tr key={j}>
                                                {comp.type === "os" || comp.type === "warranty" ? (
                                                    <>
                                                        <td colSpan="3">{comp.type === "os" ? ("Operating System") : ("Warranty")}</td>
                                                        <td>
                                                            {access.offline_orders === "edit" ? <textarea value={comp.title} onChange={(e) => updatecomp(i, j, "title", e)} /> : (<span>{comp.title}</span>)}

                                                        </td>
                                                        <td>&nbsp;</td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>
                                                            {comp.collection === "components" &&
                                                                <select className="large" value={comp.type} onChange={(e) => updatecomp(i, j, "type", e)}>
                                                                    <option value="">Select Type</option>
                                                                    <option value="cpu">Processor</option>
                                                                    <option value="mob">Motherboard</option>
                                                                    <option value="ram">RAM</option>
                                                                    <option value="gpu">Graphics Card</option>
                                                                    <option value="psu">Power Supply</option>
                                                                    <option value="ssd">SSD</option>
                                                                    <option value="hdd">HDD</option>
                                                                    <option value="cooler">CPU Cooler</option>
                                                                    <option value="tower">Case</option>
                                                                    <option value="fan">Fan</option>
                                                                    <option value="other">Other</option>
                                                                </select>
                                                            }
                                                            {comp.collection === "networking" && "WiFi Card"}
                                                        </td>
                                                        <td>
                                                            {comp.type !== "other" &&
                                                                <select className="large" value={comp.maker} onChange={(e) => updatecomp(i, j, "maker", e)}>
                                                                    <option value="">Select Brand</option>
                                                                    {comp.collection && comp.type !== "other" && prodData &&
                                                                        [...new Set(prodData[comp.collection].filter(_ => _.type === comp.type).map(item => item.maker))].sort().map((maker, j) =>
                                                                        <option key={j} value={maker}>{maker}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            }
                                                        </td>
                                                        <td>
                                                            {comp.type !== "other" &&
                                                                <Select classNamePrefix="kuro-search-select" options={prodOptions(comp)} defaultValue={{ value: comp.productid, label: comp.title }} onChange={(e) => updatecompmodel(i, j, e)} placeholder="Select Model" className="react-select-container" />
                                                            }
                                                        </td>
                                                        <td>
                                                            {access.offline_orders === "edit" ? <textarea value={comp.title} onChange={(e) => updatecomp(i, j, "title", e)} /> : <span>{comp.title}</span>}
                                                        </td>
                                                        <td>
                                                            <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatecomp(i, j, "quantity", e, true)} />
                                                        </td>
                                                    </>
                                                )}
                                                <td>
                                                    {access.offline_orders === "edit" && <><span className="prod-span" onClick={() => removecomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                        <span className="prod-span" onClick={() => addcomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span> </>}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                            <div className='title_wrap col_mob'>
                                {access.offline_orders === "edit" ? <p>Peripherals</p> : (updatedorder.products && updatedorder.products.length > 0) && <p>Peripherals</p>}
                                {updatedorder.products && updatedorder.products.length > 0 && <p>Peripherals</p>}
                                {access.offline_orders === "edit" && <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                            </div>
                            {updatedorder.products && updatedorder.products.length > 0 &&
                                <table className="border" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                        <tr>
                                            <td>Collection</td>
                                            <td>Type</td>
                                            <td>Category</td>
                                            <td>Brand</td>
                                            <td>Model</td>
                                            <td>Title</td>
                                            <td>Price</td>
                                            <td>Tax Rate</td>
                                            <td>Quantity</td>
                                            <td>Discount<br />(Per Unit)</td>
                                            <td>Total Price</td>
                                            {access.offline_orders === "edit" && <td></td>}
                                        </tr>
                                        {updatedorder.products.map((prod, i) =>
                                            <tr key={i}>
                                                <td>
                                                    <select className='small' value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                                        <option value="">Select Collection</option>
                                                        {prodData && Object.keys(prodData).sort().map((coll, j) =>
                                                            <option key={j} value={coll}>{getoptionlabel(i, "collection", coll)}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className='small' value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                                        <option value="">Select Type</option>
                                                        {prod.collection && prodData &&
                                                            [...new Set(prodData[prod.collection].map(_ => _.type))].sort().map((type, j) =>
                                                            <option key={j} value={type}>{getoptionlabel(i, "type", type)}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                                        <option value="">Select Category</option>
                                                    {prod.collection && prod.type && prodData &&
                                                        [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(_ => _.category))].sort().map((cat, j) =>
                                                            cat !== "" &&
                                                        <option key={j} value={cat}>{getoptionlabel(i, "category", cat)}</option>
                                                        )
                                                    }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className='small' value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                                        <option value="">Select Brand</option>
                                                        {prod.collection && prod.type && prodData &&
                                                            [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(item => item.maker))].sort().map((maker, j) =>
                                                            <option key={j} value={maker}>{maker}</option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                                                </td>
                                                <td>
                                                    {access.offline_orders === "edit" ? <textarea value={prod.title} onChange={(e) => updateprod(i, "title", e)} /> : <span>{prod.title}</span>}
                                                </td>
                                                <td>
                                                    {access.offline_orders === "edit" ? <input type="number" className="small" onWheel={disableScroll} value={prod.prodprice} onChange={(e) => updateprod(i, "prodprice", e, true)} /> : <span>{prod.prodprice}</span>}
                                                </td>
                                                <td>
                                                    {access.offline_orders === "edit" ? <select value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                                        <option value={5}>5</option>
                                                        <option value={12}>12</option>
                                                        <option value={18}>18</option>
                                                        <option value={28}>28</option>
                                                    </select> : <span>{prod.tax_rate}</span>}
                                                </td>
                                                <td>
                                                    {access.offline_orders === "edit" ? <input type="number" className="small" onWheel={disableScroll} value={prod.quantity} min="1" max="100" onChange={(e) => updateprod(i, "quantity", e, true)} /> : <span>{prod.quantity}</span>}
                                                </td>
                                                <td>
                                                    {access.offline_orders === "edit" ? <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} /> : <span>{prod.discount}</span>}
                                                </td>
                                                <td>
                                                    {prod.totalprice}
                                                </td>
                                                {access.offline_orders === "edit" && <td> <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                    <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Taxable Amount</td>
                                        <td>{updatedorder.totalpricebgst}</td>
                                    </tr>
                                    {updatedorder.billadd.state === "Telangana" ? (
                                        <>
                                            <tr>
                                                <td>SGST</td>
                                                <td>{updatedorder.cgst}</td>
                                            </tr>
                                            <tr>
                                                <td>CGST</td>
                                                <td>{updatedorder.cgst}</td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td>IGST</td>
                                            <td>{updatedorder.gst}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Round Off</td>
                                        <td>{updatedorder.roundoff}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>{updatedorder.totalprice}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Discount</td>
                                        <td>{updatedorder.totaldiscount}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {updatedorder.outward && updatedorder.builds && updatedorder.outward.builds && updatedorder.outward.builds.map((build, i) =>
                                <div className='serials'>
                                    <h3>Build Serial Numbers</h3>
                                    <table className="build border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Product Title</td>
                                                <td>Serial Number</td>
                                            </tr>
                                            {build.serials.map((comp, j) =>
                                                <tr key={j}>
                                                    <td>
                                                        {updatedorder.builds[i].components[j].title}
                                                    </td>
                                                    <td>
                                                        {comp.sr_no.map((e, k) => {
                                                            return <><input value={e} className="large" onChange={(e) => updateoutward("builds", e, i, j, k)} /><br /></>
                                                        })}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {updatedorder.outward && updatedorder.products.length > 0 && updatedorder.outward.products &&
                                <div>
                                    <h3>Product Serial Numbers</h3>
                                    <table className="prod border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Product Title</td>
                                                <td>Serial Number</td>
                                            </tr>
                                            {updatedorder.outward.products.map((prod, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {updatedorder.products[i].title}
                                                    </td>
                                                    <td>
                                                        {prod.sr_no.map((e, j) => {
                                                            return <><input value={e} className="large" onChange={(e) => updateoutward("products", e, i, j)} /><br /></>
                                                        })}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {updatedorder.billadd.pan === "" && updatedorder.totalprice > 200000 && <><span className='txt-light strong'>Note: Order Total is more than 2 Lakhs, Need Customer's PAN Number</span><p>&nbsp;</p></>}
                            <ul className='btns'>
                                <li>
                                    <button onClick={cancelupdates}>Cancel</button>
                                </li>
                                <li>
                                    <button onClick={submitOrder}>Submit Order</button>
                                </li>
                            </ul>
                        </div>
                    </>
                )
            ) : (
                <div className="order-details">
                    <ul className="btns">
                        {(orderdata !== null && accesslevels.find(item => item.offline_orders === "edit" && item.entity === orderdata.entity)) && <li><button onClick={editorder}>Edit the Order</button></li>}
                        <li><KuroLink to="/offlineorders"><button>back</button></KuroLink></li>
                    </ul>
                    {orderdata !== null &&
                        <div>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>OrderId</td>
                                        <td colSpan="2">Order Details</td>
                                        <td>Customer Details</td>
                                        <td colSpan="2">Billing Address</td>
                                        <td colSpan="2">Shipping Address</td>
                                    </tr>
                                    <tr>
                                        <td>{orderdata.orderid}</td>
                                        <td colSpan="2">
                                            <p>OrderId: {orderdata.orderid}</p>
                                            <p>PO/Ref: {orderdata.estimate_no}</p>
                                            <p>Order Date: {moment(orderdata.order_date).format('DD-MM-YYYY')}</p>
                                            <p>Order Total: {orderdata.totalprice}</p>
                                        </td>
                                        <td>
                                            <p>Name: {orderdata.user.name}</p>
                                            <p>Mobile: {orderdata.user.phone}</p>
                                        </td>
                                        <td colSpan={2}>
                                            {orderdata.billadd.company &&
                                                <>
                                                    {orderdata.billadd.company}<br />
                                                </>
                                            }
                                            {orderdata.billadd.name &&
                                                <>
                                                    {orderdata.billadd.name}
                                                </>
                                            }
                                            {orderdata.billadd.phone &&
                                                <>
                                                    <br />{orderdata.billadd.phone}
                                                </>}
                                            {orderdata.billadd.addressline1 &&
                                                <>
                                                    <br />{orderdata.billadd.addressline1}
                                                </>
                                            }
                                            {orderdata.billadd.addressline2 &&
                                                <>
                                                    ,&nbsp;{orderdata.billadd.addressline2}
                                                </>
                                            }
                                            {orderdata.billadd.city &&
                                                <>
                                                    <br />{orderdata.billadd.city}
                                                </>
                                            }
                                            {orderdata.billadd.state &&
                                                <>
                                                    <br />{orderdata.billadd.state}
                                                </>
                                            }
                                            {orderdata.billadd.pincode &&
                                                <>
                                                    &nbsp;- {orderdata.billadd.pincode}
                                                </>
                                            }
                                            {orderdata.billadd.pan &&
                                                <>
                                                    <br />PAN: {orderdata.billadd.pan}
                                                </>
                                            }
                                            {orderdata.billadd.gstin &&
                                                <>
                                                    <br />GSTIN: {orderdata.billadd.gstin}
                                                </>
                                            }
                                        </td>
                                        {orderdata.addressflag ? (
                                            <td colSpan={2}>
                                                {orderdata.billadd.company &&
                                                    <>
                                                        {orderdata.billadd.company}<br />
                                                    </>
                                                }
                                                {orderdata.billadd.name &&
                                                    <>
                                                        {orderdata.billadd.name}
                                                    </>
                                                }
                                                {orderdata.billadd.phone &&
                                                    <>
                                                        <br />{orderdata.billadd.phone}
                                                    </>}
                                                {orderdata.billadd.addressline1 &&
                                                    <>
                                                        <br />{orderdata.billadd.addressline1}
                                                    </>
                                                }
                                                {orderdata.billadd.addressline2 &&
                                                    <>
                                                        ,&nbsp;{orderdata.billadd.addressline2}
                                                    </>
                                                }
                                                {orderdata.billadd.city &&
                                                    <>
                                                        <br />{orderdata.billadd.city}
                                                    </>
                                                }
                                                {orderdata.billadd.state &&
                                                    <>
                                                        <br />{orderdata.billadd.state}
                                                    </>
                                                }
                                                {orderdata.billadd.pincode &&
                                                    <>
                                                        &nbsp;- {orderdata.billadd.pincode}
                                                    </>
                                                }
                                                {orderdata.billadd.pan &&
                                                    <>
                                                        <br />PAN: {orderdata.billadd.pan}
                                                    </>
                                                }
                                                {orderdata.billadd.gstin &&
                                                    <>
                                                        <br />GSTIN: {orderdata.billadd.gstin}
                                                    </>
                                                }
                                            </td>
                                        ) : (
                                            <td colSpan={2}>
                                                {orderdata.shpadd.company &&
                                                    <>
                                                        {orderdata.shpadd.company}<br />
                                                    </>
                                                }
                                                {orderdata.shpadd.name &&
                                                    <>
                                                        {orderdata.shpadd.name}
                                                    </>
                                                }
                                                {orderdata.shpadd.phone &&
                                                    <>
                                                        <br />{orderdata.shpadd.phone}
                                                    </>}
                                                {orderdata.shpadd.addressline1 &&
                                                    <>
                                                        <br />{orderdata.shpadd.addressline1}
                                                    </>
                                                }
                                                {orderdata.shpadd.addressline2 &&
                                                    <>
                                                        ,&nbsp;{orderdata.shpadd.addressline2}
                                                    </>
                                                }
                                                {orderdata.shpadd.city &&
                                                    <>
                                                        <br />{orderdata.shpadd.city}
                                                    </>
                                                }
                                                {orderdata.shpadd.state &&
                                                    <>
                                                        <br />{orderdata.shpadd.state}
                                                    </>
                                                }
                                                {orderdata.shpadd.pincode &&
                                                    <>
                                                        &nbsp;- {orderdata.shpadd.pincode}
                                                    </>
                                                }
                                                {orderdata.shpadd.pan &&
                                                    <>
                                                        <br />PAN: {orderdata.shpadd.pan}
                                                    </>
                                                }
                                                {orderdata.shpadd.gstin &&
                                                    <>
                                                        <br />GSTIN: {orderdata.shpadd.gstin}
                                                    </>
                                                }
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Customer</td>
                                        {orderdata.notes.customer === "" ? (
                                            <td>No Interaction</td>
                                        ) : (
                                            <td>{orderdata.notes.customer}</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>Admin</td>
                                        {orderdata.notes.admin === "" ? (
                                            <td>No Interaction</td>
                                        ) : (
                                            <td>{orderdata.notes.admin}</td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            {orderdata.builds && orderdata.builds.length > 0 &&
                                <div className='builds'>
                                    <h3>Builds</h3>
                                    <table className="border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Build Details</td>
                                                <td>Components</td>
                                                <td>Products</td>
                                            </tr>
                                            {orderdata.builds.map((build, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        <p>Title: {build.title}<br /></p>
                                                        <p>Price: {build.price}<br /></p>
                                                        <p>Quantity: {build.quantity}<br /></p>
                                                        <p>Total Price: {build.totalprice}</p>
                                                    </td>
                                                    <td>
                                                        {build.components.map((comp, j) =>
                                                            <p key={j}>
                                                                {comp.type}: {comp.quantity > 1 && "[QTY: " + comp.quantity + "] "}{comp.title}
                                                            </p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {build.components.map((comp, j) =>
                                                            comp.productid !== "" &&
                                                            <p key={j}>
                                                                {comp.type}: {comp.quantity > 1 && "[QTY: " + comp.quantity + "] "}{ prodData !== null && prodData[comp.collection].filter(_ => _.productid === comp.productid)[0]?.title}
                                                                {orderdata.outward && orderdata.outward.builds && orderdata.outward.builds[i].serials[j].sr_no.join('') !== "" ? (
                                                                    orderdata.outward && orderdata.outward.builds && " [S/N: " + (orderdata.outward.builds[i].serials[j].sr_no.join(', ')) + "]"
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </p>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {orderdata.products && orderdata.products.length > 0 &&
                                <div className='peripherals'>
                                    <h3>Peripherals</h3>
                                    <table className="border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td colSpan="6">Product</td>
                                                <td>Price</td>
                                                <td>Quantity</td>
                                                <td>Total Price</td>
                                            </tr>
                                            {orderdata.products.map((prod, i) =>
                                                <tr key={i}>
                                                    <td colSpan="6">
                                                        <p>
                                                            {prod.title}
                                                            {orderdata.outward && orderdata.outward.products[i] && orderdata.outward.products[i].sr_no.join('') !== "" ? (
                                                                orderdata.outward && orderdata.outward.products[i] && " [S/N: " + (orderdata.outward.products[i].sr_no.join(', ')) + "]"
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>{prod.price}</td>
                                                    <td>{prod.quantity}</td>
                                                    <td>{prod.totalprice}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <table className="border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Taxable Amount</td>
                                                <td>{orderdata.totalpricebgst}</td>
                                            </tr>
                                            {orderdata.billadd.state === "Telangana" ? (
                                                <>
                                                    <tr>
                                                        <td>SGST</td>
                                                        <td>{orderdata.cgst}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CGST</td>
                                                        <td>{orderdata.cgst}</td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <tr>
                                                    <td>IGST</td>
                                                    <td>{orderdata.gst}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>Round Off</td>
                                                <td>{orderdata.roundoff}</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>{orderdata.totalprice}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Discount</td>
                                                <td>{orderdata.totaldiscount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    }
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(OfflineOrder)