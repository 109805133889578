import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import note from '../assets/img/button.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const TPBuilds = ({ user: { token }, admin: { accesslevels } }) => {

    const [tpBuilds, settpBuilds] = useState(null)
    const history = useHistory();
    useEffect(() => {
        const access = accesslevels.find(item => item.tp_builds !== "NA")
        if (access === undefined || access.length <= 0) {
            history.push('/unauthorized')
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/tpbuilds', config).then(res => {
            settpBuilds(res.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [])

    return (
        <div className='tpbuilds txt-light-grey'>
            <h2>TP-Builds</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">
                    <b>Build ID:</b> Unique identifier for the build. Click the link to view details. <br />
                    <b>Build Title:</b> Descriptive name or title of the build. <br />
                    <b>ASIN:</b> Amazon Standard Identification Number, if applicable. <br />
                    <b>Kuro SKU:</b> Internal SKU code assigned to the build. <br />
                    <b>Build Total:</b> Total cost or value of the build.   <br />
                </span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            <ul className="btns">
                <li>
                    <KuroLink to="/create-tpbuilds"><button>Add TP-Build</button></KuroLink>
                </li>
            </ul>
            <div className='tpbuilds'>
                <table className='even_odd border txt-light-grey table_mob'>
                    <tbody>
                        <tr>
                            <th>Build ID</th>
                            <th>Build Title</th>
                            <th>ASIN</th>
                            <th>Kuro SKU</th>
                            <th>Build Total</th>
                        </tr>
                        {tpBuilds !== null && tpBuilds.map((item, i) =>
                            <tr key={i}>
                                <td><KuroLink to={"/tpbuilds/" + item.buildid}>{item.buildid}</KuroLink></td>
                                <td>{item.title}</td>
                                <td>{item.asin}</td>
                                <td>{item.kuro_sku}</td>
                                <td>{item.kurototal}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(TPBuilds)