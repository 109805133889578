import axios from "axios"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getVendors } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import '../styles/indent-list.css'
import Select from 'react-select'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import fileDownload from "js-file-download"


const IndentList = ({ user: { token, userDetails }, admin: { vendors, accesslevels, bgDetails }, getVendors }) => {

    const [tabIndex, setTabIndex] = useState(0)
    const [indents, setIndents] = useState(null)
    const [batchData, setbatchData] = useState(null)
    const [selectedIndents, setSelectedIndents] = useState([])
    const [selectedBatches, setSelectedBatches] = useState([])
    const [editable, setEditable] = useState(false)
    const [expandedRows, setExpandedRows] = useState([]);
    const history = useHistory();
    const access = accesslevels.find(item => item.indent !== "NA");
    const [vendorOptions, setVendorOptions] = useState([]);



    const getData = async () => {
        try {
            await getVendors();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
            const res = await axios.get(process.env.REACT_APP_KC_API_URL + `kurostaff/indent?indent=true`, config)
            setIndents(res.data)
            const batchRes = await axios.get(process.env.REACT_APP_KC_API_URL + `kurostaff/indent?batch=true`, config)
            setbatchData(batchRes.data)
        } catch (error) {
            console.error('Error in IndentList:', error)
            if (error.response.status === 401) {
                history.push("/unauthorized")
            }
        }
    }

    var fileDownload = require('js-file-download')

    const downloadpo = (batchid) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/indent?batchid=' + batchid + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, batchid + '.pdf')
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }
    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return;
        }
        getData();
    }, [])

    useEffect(() => {
        let tempdata = [{ value: 'instock', label: 'In Stock' }];
        if (vendors && vendors.length > 0) {
            vendors.map((item) => {
                tempdata.push({ value: item.vendor_code, label: item.name })
            })
            setVendorOptions(tempdata)
        }
    }, [vendors])


    const handleInputChange = (index, field, value) => {
        const updatedIndents = indents.map((indent, i) => {
            if (i === index) {
                if (field.startsWith('products')) {
                    const [_, productIndex, productField] = field.split('.');

                    return {
                        ...indent,
                        products: indent.products.map((product, j) => {
                            if (j === parseInt(productIndex)) {
                                const updatedProduct = { ...product, [productField]: value };
                                if (productField === 'quantity' || productField === 'price') {
                                    updatedProduct.totalprice = updatedProduct.price * updatedProduct.quantity;
                                }
                                return updatedProduct;
                            }
                            return product;
                        }),
                    };
                } else {
                    return { ...indent, [field]: value };
                }
            }
            return indent;
        });
        setIndents(updatedIndents);
    };

    const handleEdit = async (data) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
            if (editable === data.indentid) {
                if (editable === 'new') {
                    const res = await axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/indent?new=true', data, config);
                    setIndents(res.data);
                } else {
                    await axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/indent?indentid=' + data.indentid, data, config);
                    let tempdata = [...indents];
                    tempdata = tempdata.map((item) => {
                        if (item.indentid === data.indentid) {
                            return data;
                        }
                        return item;
                    });
                    setIndents(tempdata);
                }
                setEditable(false);

            } else {
                setEditable(data.indentid);
            }
        } catch (error) {
            console.error('Error handling edit:', error);
            if (error.response.status === 401) {
                history.push("/unauthorized")
            }
        };
    }

    const toggleSelectedIndent = (indent) => {
        setSelectedIndents((prevSelectedIndents) =>
            prevSelectedIndents.includes(indent)
                ? prevSelectedIndents.filter((p) => p !== indent)
                : [...prevSelectedIndents, indent]
        );
    };

    const toggleSelectedBatches = (batch) => {
        setSelectedBatches((prevSelectedBatches) =>
            prevSelectedBatches.includes(batch)
                ? prevSelectedBatches.filter((p) => p !== batch)
                : [...prevSelectedBatches, batch]
        );
    };
    const selectAllIndents = () => {
        if (selectedIndents.length === indents.length) {
            setSelectedIndents([]);
        } else {
            setSelectedIndents(indents);
        }
    }

    const selectAllBatches = () => {
        if (selectedBatches.length === batchData.length) {
            setSelectedBatches([]);
        } else {
            setSelectedBatches(batchData);
        }
    }
    const convertToBatches = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
            const res = await axios.post(
                `${process.env.REACT_APP_KC_API_URL}kurostaff/indent?batch=true`,
                selectedIndents,
                config
            );
            setSelectedIndents([]);
            setbatchData(res.data);
            await getData();
            alert('Batches created successfully');

        } catch (error) {
            console.error('Error in convertToBatches:', error);
            alert('Error in convertToBatches');
            if (error.response.status === 401) {
                history.push("/unauthorized")
            }
        }
    };


    const convertToPos = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
            const res = await axios.post(
                `${process.env.REACT_APP_KC_API_URL}kurostaff/indent?pos=true`,
                selectedBatches,
                config
            );
            setSelectedBatches([]);
            await getData();
            alert('Purchase Order created successfully');
        } catch (error) {
            console.error('Error in convertToPost:', error);
            alert('Error converting to Purchase Order');
            if (error.response.status === 401) {
                history.push("/unauthorized")
            }
        }
    };

    const addIndentRow = (index) => {
        setIndents(prevState => {
            const newBatchData = [...prevState];
            newBatchData.splice(index + 1, 0, { 'indentid': 'new', 'entity': accesslevels.find(item => item.indent === "edit" || item.indent === "write").entity, 'vendor': '', 'products': [{ 'title': '', 'quantity': 0, 'price': 0, 'totalprice': 0 }] });
            return newBatchData;
        });
        setEditable('new');
    };

    /* eslint-disable no-restricted-globals */
    const removeIndentRow = (indentid) => {
        if (indentid === 'new') {
            setIndents(prevState => prevState.filter(item => item.indentid !== 'new'));
        } else if (confirm('Are you sure you want to delete this indent?')) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
            axios.post(`${process.env.REACT_APP_KC_API_URL}kurostaff/indent?indentid=${indentid}&delete=true`, {}, config)
                .then((res) => {
                    setIndents(res.data);
                    setSelectedIndents([]);
                }).catch((err) => {
                    if (err.response.status === 401) {
                        history.push("/unauthorized")
                    }
                })
        }
    };
    /* eslint-enable no-restricted-globals */
    const toggleExpandRow = (id) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(id) ? prevExpandedRows.filter((rowId) => rowId !== id) : [...prevExpandedRows, id]
        );
    };



    return (
        <div className="indent-list" style={{ color: '#f2f2f2' }}>
            <h3 className='txt-light'>Indent List</h3>
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Indents</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Batches</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    {indents !== null &&
                        indents.length > 0 ? (
                        <div className="indents">
                            {selectedIndents.length > 0 && <button disabled={editable} onClick={convertToBatches} className="fixed-btn">Convert {selectedIndents.length} Indent(s) to Batch</button>}
                            <table className="border table_mob" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th>Indent ID</th>
                                        <th>Entity</th>
                                        <th style={{ width: "500px" }}>Product Title</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                        <th style={{ width: "300px" }}>Vendor</th>
                                        <th style={{ display: 'flex', alignItems: 'center' }}>Select <input type="checkbox" onChange={selectAllIndents} checked={selectedIndents.length === indents.length} /></th>
                                        <th>Edit</th>
                                        <th>Add </th>
                                        {userDetails.access === "Super" && <th>Delete</th>}
                                    </tr>
                                    {indents.length > 0 && indents.map((data, index) => (
                                        <tr key={index}>
                                            <td>
                                                {data.indentid}

                                            </td>
                                            <td>
                                            {data.indentid !== editable ? <p style={{ textWrap: "wrap" }}>{ bgDetails && bgDetails.entities.map( ent => ent.name === data.entity ? ent.title : null )}</p> : <>  <select className="type" name="entity" value={data.entity} onChange={(e) => handleInputChange(index, 'entity', e.target.value)} >
                                        {accesslevels.map((item, index) => (item.indent === "edit" || item.indent === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option>) : (null))}
                                    </select>
                                                </>}
                                            </td>
                                            <td>
                                                {data.indentid !== editable ? <p style={{ textWrap: "wrap" }}>{data.products[0]?.title}</p> : <> <input className="large"
                                                    type="text"
                                                    value={data.products[0]?.title}
                                                    onChange={(e) => handleInputChange(index, 'products.0.title', e.target.value)}
                                                /></>}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={data.products[0]?.price}
                                                    onChange={(e) => handleInputChange(index, 'products.0.price', e.target.value)}
                                                    readOnly={data.indentid !== editable}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={data.products[0]?.quantity}
                                                    onChange={(e) => handleInputChange(index, 'products.0.quantity', e.target.value)}
                                                    readOnly={data.indentid !== editable}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    value={data.products[0]?.totalprice}
                                                    onChange={(e) => handleInputChange(index, 'products.0.totalprice', e.target.value)}
                                                    readOnly
                                                />
                                            </td>
                                            <td>
                                                {data.indentid !== editable ? (
                                                    <p>{data.vendor}</p>
                                                ) : (
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="kuro-search-select"
                                                        options={vendorOptions}
                                                        value={vendorOptions.find(option => option.value === data.vendor)}
                                                        onChange={(selectedOption) => handleInputChange(index, 'vendor', selectedOption.value)}
                                                        placeholder="Select Vendor"
                                                    />
                                                )}

                                            </td>
                                            {accesslevels.find(item => (item.indent === "edit" || item.indent === "write")) ? (<td>
                                                <button className="checkbox" onDoubleClick={() => selectAllIndents(data)} onClick={() => toggleSelectedIndent(data)}>
                                                    {selectedIndents.includes(data) ? (
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M4 0C1.79086 0 0 1.79086 0 4V21C0 23.2091 1.79086 25 4 25H21C23.2091 25 25 23.2091 25 21V4C25 1.79086 23.2091 0 21 0H4ZM4.53846 1H20.4615C21.3997 1.00102 22.2991 1.37415 22.9625 2.03752C23.6258 2.70089 23.999 3.60032 24 4.53846V20.4615C23.999 21.3997 23.6258 22.2991 22.9625 22.9625C22.2991 23.6258 21.3997 23.999 20.4615 24H4.53846C3.60032 23.999 2.70089 23.6258 2.03752 22.9625C1.37415 22.2991 1.00102 21.3997 1 20.4615V4.53846C1.00102 3.60032 1.37415 2.70089 2.03752 2.03752C2.70089 1.37415 3.60032 1.00102 4.53846 1ZM11.0542 17.492L18.485 8.64584C18.6319 8.46575 18.702 8.23509 18.6802 8.00371C18.6584 7.77233 18.5464 7.55883 18.3685 7.40935C18.1905 7.25987 17.9609 7.18642 17.7292 7.20489C17.4975 7.22335 17.2824 7.33223 17.1304 7.50801L10.3526 15.5751L7.84858 12.793C7.77177 12.7036 7.67786 12.6304 7.57236 12.5778C7.46686 12.5251 7.35191 12.4941 7.23425 12.4866C7.1166 12.479 6.99862 12.495 6.88725 12.5337C6.77587 12.5724 6.67336 12.6329 6.58572 12.7118C6.49809 12.7907 6.4271 12.8862 6.37693 12.9929C6.32677 13.0996 6.29843 13.2153 6.2936 13.3331C6.28876 13.4509 6.30752 13.5684 6.34878 13.6789C6.39003 13.7893 6.45294 13.8904 6.53382 13.9762L9.71844 17.5147C9.80136 17.6068 9.90273 17.6806 10.016 17.7311C10.1292 17.7815 10.2518 17.8076 10.3758 17.8077H10.3907C10.5175 17.8057 10.6423 17.7765 10.7568 17.722C10.8713 17.6675 10.9727 17.5891 11.0542 17.492Z"
                                                                fill="#5AF27A"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <rect x="0.5" y="0.5" width="24" height="24" rx="3.5" stroke="#D9D9D9" />
                                                        </svg>
                                                    )}
                                                </button>
                                            </td>):(<td>You Don`t Have Access</td>)}

                                            {(accesslevels.find(item => item.inedent === "write" || item.indent === "edit")) ? (<td style={{ display: 'flex', gap: 5, height: '100%' }} ><button onClick={() => handleEdit(data)}>{data.indentid === editable ? "Save" : "Edit"}
                                            </button>
                                                {data.indentid === editable && <button style={{ border: 0, minWidth: 65, }} onClick={() => setEditable(false)}>Cancel</button>}
                                            </td>):(<td>You Don`t Have Access</td>)}

                                            {(accesslevels.find(item => (item.indent === "write" || item.indent === "edit"))) ? (<td> <button onClick={() => addIndentRow(index)} >Add <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></button>
                                            </td>):(<td>You Don`t Have Access</td>)}

                                            {userDetails.access === "Super" && <td><button onClick={() => removeIndentRow(data.indentid)} className="delete-btn" >DELETE <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></button>
                                            </td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <>
                            <p>No Indent Found</p>
                            <button onClick={addIndentRow}>Add</button>
                        </>
                    )}
                </div>

            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                    {selectedBatches.length > 0 && <button onClick={convertToPos} className="fixed-btn">Convert {selectedBatches.length} Batch(es) to purchase order</button>}
                    {batchData !== null &&
                        batchData.length > 0 ? (
                        <table className="border table_mob" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>Batch Id</th>
                                    <th>Vendor</th>
                                    <th>Total</th>
                                    <th>Po no</th>
                                    <th>Po Date</th>
                                    <th>Products</th>
                                    <th>Download</th>
                                </tr>
                                {batchData.filter(batch => batch.products.length > 0).map((data, index) => (
                                    <tr key={index}>
                                        <td>{accesslevels.find(item => item.entity === data.entity && item.indent === "edit") ? <KuroLink to={'/batches/' + data.batchid}>{data.batchid}</KuroLink> : <span>{data.batchid}</span>}</td>
                                        <td>{data.vendor}</td>
                                        <td>{data.totalprice}</td>
                                        <td>{data.po_no}</td>
                                        <td>{data.po_date}</td>
                                        <td style={{ position: "relative", width: "520px" }}>
                                            {!expandedRows.includes(data.batchid) && data.products.map((product, index) => (
                                                <p key={index}>{product.title}</p>
                                            ))}

                                            <button className="arrow" onClick={() => toggleExpandRow(data.batchid)}>
                                                {expandedRows.includes(data.batchid) ? "▲" : "▼"}
                                            </button>

                                            {expandedRows.includes(data.batchid) && (
                                                <table width="500px" className="border" style={{ marginBottom: 0, marginTop: "20px" }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Indent Id</th>
                                                            <th>Quantity</th>
                                                            <th>Price</th>
                                                            <th>Total Price</th>
                                                        </tr>
                                                        {data.products.map((product, index) => (
                                                            <tr key={index}>
                                                                <td>{product.title}</td>
                                                                <td>{product.indentid}</td>
                                                                <td>{product.quantity}</td>
                                                                <td>{product.price}</td>
                                                                <td>{product.totalprice}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </td>
                                        <td>
                                            { data.po_no === "" ? (accesslevels.find(item => ((item.purchase_orders === "edit" || item.purchase_orders === "write") && item.entity===data.entity))?<KuroLink to={"/create-po/" + data.batchid} ><button>Create Po</button></KuroLink>: "You Don`t Have Access"):
                                                (accesslevels.find(item => (item.purchase_orders !== "NA" && item.entity === data.entity)) ? <button onClick={() => downloadpo(data.batchid)}>Download Po</button>: "You Don`t Have Access")
                                                }
                                        </td>

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    ) : (
                        <p>No Batch Found</p>
                    )}
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = (state) => ({
    admin: state.admin,
    user: state.user
})
export default connect(mapStateToProps, { getVendors })(IndentList)