import React, { useState } from 'react';
import axios from 'axios';
import note from '../assets/img/button.png'
import { connect } from 'react-redux';

const Audit = ({ user: { token } }) => {

    const [srno, setsrno] = useState("")
    const [stock, setstock] = useState(null)

    const getdetails = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/stock?srno=' + srno, config).then((res) => {
            setstock(res.data)
        })
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/stock', stock[0], config).then((res) => {
            setstock(res.data)
        })
    }

    return (
        <div className="kuro-audit mx-width txt-light-grey">
            <h2>Audit</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">Enter the Serial number to get details</span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            <table>
                <tbody>
                    <tr>
                        <td>Enter Serial No.</td>
                        <td><input className="large" onChange={(e) => setsrno(e.target.value.toUpperCase())} value={srno} /></td>
                        <td><button onClick={getdetails}>Get Details</button></td>
                    </tr>
                </tbody>
            </table>
            {stock !== null &&
                (stock.length > 0 ? (
                    <div className='audit-details'>
                        <table cellPadding="0" cellSpacing="0" className="border txt-light">
                            <tbody>
                                <tr>
                                    <th>Collection</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Productid</th>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Serial No.</th>
                                </tr>
                                {stock.map((prod, i) =>
                                    <tr key={i}>
                                        <td>{prod.collection}</td>
                                        <td>{prod.type}</td>
                                        <td>{prod.category}</td>
                                        <td>{prod.productid}</td>
                                        <td>{prod.title}</td>
                                        <td>{prod.price}</td>
                                        <td>{prod.sr_no}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <button onClick={submitHandler}>Submit</button>
                        <p>&nbsp;</p>
                    </div>
                ) : (
                    <p className='txt-light-grey'>No Stock found matching the Serial Number</p>
                ))
            }
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(Audit)