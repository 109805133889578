import React, { useState, useEffect } from 'react'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import EditableOrder from '../components/EditableOrder'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const UserDetails = ({ match }) => {

    const { params: { userId } } = match

    const [userData, setUserData] = useState(null)
    const history = useHistory()
    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/userdetails?phone=9492540571&userid=' + userId).then(res => {
            setUserData(res.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [])

    const categoryHandler = (category) => {
        switch (category) {
            case "component":
                return "/p/"
            case "monitor":
                return "/m/"
            case "kgbuilds":
                return "/b/"
            case "accessory":
                return "/a/"
            case "networking":
                return "/n/"
            case "external":
                return "/e/"
            default:
                return "/p/"
        }
    }

    return (
        <div className="users txt-light-grey">
            {userData !== null && userData.map((item, i) =>
                <div>
                    <h3>User Details</h3>
                    <table className="border" cellPadding="0" cellSpacing="0">
                        <tr>
                            <th>Userid</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Username</th>
                            <th>Last Login</th>
                        </tr>
                        <tr>
                            <td>{item.profile.userid}</td>
                            <td>{item.profile.name}</td>
                            <td>{item.profile.phone}</td>
                            <td>{item.profile.email}</td>
                            <td>{item.profile.username}</td>
                            <td>{item.profile.last_login}</td>
                        </tr>
                    </table>
                    {item.cart.length > 0 &&
                        <>
                            <h3>Cart</h3>
                            <table className="border" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td>Cart Id</td>
                                    <td>Productid</td>
                                    <td>Category</td>
                                    <td>Quantity</td>
                                    <td>Title</td>
                                    <td>Status</td>
                                    <td>Price</td>
                                </tr>
                                {item.cart.map((prod) =>
                                    <tr>
                                        <td>{prod.cartid}</td>
                                        <td><a href={"https://kurogaming.com" + categoryHandler(prod.category) + prod.prod_url} target="_blank" rel="noreferrer">{prod.productid}</a></td>
                                        <td>{prod.category}</td>
                                        <td>{prod.quantity}</td>
                                        <td>{prod.title}</td>
                                        <td>{prod.status}</td>
                                        <td>{prod.price}</td>
                                    </tr>
                                )}
                            </table>
                        </>
                    }
                    {item.orders.length > 0 &&
                        item.orders.map((order) =>
                            <div className="orders-list">
                                <h3>Orders</h3>
                                <EditableOrder order={order} />
                            </div>
                        )}
                    <div className='col_2'>
                        <KuroLink to="/users"><button>Back</button></KuroLink>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserDetails