import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getStates } from '../actions/admin'
import '../styles/table.css'
import NewOrder from '../components/NewOrder'
import KuroLink from '../components/common/KuroLink'
import '../styles/tabs.css'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const TPOrders = ({ user: { token, userDetails }, admin:{ accesslevels } }) => {

    const [orderflag, setorderflag] = useState("")
    const [ordersData, setOrdersData] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const [limit, setlimit] = useState(20)
    const history = useHistory()
    const access = accesslevels.find(item => item.orders !== "NA")

    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return
        }
    })
  
    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?limit=' + limit, config).then(res => {
            setOrdersData(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err)=>{
            if(err.response.status === 401){
                history.push("/unauthorized")
            }
        })
    }, [orderflag, limit])

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 20)
        }
    }

    var fileDownload = require('js-file-download')

    const orderChecklist = (orderid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/check_list?orderid=' + orderid, config)
            .then((res) => {
                fileDownload(res.data, 'checklist_' + orderid + '.pdf')
            }).catch((err)=>{
                if(err.response.status === 401){
                    history.push("/unauthorized")
                }
            })
    }


    const updateorder = (orderid, orderstatus) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "order_status": orderstatus }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderid , data, config)
            .then((res) => {
                setOrdersData(res.data)
            })
    }

    const orderDelivered = (orderid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "order_status": "Delivered" }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderid, data, config).then(res => {
            setOrdersData(res.data)
        }).catch((err)=>{
            if(err.response.status === 401){
                history.push("/unauthorized")
            }
        })

    }

    return (
        <div className="orders txt-light-grey">
            <h2>Third Party Orders</h2>
            {orderflag === "create" ? (
                <NewOrder updateorderflag={setorderflag} />
            ) : (
                <div className="order-list txt-light-grey">
                    {(accesslevels.find(item => item.orders === "edit" || item.orders === "write")) && <ul className='btns'>
                        <li>
                            <button onClick={() => setorderflag("create")}>Create Order</button>
                        </li>
                    </ul>}

                    <div className="tabs">
                        <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>New Orders</button>
                        <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Pending Authorization</button>
                        <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>Authorized</button>
                        <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Being Processed</button>
                        <button onClick={() => setTabIndex(4)} className={"tab txt-grey" + (tabIndex === 4 ? " active" : "")}>Shipped</button>
                        <button onClick={() => setTabIndex(5)} className={"tab txt-grey" + (tabIndex === 5 ? " active" : "")}>Delivered</button>
                        {userDetails.access === "Super" && <button onClick={() => setTabIndex(6)} className={"tab txt-grey" + (tabIndex === 6 ? " active" : "")}>Cancelled/Returned</button>}
                    </div>
                    <div className="tab-panels">
                        <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                        {userDetails.access === "Super" ? (
                            ordersData !== null && ordersData.filter(order => order.order_status === "Created").length > 0 ? (
                                ordersData.filter(order => order.order_status === "Created").map((order, i) => (
                        <table key={i} className="border table_mob" cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td>OrderId</td>
                              <td>Order Details</td>
                              <td>Customer Details</td>
                              <td colSpan="2">Billing Address</td>
                              <td colSpan="2">Shipping Address</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid}</KuroLink></td>
                              <td>
                                <p>Channel: {order.channel}</p>
                                <p>TP OrderId: {order.tporderid}</p>
                                <p>PO/Ref: {order.po_ref}</p>
                                <p>Order Date: {moment(order.order_date).format('DD-MM-YYYY')}</p>
                                <p>Order Total: {order.totalprice}</p>
                                <p>Dispatch By Date: {order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}</p>
                              </td>
                              <td>
                                <p>Name: {order.user.name}</p>
                                <p>Mobile: {order.user.phone}</p>
                              </td>
                              <td colSpan="2">
                                {order.billadd.company && <>{order.billadd.company}<br /></>}
                                {order.billadd.name && <>{order.billadd.name}</>}
                                {order.billadd.phone && <><br />{order.billadd.phone}</>}
                                {order.billadd.addressline1 && <><br />{order.billadd.addressline1}</>}
                                {order.billadd.addressline2 && <>,&nbsp;{order.billadd.addressline2}</>}
                                {order.billadd.city && <><br />{order.billadd.city}</>}
                                {order.billadd.state && <><br />{order.billadd.state}</>}
                                {order.billadd.pincode && <>&nbsp;- {order.billadd.pincode}</>}
                                {order.billadd.pan && <><br />PAN: {order.billadd.pan}</>}
                                {order.billadd.gstin && <><br />GSTIN: {order.billadd.gstin}</>}
                              </td>
                              {order.addressflag ? (
                                <td colSpan={2}>
                                  {order.billadd.company && <>{order.billadd.company}<br /></>}
                                  {order.billadd.name && <>{order.billadd.name}</>}
                                  {order.billadd.phone && <><br />{order.billadd.phone}</>}
                                  {order.billadd.addressline1 && <><br />{order.billadd.addressline1}</>}
                                  {order.billadd.addressline2 && <>,&nbsp;{order.billadd.addressline2}</>}
                                  {order.billadd.city && <><br />{order.billadd.city}</>}
                                  {order.billadd.state && <><br />{order.billadd.state}</>}
                                  {order.billadd.pincode && <>&nbsp;- {order.billadd.pincode}</>}
                                  {order.billadd.pan && <><br />PAN: {order.billadd.pan}</>}
                                  {order.billadd.gstin && <><br />GSTIN: {order.billadd.gstin}</>}
                                </td>
                              ) : (
                                <td colSpan={2}>
                                  {order.shpadd.company && <>{order.shpadd.company}<br /></>}
                                  {order.shpadd.name && <>{order.shpadd.name}</>}
                                  {order.shpadd.phone && <><br />{order.shpadd.phone}</>}
                                  {order.shpadd.addressline1 && <><br />{order.shpadd.addressline1}</>}
                                  {order.shpadd.addressline2 && <>,&nbsp;{order.shpadd.addressline2}</>}
                                  {order.shpadd.city && <><br />{order.shpadd.city}</>}
                                  {order.shpadd.state && <><br />{order.shpadd.state}</>}
                                  {order.shpadd.pincode && <>&nbsp;- {order.shpadd.pincode}</>}
                                  {order.shpadd.pan && <><br />PAN: {order.shpadd.pan}</>}
                                  {order.shpadd.gstin && <><br />GSTIN: {order.shpadd.gstin}</>}
                                </td>
                              )}
                              <td>
                                <ul className='btns'>
                                  <li>
                                    <button onClick={() => updateorder(order.orderid, "Cancelled")}>Cancel Order</button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                                ))
                            ) : (
                        <p>No Data is Being Processed</p>
                            )
                        ) : (
                            ordersData !== null && ordersData.filter(order => order.order_status === "Created").length > 0 ? (
                        <div className="order">
                          <table cellPadding="0" cellSpacing="0" className="border table_mob">
                            <tbody>
                              <tr>
                                <th>Order Id</th>
                                <th>Order Date</th>
                                <th>Channel</th>
                                <th>TP Order Id</th>
                                <th>Customer Name</th>
                                <th>Mobile</th>
                                <th>Order Total</th>
                                <th>Order Status</th>
                                <th>Dispatch Due Date</th>
                                 <th>Add Product</th>
                              </tr>
                              {ordersData.map((order, i) =>
                                order.order_status === "Created" && (
                                  <tr key={i}>
                                    <td><KuroLink to={'tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                    <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                    <td>{order.channel}</td>
                                    <td>{order.tporderid}</td>
                                    <td>{order.user.name}</td>
                                    <td>{order.user.phone}</td>
                                    <td>{order.totalprice}</td>
                                    <td>{order.order_status}</td>
                                    <td>{order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}</td>
                                    {accesslevels.find(item => ((item.orders === "write" || item.orders === "edit") && item.entity === order.entity) ) ? (
                                      <td><KuroLink to={'tporders/add-products/' + order.orderid}>Add Products</KuroLink></td>
                                    ):(<td>You Don`t Have Access</td>)}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                            ) : (
                                <p className='txt-light'>No New Order is Being Processed</p>
                            )
                        )}
                    </div>

                    <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
      {userDetails.access === "Super" ? (
        ordersData?.filter(order => order.order_status === "Products Added").length > 0 ? (
          ordersData.filter(order => order.order_status === "Products Added").map((order, i) => (
            <table key={i} className="border table_mob" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td>OrderId</td>
                  <td>Order Details</td>
                  <td>Customer Details</td>
                  <td colSpan="2">Billing Address</td>
                  <td colSpan="2">Shipping Address</td>
                </tr>
                <tr>
                  <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid}</KuroLink></td>
                  <td>
                    <p>Channel: {order.channel}</p>
                    <p>TP OrderId: {order.tporderid}</p>
                    <p>PO/Ref: {order.po_ref}</p>
                    <p>Order Date: {moment(order.order_date).format('DD-MM-YYYY')}</p>
                    <p>Order Total: {order.totalprice}</p>
                    <p>Dispatch By Date: {order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</p>
                  </td>
                  <td>
                    <p>Name: {order.user.name}</p>
                    <p>Mobile: {order.user.phone}</p>
                  </td>
                  <td>
                    {order.billadd.company && <>{order.billadd.company}<br /></>}
                    {order.billadd.name && <>{order.billadd.name}</>}
                    {order.billadd.phone && <><br />{order.billadd.phone}</>}
                    {order.billadd.addressline1 && <><br />{order.billadd.addressline1}</>}
                    {order.billadd.addressline2 && <>, {order.billadd.addressline2}</>}
                    {order.billadd.city && <><br />{order.billadd.city}</>}
                    {order.billadd.state && <><br />{order.billadd.state}</>}
                    {order.billadd.pincode && <>&nbsp;- {order.billadd.pincode}</>}
                    {order.billadd.pan && <><br />PAN: {order.billadd.pan}</>}
                    {order.billadd.gstin && <><br />GSTIN: {order.billadd.gstin}</>}
                  </td>
                  <td colSpan={2}>
                    {order.addressflag ? (
                      <>
                        {order.billadd.company && <>{order.billadd.company}<br /></>}
                        {order.billadd.name && <>{order.billadd.name}</>}
                        {order.billadd.phone && <><br />{order.billadd.phone}</>}
                        {order.billadd.addressline1 && <><br />{order.billadd.addressline1}</>}
                        {order.billadd.addressline2 && <>, {order.billadd.addressline2}</>}
                        {order.billadd.city && <><br />{order.billadd.city}</>}
                        {order.billadd.state && <><br />{order.billadd.state}</>}
                        {order.billadd.pincode && <>&nbsp;- {order.billadd.pincode}</>}
                        {order.billadd.pan && <><br />PAN: {order.billadd.pan}</>}
                        {order.billadd.gstin && <><br />GSTIN: {order.billadd.gstin}</>}
                      </>
                    ) : (
                      <>
                        {order.shpadd.company && <>{order.shpadd.company}<br /></>}
                        {order.shpadd.name && <>{order.shpadd.name}</>}
                        {order.shpadd.phone && <><br />{order.shpadd.phone}</>}
                        {order.shpadd.addressline1 && <><br />{order.shpadd.addressline1}</>}
                        {order.shpadd.addressline2 && <>, {order.shpadd.addressline2}</>}
                        {order.shpadd.city && <><br />{order.shpadd.city}</>}
                        {order.shpadd.state && <><br />{order.shpadd.state}</>}
                        {order.shpadd.pincode && <>&nbsp;- {order.shpadd.pincode}</>}
                        {order.shpadd.pan && <><br />PAN: {order.shpadd.pan}</>}
                        {order.shpadd.gstin && <><br />GSTIN: {order.shpadd.gstin}</>}
                      </>
                    )}
                  </td>
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => updateorder(order.orderid,  "Authorized")}>Authorize</button></li>
                      <li><button onClick={() => updateorder(order.orderid,  "Cancelled")}>Cancel Order</button></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <p className='txt-light'>No Pending Order to Authorize</p>
        )
      ) : (
        ordersData?.filter(order => order.order_status === "Products Added").length > 0 ? (
          <div className="order">
            <table cellPadding="0" cellSpacing="0" className="border">
              <tbody>
                <tr>
                  <th>Order Id</th>
                  <th>Order Date</th>
                  <th>Channel</th>
                  <th>TP Order Id</th>
                  <th>Customer Name</th>
                  <th>Mobile</th>
                  <th>Order Total</th>
                  <th>Order Status</th>
                  <th>Dispatch Due Date</th>
                  <th></th>
                </tr>
                {ordersData.map((order, i) =>
                  order.order_status === "Products Added" && (
                    <tr key={i}>
                      <td><KuroLink to={'tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                      <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                      <td>{order.channel}</td>
                      <td>{order.tporderid}</td>
                      <td>{order.user.name}</td>
                      <td>{order.user.phone}</td>
                      <td>{order.totalprice}</td>
                      <td>{order.order_status}</td>
                      <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                      <td>Pending Authorization</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className='txt-light'>No Pending Order to Authorize</p>
        )
      )}
    </div>

    <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
  {userDetails.access === "Super" ? (
    ordersData !== null && ordersData.filter(order => order.order_status === "Authorized").length > 0 ? (
      ordersData.filter(order => order.order_status === "Authorized").map((order, i) => (
        <table key={i} className="border table_mob" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td colSpan="2">
                <p>Channel: {order.channel}</p>
                {order.tporderid !== "" ? (
                  <p>TP OrderId: <strong>{order.tporderid}</strong></p>
                ) : (
                  order.po_ref !== "" && <p>PO/Ref: {order.po_ref}</p>
                )}
                <p>Order Date: {moment(order.order_date).format('DD-MM-YYYY')}</p>
                <p>Dispatch By Date: {order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</p>
              </td>
              <td>
                <p><strong><KuroLink to={'/tporders/' + order.orderid}>{order.orderid}</KuroLink></strong></p>
                <p>{order.user.name}</p>
                <p>{order.user.phone}</p>
                <p>Order Total: {order.totalprice}</p>
              </td>
              <td>
                <ul className='btns'>
                  <li><button onClick={() => updateorder(order.orderid,  "Cancelled")}>Cancel Order</button></li>
                </ul>
              </td>
              <td>
                <ul className='btns'>
                  <li><button onClick={() => orderChecklist(order.orderid)}>Check List</button></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      ))
    ) : (
      <p>No Order for Authorized</p>
    )
  ) : (
    ordersData !== null && ordersData.filter(order => order.order_status === "Authorized").length > 0 ? (
      <div className="order">
        <table cellPadding="0" cellSpacing="0" className="border">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Name</th>
              <th>Mobile</th>
              <th>Order Total</th>
              <th>Order Status</th>
              <th>Dispatch Due Date</th>
              <th>Add Inventory</th>
              <th></th>
            </tr>
            {ordersData.map((order, i) =>
              order.order_status === "Authorized" && (
                <tr key={i}>
                  <td><KuroLink to={'tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                  <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                  <td>{order.channel}</td>
                  <td>{order.tporderid}</td>
                  <td>{order.user.name}</td>
                  <td>{order.user.phone}</td>
                  <td>{order.totalprice}</td>
                  <td>{order.order_status}</td>
                  <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                  {(accesslevels.find(item => ((item.orders === "write" || item.orders === "edit") && item.entity === order.entity) ))?(
                    <td><KuroLink to={'tporders/add-inventory/' + order.orderid }>Add Inventory</KuroLink></td>
                  ):(<td>You Don`t Have Access</td>)}
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => orderChecklist(order.orderid) }>Check List</button></li>
                    </ul>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    ) : (
      <p className='txt-light'>No Order for Authorized</p>
    )
  )}
</div>

<div className={"tab-panel" + (tabIndex === 3 ? " active" : "")}>
  {userDetails.access === "Super" ? (
    ordersData !== null && ordersData.filter(order => order.order_status === "Inventory Added").length > 0 ? (
      ordersData.filter(order => order.order_status === "Inventory Added").map((order, i) => (
        <table key={i} className="border table_mob" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td>
                <p>Channel: {order.channel}</p>
                {order.tporderid !== "" ? (
                  <p>TP OrderId: <strong>{order.tporderid}</strong></p>
                ) : (
                  order.po_ref !== "" && <p>PO/Ref: {order.po_ref}</p>
                )}
                <p>Order Date: {moment(order.order_date).format('DD-MM-YYYY')}</p>
              </td>
              <td>
                <p><strong><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></strong></p>
                <p>{order.user.name}</p>
                <p>{order.user.phone}</p>
                <p>Order Total: {order.totalprice}</p>
              </td>
              <td>
                {order.invoice_generated ? (
                  <b>Invoice Generated</b>
                ) : (
                  <KuroLink classList="btn pri0 solid" to={'tporder-invoice/' + order.orderid}>Generate Invoice</KuroLink>
                )}
              </td>
              <td>
                <ul className='btns'>
                  <li><button onClick={() => updateorder(order.orderid,  "Shipped")}>Shipped</button></li>
                  <li><button onClick={() => updateorder(order.orderid,  "Cancelled")}>Cancel Order</button></li> 
                </ul>
              </td>
              <td>
                <ul className='btns'>
                  <li><button onClick={() => orderChecklist(order.orderid) }>Check List</button></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      ))
    ) : (
      <p>No Order for Inventory Added</p>
    )
  ) : (
    ordersData !== null && ordersData.filter(order => order.order_status === "Inventory Added").length > 0 ? (
      <div className="order txt-light">
        <table cellPadding="0" cellSpacing="0" className="border table-mob">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Name</th>
              <th>Mobile</th>
              <th>Order Total</th>
              <th>Order Status</th>
              <th>Dispatch Due Date</th>
              <th></th>
              <th></th>
            </tr>
            {ordersData.map((order, i) =>
              order.order_status === "Inventory Added" && (
                <tr key={i}>
                  <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                  <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                  <td>{order.channel}</td>
                  <td>{order.tporderid}</td>
                  <td>{order.user.name}</td>
                  <td>{order.user.phone}</td>
                  <td><b>{order.totalprice}</b></td>
                  <td>{order.order_status}</td>
                  <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                  {accesslevels.find(item => (item.orders === "edit" && item.entity === order.entity) ) ? (<td><button onClick={() => updateorder(order.orderid,  "Shipped")}>Order Shipped</button></td>):(<td>You don`t Have Access</td>)}
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => orderChecklist(order.orderid)}>Check List</button></li>
                    </ul>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    ) : (
      <p className='txt-light'>No Inventory Added</p>
    )
  )}

  {limit !== 0 && ordersData !== null && ordersData.filter(order => order.order_status === "Inventory Added").length >= 20 && (
    <ul className='btns'>
      <li><button className='btn' onClick={() => limitHandler(false)}>Show More</button></li>
      <li><button className='btn' onClick={() => limitHandler(true)}>Show All</button></li>
    </ul>
  )}
</div>

<div className={"tab-panel" + (tabIndex === 4 ? " active" : "")}>
  {userDetails.access === "Super" ? (
    <div className="order txt-light">
      {ordersData && ordersData.filter(order => order.order_status === "Shipped").length > 0 ? (
        <table cellPadding="0" cellSpacing="0" className="border table_mob">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Details</th>
              <th>Order Total</th>
              <th></th>
              <th></th>
            </tr>
            {ordersData.filter(order => order.order_status === "Shipped").map((order, i) => (
              <tr key={i}>
                <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                <td>{order.channel}</td>
                <td>{order.tporderid}</td>
                <td>{order.user.name}</td>
                <td><b>{order.totalprice}</b></td>
                {accesslevels.find(item => item.orders === "edit" && item.entity === order.entity) ? (
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => updateorder(order.orderid,  "Delivered")}>Order Delivered</button></li>
                      <li><button onClick={() => updateorder(order.orderid,  "Cancelled")}>Cancel Order</button></li>
                    </ul>
                  </td>
                ):(<td>You Don`t Have Access</td>)}
                <td>
                  <ul className='btns'>
                    <li><button onClick={() => orderChecklist(order.orderid)}>Check List</button></li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Shipped products</p>
      )}
    </div>
  ) : (
    <div className="order txt-light">
      {ordersData && ordersData.filter(order => order.order_status === "Shipped").length > 0 ? (
        <table cellPadding="0" cellSpacing="0" className="border table-mob">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Name</th>
              <th>Mobile</th>
              <th>Order Total</th>
              <th>Order Status</th>
              <th>Dispatch Due Date</th>
              <th>Order Delivered</th>
              <th></th>
            </tr>
            {ordersData.map((order, i) =>
              order.order_status === "Shipped" && (
                <tr key={i}>
                  <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                  <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                  <td>{order.channel}</td>
                  <td>{order.tporderid}</td>
                  <td>{order.user.name}</td>
                  <td>{order.user.phone}</td>
                  <td><b>{order.totalprice}</b></td>
                  <td>{order.order_status}</td>
                  <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                  {accesslevels.find(item => item.orders === "edit" && item.entity === order.entity) ? (<td><button onClick={() => orderDelivered(order.orderid)}>Order Delivered</button></td>):(<td>You Don`t Have Access</td>)}
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => orderChecklist(order.orderid) }>Check List</button></li>
                    </ul>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <p>No Shipped products</p>
      )}
    </div>
  )}
</div>

<div className={"tab-panel" + (tabIndex === 5 ? " active" : "")}>
  {userDetails.access === "Super" ? (
    <div className="order txt-light">
      {ordersData && ordersData.filter(order => order.order_status === "Delivered").length > 0 ? (
        <table cellPadding="0" cellSpacing="0" className="border table_mob">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Details</th>
              <th>Order Total</th>
              <th></th>
            </tr>
            {ordersData.filter(order => order.order_status === "Delivered").map((order, i) => (
              <tr key={i}>
                <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                <td>{order.channel}</td>
                <td>{order.tporderid}</td>
                <td>{order.user.name}</td>
                <td><b>{order.totalprice}</b></td>
                {accesslevels.find(item => item.orders === "edit" && item.entity === order.entity)? (
                  <td>
                    <button onClick={() => updateorder(order.orderid,  "Cancelled")}>Cancel Order</button>
                  </td>
                ):(<td>You Don`t Have Access</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Delivered Products</p>
      )}
    </div>
  ) : (
    <div className="order txt-light">
      {ordersData && ordersData.filter(order => order.order_status === "Delivered").length > 0 ? (
        <table cellPadding="0" cellSpacing="0" className="border table-mob">
          <tbody>
            <tr>
              <th>Order Id</th>
              <th>Order Date</th>
              <th>Channel</th>
              <th>TP Order Id</th>
              <th>Customer Name</th>
              <th>Mobile</th>
              <th>Order Total</th>
              <th>Order Status</th>
              <th>Dispatch Due Date</th>
              <th></th>
            </tr>
            {ordersData.map((order, i) =>
              order.order_status === "Delivered" && (
                <tr key={i}>
                  <td><KuroLink to={'/tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                  <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                  <td>{order.channel}</td>
                  <td>{order.tporderid}</td>
                  <td>{order.user.name}</td>
                  <td>{order.user.phone}</td>
                  <td><b>{order.totalprice}</b></td>
                  <td>{order.order_status}</td>
                  <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                  <td>
                    <ul className='btns'>
                      <li><button onClick={() => orderChecklist(order.orderid)}>Check List</button></li>
                    </ul>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <p>No Delivered Products</p>
      )}
    </div>
  )}
</div>

                        {userDetails.access === "Super" && <div className={"tab-panel" + (tabIndex === 6 ? " active" : "")}>
                            <div className="order txt-light">
                                {ordersData !== null && ordersData.filter(order => order.order_status === "Cancelled").length > 0 ?
                                    <table cellPadding="0" cellSpacing="0" className="border table_mob">
                                        <tbody>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>Order Date</th>
                                                <th>Channel</th>
                                                <th>TP Order Id</th>
                                                <th>Customer Details</th>
                                                <th>Order Total</th>
                                            </tr>
                                            {ordersData.filter(order => order.order_status === "Cancelled").map((order, i) =>
                                                <tr key={i}>
                                                    <td><KuroLink to={'tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                    <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                                    <td>{order.channel}</td>
                                                    <td>{order.tporderid}</td>
                                                    <td>{order.user.name}</td>
                                                    <td><b>{order.totalprice}</b></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                :(<p>No Cancelled products</p>)}
                            </div>
                        </div>}

                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getStates })(TPOrders)