import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getStates } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import { useHistory } from "react-router-dom"

const Vendor = ({ match, user: { token, userDetails }, admin: { provinces, accesslevels }, getStates }) => {

    const { params: { vendorCode } } = match
    const [vendordata, setvendordata] = useState(null)
    const [updatedvendor, setupdatedvendor] = useState({})
    const [editflag, seteditflag] = useState(false)
    const [submitflag, setsubmitflag] = useState(false)
    const [errmsg, seterrmsg] = useState("")
    const selectAllRef = useRef(null)
    const [tagselectall, settagselectall] = useState(false)
    const history = useHistory()
    const vendorType = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Rent",
        "Expenses - Salaries",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Expenses - Petty Cash",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    const tagOptionsHandler = () => {
        const tagOptions = {
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Rent": ["Rent"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        }
        return tagOptions[updatedvendor.type] || []
    }

    useEffect(() => {
        const access = userDetails.accesslevel.vendor
        if (access === "NA") {
            history.push('/unauthorized')
            return
        }
        getStates()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/vendors?vendor_code=' + vendorCode, config).then(res => {
            setvendordata(res.data[0])
        })
    }, [editflag, vendorCode, getStates])

    useEffect(() => {
        if (selectAllRef.current && updatedvendor) {
            if (updatedvendor.tags.length === 0) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = false
            } else if (updatedvendor.tags.length === tagOptionsHandler(updatedvendor.type).length) {
                selectAllRef.current.indeterminate = false
              
                selectAllRef.current.checked = true
            } else {
                selectAllRef.current.indeterminate = true
            }
        }
    }, [updatedvendor.tags, updatedvendor.type])

    const toggleselectall = () => {
        let tempVendor = { ...updatedvendor }
        let selecttedTags = []
        if (tagselectall) {
            settagselectall(false)
            selecttedTags = []
        } else {
            tagOptionsHandler(tempVendor.type).map((tag) => {
                selecttedTags.push(tag)
            })
            settagselectall(true)
        }
        tempVendor.tags = selecttedTags
        setupdatedvendor({...tempVendor})
    }

    const editvendor = () => {
        setupdatedvendor(vendordata)
        seteditflag(true)
    }

    const updatesubmitflag = (vendor) => {
        let tempflag = true
        let tempmsg = ""
        if (vendor.name === "") {
            tempflag = false
            tempmsg = "*** Vendor Name should not be empty"
        } else if (vendor.regaddress.province === "") {
            tempflag = false
            tempmsg = "*** Please select the vendor state"
        } else if (vendor.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(vendor.pan)) {
            tempflag = false
            tempmsg = "*** Please Enter Valid PAN number"
        } else {
            for (let item of vendor.gstdetails) {
                if (item.gst.gstin.length !== 15 || item.gst.gstin.slice(2, 12) !== vendor.pan || !(/^[0-9]*$/).test(item.gst.gstin.slice(0, 2))) {
                    tempflag = false
                    tempmsg = "*** Please Enter Valid GSTIN number"
                }
            }
        }
        seterrmsg(tempmsg)
        setsubmitflag(tempflag)
    }

    const updatevendor = (e, key, subkey = "") => {
        let tempvendor = { ...updatedvendor }
        let data_typecheking = e.target.value
        if (key==="credit") {
            data_typecheking = Number(data_typecheking)
        }
        let value = data_typecheking
        if (key === "regaddress") {
            if (subkey === "city") {
                if ((/^[a-zA-Z\s]*$/).test(value)) {
                    tempvendor[key][subkey] = value
                }
            } else if (subkey === "pincode") {
                if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                    tempvendor[key][subkey] = value
                }
            } else {
                tempvendor[key][subkey] = value
            }
        } else {
            if (key === "pan") {
                if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                    tempvendor[key] = value.toUpperCase()
                }
            } else if (key === "name") {
                tempvendor[key] = value.toUpperCase()
            } else if (key === "tags") {
                tempvendor.tags.push(value)
            } else if(key==="type") {
                tempvendor[key] = value
                tempvendor.tags=[]
            } else {
                tempvendor[key] = value
            }
        }

        setupdatedvendor(tempvendor)
        updatesubmitflag(tempvendor)
    }

    const updatevendorgst = (e, i, key, subkey) => {
        let tempvendor = { ...updatedvendor }
        let value = e.target.value
        if (key === "gst") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempvendor.gstdetails[i][key][subkey] = value.toUpperCase()
            }
        } else {
            if (subkey === "city") {
                if ((/^[a-zA-Z\s]*$/).test(value)) {
                    tempvendor.gstdetails[i][key][subkey] = value
                }
            } else if (subkey === "pincode") {
                if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                    tempvendor.gstdetails[i][key][subkey] = value
                }
            } else {
                tempvendor.gstdetails[i][key][subkey] = value
            }
        }
        setupdatedvendor(tempvendor)
        updatesubmitflag(tempvendor)
    }

    const updatebankdetails = (e, key) => {
        let tempvendor = { ...updatedvendor }
        tempvendor[key] = e.target.value
        setupdatedvendor(tempvendor)
        updatesubmitflag(tempvendor)
    }

    const cancelupdates = () => {
        seteditflag(false)
        seterrmsg("")
    }

    const addgst = () => {
        setupdatedvendor({
            ...updatedvendor,
            gstdetails: [...updatedvendor.gstdetails, { 'gst': { 'gstin': '' }, 'address': { 'line1': '', 'line2': '', 'city': '', 'province': '', 'pincode': '' } }]
        })
    }

    const removegst = (index) => {
        let tempvendor = { ...updatedvendor }
        tempvendor.gstdetails = tempvendor.gstdetails.filter((_, i) => i !== index)
        setupdatedvendor(tempvendor)
        updatesubmitflag(tempvendor)
    }

    const submitvendor = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        updatedvendor.name = updatedvendor.name.trim()

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/vendors?vendor_code=' + updatedvendor["vendor_code"], updatedvendor, config).then(res => {
            setvendordata(res.data)
        })
        seteditflag(false)
    }

    return (
        <div className="vendor txt-light">
            {editflag ? (
                [updatedvendor !== null &&
                    <div className="edit-vendor">
                        <table className="border" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td><textarea className="large" name="name" onChange={(e) => updatevendor(e, "name")} value={updatedvendor.name} /></td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>
                                        <select value={updatedvendor.type}>
                                            <option value="">Select Vendor Type</option>
                                        {vendorType.map((type) =>
                                            <option value={type} onClick={(e) => updatevendor(e, "type")}>{type}</option>
                                        )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Type</td>
                                    <td>
                                        <select value={updatedvendor.payment_type} onChange={(e) => updatevendor(e, "payment_type")}>
                                            <option value="">Select Payment Type</option>
                                            <option value="Post Paid">Post Paid</option>
                                            <option value="Auto Paid">Auto Paid</option>
                                            <option value="Pre Paid">Pre Paid</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Credit</td>
                                    <td> <input className="creditinput" value={updatedvendor.credit} onChange={(e) => updatevendor(e, "credit")} />  Days</td>
                                </tr>
                                {(updatedvendor.type && tagOptionsHandler(updatedvendor.type).length > 0) && (
                                    <tr>
                                        <th>Tags</th>
                                        <td>
                                        {tagOptionsHandler(updatedvendor.type).length > 1 && (
                                            <div className="checkbox-container">
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        ref={selectAllRef}
                                                        checked={tagselectall}
                                                        onChange={toggleselectall}
                                                    />
                                                </span>
                                                <span>Select All</span>
                                            </div>
                                        )}
                                      
                                        {tagOptionsHandler(updatedvendor.type).map((tag) => (
                                            <div key={tag} className="checkbox-container-inner">
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        value={tag}
                                                        checked={updatedvendor.tags.includes(tag)}
                                                        onChange={(e) => updatevendor(e, "tags")}
                                                    />
                                                </span>
                                                <span>{tag}</span>
                                            </div>
                                        ))}
                                          </td>
                                    </tr>
                                )}

                                <tr>
                                    <td colSpan="2">Registered Address</td>
                                </tr>
                                <tr>
                                    <td>Address Line1</td>
                                    <td><textarea className="large" name="line1" onChange={(e) => updatevendor(e, "regaddress", "line1")} value={updatedvendor.regaddress.line1} /></td>
                                </tr>
                                <tr>
                                    <td>Address Line2</td>
                                    <td><textarea className="large" name="line2" onChange={(e) => updatevendor(e, "regaddress", "line2")} value={updatedvendor.regaddress.line2} /></td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td><input className="large" name="city" onChange={(e) => updatevendor(e, "regaddress", "city")} value={updatedvendor.regaddress.city} /></td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>
                                        <select className="large" name="province" onChange={(e) => updatevendor(e, "regaddress", "province")} value={updatedvendor.regaddress.province}>
                                            <option value="">Select State</option>
                                            {provinces.map((province, i) =>
                                                <option key={i} value={province["state"]}>{province["state"]}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pincode</td>
                                    <td><input className="large" name="pincode" onChange={(e) => updatevendor(e, "regaddress", "pincode")} value={updatedvendor.regaddress.pincode} /></td>
                                </tr>
                                <tr>
                                    <td>PAN</td>
                                    <td><input className="large" name="pan" onChange={(e) => updatevendor(e, "pan")} value={updatedvendor.pan} /></td>
                                </tr>
                                <tr>
                                    <td>TAN</td>
                                    <td><input className="large" name="tan" onChange={(e) => updatevendor(e, "tan")} value={updatedvendor.tan} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>Bank Details</h6>
                        <table className='border'>
                            <tbody>
                                <tr>
                                    <td>Beneficiary Name</td>
                                    <td><input className="large" onChange={(e) => updatebankdetails(e, "bfc_name")} value={updatedvendor.bfc_name} /></td>
                                </tr>
                                <tr>
                                    <td>Beneficiary Code</td>
                                    <td><input className="large" onChange={(e) => updatebankdetails(e, "bfc_code")} value={updatedvendor.bfc_code} /></td>
                                </tr>
                                <tr>
                                    <td>Bank Account No</td>
                                    <td><input className="large" onChange={(e) => updatebankdetails(e, "accountno")} value={updatedvendor.accountno} /></td>
                                </tr>
                                <tr>
                                    <td>IFSC Code</td>
                                    <td><input className="large" onChange={(e) => updatebankdetails(e, "ifsc_code")} value={updatedvendor.ifsc_code} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>GST Details</h6>
                        <table className='border'>
                            <tbody>
                                <tr>
                                    <td>GSTIN</td>
                                    <td>Address Line1</td>
                                    <td>Address Line2</td>
                                    <td>City</td>
                                    <td>State</td>
                                    <td>Pincode</td>
                                    <td></td>
                                </tr>
                                {updatedvendor.gstdetails.map((item, i) =>
                                    <tr key={i}>
                                        <td><input className="large" onChange={(e) => updatevendorgst(e, i, "gst", "gstin")} value={item.gst.gstin} /></td>
                                        <td><textarea className="large" name="line1" onChange={(e) => updatevendorgst(e, i, "address", "line1")} value={item.address.line1} /></td>
                                        <td><textarea className="large" name="line2" onChange={(e) => updatevendorgst(e, i, "address", "line2")} value={item.address.line2} /></td>
                                        <td><input className="large" name="city" onChange={(e) => updatevendorgst(e, i, "address", "city")} value={item.address.city} /></td>
                                        <td>
                                            <select className="large" name="province" onChange={(e) => updatevendorgst(e, i, "address", "province")} value={item.address.province}>
                                                <option value="">Select State</option>
                                                {provinces.map((province, i) =>
                                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td><input className="small" name="pincode" onChange={(e) => updatevendorgst(e, i, "address", "pincode")} value={item.address.pincode} /></td>
                                        <td>
                                            <span className="prod-span" onClick={() => removegst(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <button onClick={addgst}>Add Another GSTIN</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className="btns">
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                            <li>
                                {submitflag ? (
                                    <button onClick={submitvendor}>Submit</button>
                                ) : (
                                    <button style={{ background: "#87858e", color: "#000" }}>Submit</button>
                                )}
                            </li>
                            <p>{errmsg !== "" && errmsg}</p>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="vendors">
                    {accesslevels.find(item=>item.vendors === "edit") && <ul className="btns">
                        <li><button onClick={editvendor}>Edit the Vendor Details</button></li>
                    </ul>}

                    <table cellPadding="0" cellSpacing="0" className="border">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Credit</th>
                                <th>PAN</th>
                                <th>TAN</th>
                                <th>Registered Address</th>
                            </tr>
                            {vendordata !== null &&
                                <tr>
                                    <td>{vendordata.name}</td>
                                    <td>{vendordata.type}</td>
                                    <td>{vendordata.credit !==0 ?(vendordata.credit+" Days"):("No Credit")}</td>
                                    <td>{vendordata.pan}</td>
                                    <td>{vendordata.tan}</td>
                                    <td>
                                        {vendordata.regaddress.line1}<br />
                                        {vendordata.regaddress.line2}<br />
                                        {vendordata.regaddress.city}<br />
                                        {vendordata.regaddress.province}<br />
                                        {vendordata.regaddress.pincode}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <table className='border'>
                        <tbody>
                            <tr>
                                <th>Beneficiary Name</th>
                                <th>Beneficiary Code</th>
                                <th>Bank Account No</th>
                                <th>IFSC Code</th>
                            </tr>
                            {vendordata !== null &&
                                <tr>
                                    <td>{vendordata.bfc_name}</td>
                                    <td>{vendordata.bfc_code}</td>
                                    <td>{vendordata.accountno}</td>
                                    <td>{vendordata.ifsc_code}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <table cellPadding="0" cellSpacing="0" className="border">
                        <tbody>
                            <tr>
                                <td>GSTIN</td>
                                <td>GST Address</td>
                            </tr>
                            {vendordata !== null && vendordata.gstdetails.map((item, j) =>
                                <tr key={j}>
                                    <td>
                                        {item.gst.gstin}
                                    </td>
                                    <td>
                                        {item.address.line1 && <>{item.address.line1}<br /></>}
                                        {item.address.line2 && <>{item.address.line2}<br /></>}
                                        {item.address.city}<br />
                                        {item.address.province}<br />
                                        {item.address.pincode}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className='btns col_2'>
                        <KuroLink to="/vendors"><button>Back</button></KuroLink>
                    </div>
                    <p>&nbsp;</p>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getStates })(Vendor)