import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getVendors } from '../actions/admin';
import moment from 'moment-timezone';
import KuroLink from '../components/common/KuroLink';
import note from '../assets/img/button.png';
import '../styles/response.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const Editbatch = ({ match, user: { token, userDetails }, admin: { vendors, accesslevels }, getVendors }) => {
    const { params: { batchid } } = match;
    const [batch, setBatch] = useState(null);
    const [selectedIndents, setSelectedIndents] = useState([]);
const history=useHistory();
const access= accesslevels.find(item => item.indent === "edit");
useEffect(()=>{
    if(access===undefined || access.length <= 0){
        history.push("/unauthorized")
    }
})
    useEffect(() => {
        const fetchBatch = async () => {
            try {
                getVendors();
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    },
                };
                let url = process.env.REACT_APP_KC_API_URL + 'kurostaff/indent?batchid=' + batchid;
                const response = await axios.get(url, config);
                setBatch(response.data[0]);
            } catch (error) {
                console.error("API error:", error);
                // Handle error state or show an error message to the user
            }
        };

        if (batchid && token) {
            fetchBatch();
        }
    }, [batchid, token, getVendors]);

        const updateIndent = (e, indentId) => {
            const isChecked = e.target.checked;
            if (isChecked) {
                setSelectedIndents(prevIndents => [...prevIndents, indentId]);
            } else {
                setSelectedIndents(prevIndents => prevIndents.filter(id => id !== indentId));
            }
        };

        const updateHandler = () => {
            const url = process.env.REACT_APP_KC_API_URL + 'kurostaff/indent?batchid=' + batchid 
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            };
            axios.post(url, selectedIndents, config).then(
                (response) => {
                    setBatch(response.data[0])
                    setSelectedIndents([])
                }
                
            )
        }
    const handleBack=(()=>{
        history.push("/indent-list")
    })

    return (
        <div className='txt-light-grey'>
            {batch && batch !== null ?
                <>
                    <table className="border table_mob" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Batchid</th>
                                <th>Vendor Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{batch.batchid}</td>
                                <td>{batch.vendor}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Products</h3>
                    <table className="border table_mob" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Indent Id</th>
                                <th>Title</th>
                                <th>qty</th>
                                <th>price</th>
                                <th>total</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {batch.products.map((data, index) => (
                                <tr key={data.indentid}>
                                    <td>{data.indentid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.price}</td>
                                    <td>{data.totalprice}</td>
                                    <td><input type="checkbox" onChange={(e) => updateIndent(e, data.indentid)} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
<ul className='btns'>
    <li>   <button onClick={updateHandler}>UPDATE</button></li>
    <li><button onClick={handleBack}>Back</button></li>
</ul>
                 
                    
                </> : <></>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
});

export default connect(mapStateToProps, { getVendors })(Editbatch);
