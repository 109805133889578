import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'
import { mapData } from '../data/mappings'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import '../styles/react-select.css'

moment.tz.setDefault('Asia/Kolkata')

const EstimateOrder = ({ match, user: {token}, admin: { provinces, accesslevels }, products: { prodData }}) => {

    let history = useHistory()
    const { params: { estimate_no, version } } = match
    const [estimatedata, setestimatedata] = useState(null)
    const [orderdata, setorderdata] = useState(null)
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })

    const access = accesslevels.find(item => (item.offline_orders === "write" || item.offline_orders === "edit" ));

    if(access === undefined ||access?.offline_orders==="NA" || access?.offline_orders==="read"){
        history.push("/unauthorized");
    }
   

    const defbuild = {
        "title": "",
        "components": [
            {
                "collection": "components",
                "type": "cpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "mob",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "gpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ram",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ssd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "hdd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "psu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "tower",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "cooler",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "fan",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "networking",
                "type": "wifi-card",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "os",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "warranty",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            }
        ],
        "hsncode": "",
        "tax_rate": 18,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "price": 0,
        "pricebgst": 0,
        "quantity": 1,
        "totalprice": 0,
        "totalpricebgst": 0
    }

    useEffect(() => {
        if (access==="NA" || access==="read") {
            history.push("/unauthorized");
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no + '&version=' + version, config).then(res => {
            setestimatedata(res.data[0])
        })
    }, [])

    useEffect(() => {
        if (estimatedata !== null) {
            let temporder = {...estimatedata}
            temporder["po_ref"] = estimate_no
            if (temporder.builds.length > 0) {
                for (let build of temporder.builds) {
                    build.components = []
                    for (let preset of build.presets) {
                        if (preset.title !== "") {
                            if (preset.type === "wifi") {
                                build.components.push({
                                    "collection": "networking",
                                    "type": "wifi-card",
                                    "maker": "",
                                    "productid": "",
                                    "title": preset.title,
                                    "quantity": 1
                                })
                            } else if (preset.type !== "shp_fees" && preset.type !== "build_fees" && preset.type !== "margin") {
                                build.components.push({
                                    "collection": "components",
                                    "type": preset.type,
                                    "maker": "",
                                    "productid": "",
                                    "title": preset.title,
                                    "quantity": 1
                                })
                            }
                        }
                    }
                    delete build.presets
                    delete build.subtotal
                }
            }
            delete temporder.terms
            delete temporder.order_confirmed
            delete temporder.estimate_date
            delete temporder.validity
            delete temporder.type
            temporder["order_date"] = new Date()
            temporder["dispatchby_date"] = new Date()
            setorderdata(temporder)
        }
    }, [estimatedata])

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (temporder) => {
        let roundoff = 0
        if (temporder.billadd.state === "Telangana") {
            roundoff = ((temporder.totalprice * 100) - (200 * temporder.cgst) - (100 * temporder.totalpricebgst))
        } else {
            roundoff = ((temporder.totalprice * 100) - (100 * temporder.gst) - (100 * temporder.totalpricebgst))
        }
        temporder.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return temporder
    }

    const updatetotal = (temporder) => {
        temporder.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i=0; i<temporder.builds.length; i++) {
            totaldiscount += temporder.builds[i].totaldiscount
            subtotals[temporder.builds[i].tax_rate] += temporder.builds[i].totalprice
            totalprice += temporder.builds[i].totalprice
        }
        for (let i=0; i<temporder.products.length; i++) {
            totaldiscount += temporder.products[i].totaldiscount
            subtotals[temporder.products[i].tax_rate] += temporder.products[i].totalprice
            totalprice += temporder.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (temporder.billadd.state === "Telangana") {
                temporder.taxes[key] = trcgst
            } else {
                temporder.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        temporder.totaldiscount = totaldiscount
        temporder.totalprice = totalprice
        temporder.totalpricebgst = round("round", totalpricebgst, 2)
        temporder.gst = round("round", gst, 2)
        temporder.cgst = round("round", cgst, 2)

        temporder = updateroundoff(temporder)
        return temporder
    }

    const updateorder = (key, e) => {
        let temporder = {...orderdata}
        temporder[key] = e.target.value
        setorderdata(temporder)
    }

    const updateorderdate = (key, date) => {
        let temporder = {...orderdata}
        temporder[key] = (date === null) ? "" : date
        setorderdata(temporder)
    }

    const updateuser = (key, e) => {
        let temporder = {...orderdata}
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        temporder.user[key] = value
        temporder.billadd[key] = value
        setorderdata(temporder)
    }

    const updatepangstin = (order) => {
        let tempmsg = {...errmsg}
        if (order.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(order.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (order.billadd.gstin !== "" && (order.billadd.gstin.length !== 15 || order.billadd.gstin.slice(2,12) !== order.billadd.pan || !(/^[0-9]*$/).test(order.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let temporder = {...orderdata}
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({...msg, state: "Please select the State"}))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            temporder[key][subkey] = value
            temporder = updatetotal(temporder)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                temporder[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                temporder[key][subkey] = value.toUpperCase()
            }
            updatepangstin(temporder)
        } else {
            temporder[key][subkey] = value
        }
        setorderdata(temporder)
    }

    const updateaddressflag = (value) => {
        let temporder = {...orderdata}
        temporder["addressflag"] = value
        if (value) {
            delete temporder["shpadd"]
        } else {
            temporder["shpadd"] = {'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': ''}
        }
        setorderdata(temporder)
    }

    const updatenotes = (key, e) => {
        let temporder = {...orderdata}
        temporder.notes[key] = e.target.value
        setorderdata(temporder)
    }

    const addbuild = (bindex) => {
        let temporder = {...orderdata}
        temporder.builds.splice(bindex+1, 0, defbuild)
        setorderdata(temporder)
    }

    const removebuild = (bindex) => {
        let temporder = {...orderdata}
        temporder.builds.splice(bindex, 1)
        temporder = updatetotal(temporder)
        setorderdata(temporder)
    }

    const updatebuild = (bindex, key, e, num=false) => {
        let temporder = {...orderdata}
        temporder.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            temporder.builds[bindex].discount = 0
            temporder.builds[bindex].totaldiscount = 0
            let totalprice = parseFloat(temporder.builds[bindex].price) * temporder.builds[bindex].quantity
            temporder.builds[bindex].pricebgst = round("round", parseFloat(temporder.builds[bindex].price) / 1.18, 2)
            temporder.builds[bindex].totalprice = totalprice
            temporder.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            temporder = updatetotal(temporder)
        }
        setorderdata(temporder)
    }

    const addcomp = (bindex, cindex) => {
        let temporder = {...orderdata}
        temporder.builds[bindex].components.splice(cindex + 1, 0, { "collection": "components", "type": "", "maker": "", "productid": "", "title": "", "quantity": 1 })
        setorderdata(temporder)
    }

    const removecomp = (bindex, cindex) => {
        let temporder = {...orderdata}
        temporder.builds[bindex].components.splice(cindex, 1)
        setorderdata(temporder)
    }

    const updatecomp = (bindex, cindex, key, e, num=false) => {
        let temporder = {...orderdata}
        temporder.builds[bindex].components[cindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "type" || key === "maker") {
            temporder.builds[bindex].components[cindex].productid = ""
        }
        setorderdata(temporder)
    }

    const updatecompmodel = (bindex, cindex, e) => {
        let temporder = {...orderdata}
        temporder.builds[bindex].components[cindex].productid = e.value
        setorderdata(temporder)
    }

    const addprod = (pindex) => {
        let temporder = {...orderdata}
        temporder.products.splice(pindex+1, 0, {'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18})
        setorderdata(temporder)
    }

    const removeprod = (pindex) => {
        let temporder = {...orderdata}
        temporder.products.splice(pindex, 1)
        temporder = updatetotal(temporder)
        setorderdata(temporder)
    }

    const prodtotal = (temporder, pindex) => {
        if (temporder.products.length > 0) {
            const quantity = temporder.products[pindex].quantity
            const price = temporder.products[pindex].prodprice - temporder.products[pindex].discount
            const totalprice = price * quantity
            temporder.products[pindex].pricebgst = round("round", price / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].price = price
            temporder.products[pindex].totalpricebgst = round("round", totalprice / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].totalprice = totalprice
            temporder = updatetotal(temporder)
        }
        return temporder
    }

    const getoptionlabel = (pindex, key, value) => {
        let prod = orderdata.products[pindex]
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (pindex, key, e, num=false) => {
        let temporder = {...orderdata}
        temporder.products[pindex].discount = 0
        temporder.products[pindex].totaldiscount = 0
        temporder.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" && key === "type" && key === "category" && key === "maker") {
            temporder.products[pindex].productid = ""
            temporder.products[pindex].title = ""
            temporder.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            temporder = prodtotal(temporder, pindex)
        }
        setorderdata(temporder)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let temporder = { ...orderdata }
        temporder.products[pindex].productid = prodid
        temporder.products[pindex].discount = 0
        temporder.products[pindex].totaldiscount = 0

        if (e) {
            temporder.products[pindex].title = (prodid === "other") ? "" : prodData[collection].filter(item => item.productid === prodid)[0].title
            temporder.products[pindex].prodprice = (prodid === "other") ? 0 : prodData[collection].filter(item => item.productid === prodid)[0].price.kuro_on
        } else {
            temporder.products[pindex].title = ''
            temporder.products[pindex].productid = ''
            temporder.products[pindex].prodprice = 0
        }
        temporder = prodtotal(temporder, pindex)
        setorderdata(temporder)
    }

    const updateproddiscount = (pindex, e) => {
        let temporder = {...orderdata}
        temporder.products[pindex].discount = Number(e.target.value)
        temporder.products[pindex].totaldiscount = temporder.products[pindex].discount * temporder.products[pindex].quantity
        temporder = prodtotal(temporder, pindex)
        setorderdata(temporder)
    }

    const prodOptions = (prod) => {
        let options = []
        if (prodData && prod.collection && prod.type) {
            options = prodData[prod.collection].filter(_ => _.type === prod.type && _.maker === prod.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
        }
        return options
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitOrder = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        if (orderdata.billadd.state !== "") {
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders', orderdata, config)
            history.push("/offlineorders")
        }
    }

    const cancelupdates = () => {
        setorderdata(orderdata)
        history.push("/estimates")
    }

    return (
        <div className="hp20 order-creation txt-light-grey">
            {orderdata !== null &&
            <div className="order-wrap">
                <h2>Kuro Gaming Order</h2>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Channel</td>
                            <td>Kuro Gaming Offline</td>
                        </tr>
                        <tr>
                            <td>PO/Ref</td>
                            <td><input className="large" value={orderdata.po_ref} onChange={(e) => updateorder("po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Order Date</td>
                            <td>
                                <DatePicker selected={orderdata.order_date === "" ? new Date() : new Date(orderdata.order_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("order_date", date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Order Total</td>
                            <td>{orderdata.totalprice}</td>
                        </tr>
                        <tr>
                            <td>Dispatch Due Date</td>
                            <td>
                                <DatePicker selected={orderdata.dispatchby_date === "" ? new Date() : new Date(orderdata.dispatchby_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updateorderdate("dispatchby_date", date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={orderdata.user.name} onChange={(e) => updateuser("name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={orderdata.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                        </tr>
                         <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={orderdata.billadd.company !== null ? orderdata.billadd.company : ''} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={orderdata.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={orderdata.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={orderdata.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={orderdata.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={orderdata.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={orderdata.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={orderdata.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={orderdata.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={orderdata.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                Shipping Address same as Billing Address 
                                <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!orderdata.addressflag)} checked={orderdata.addressflag} />
                            </td>
                        </tr>
                        {!orderdata.addressflag &&
                        <>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={orderdata.shpadd.company !== null ? orderdata.shpadd.company : ''} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={orderdata.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={orderdata.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={orderdata.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={orderdata.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={orderdata.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={orderdata.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={orderdata.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province["state"]}>{province["state"]}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={orderdata.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={orderdata.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                        </>
                        }
                    </tbody>
                </table>
                {Object.keys(errmsg).map((err) =>
                    <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                )}
                <table className="border" cellPadding="0" cellSpacing="0">
                {orderdata !== null &&
                    <tbody>
                        <tr>
                            <td>Customer</td>
                            <td>
                                <textarea className="medium" type="text" value={orderdata.notes.customer} onChange={(e) => updatenotes("customer", e)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Admin</td> 
                            <td>
                                <textarea className="medium" type="text" value={orderdata.notes.admin} onChange={(e) => updatenotes("admin", e)} />
                            </td>
                        </tr>
                    </tbody>
                }
                </table>
                <div className="products">
                    <div className='title_wrap col_mob'>
                        <p>Builds</p>
                        <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                    </div>
                    {orderdata.builds && orderdata.builds.map((build, i) =>
                    <table key={i} className="build border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td colSpan="4"><textarea className="x-large" value={build.title} onChange={(e) => updatebuild(i, "title", e, false)} /></td>
                                <td>
                                    <span className="prod-span" onClick={() => removebuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => addbuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                </td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td><input type="number" className="large" onWheel={disableScroll} value={build.price} onChange={(e) => updatebuild(i, "price", e, true)} /></td>
                                <td>Build Quantity</td>
                                <td><input type="number" onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(i, "quantity", e, true)} /></td>
                                <td>Quantity</td>
                                <td><span className="prod-span" onClick={() => addcomp(i, -1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span></td>
                            </tr>
                            {build.components.map((comp, j) =>
                            <tr key={j}>
                            {comp.type === "os" || comp.type === "warranty" ? (
                                <>
                                <td colSpan="3">{comp.type === "os" ? ("Operating System") : ("Warranty")}</td>
                                <td>
                                    <textarea value={comp.title} onChange={(e) => updatecomp(i, j, "title", e)} />
                                </td>
                                <td>&nbsp;</td>
                                </>
                            ) : (
                                <>
                                <td>
                                    {comp.collection === "components" &&
                                    <select className="large" value={comp.type} onChange={(e) => updatecomp(i, j, "type", e)}>
                                        <option value="">Select Type</option>
                                        <option value="cpu">Processor</option>
                                        <option value="mob">Motherboard</option>
                                        <option value="ram">RAM</option>
                                        <option value="gpu">Graphics Card</option>
                                        <option value="psu">Power Supply</option>
                                        <option value="ssd">SSD</option>
                                        <option value="hdd">HDD</option>
                                        <option value="cooler">CPU Cooler</option>
                                        <option value="tower">Case</option>
                                        <option value="fan">Fan</option>
                                        <option value="other">Other</option>
                                    </select>
                                    }
                                    {comp.collection === "networking" && "WiFi Card"}
                                </td>
                                <td>
                                    <select className='large' value={comp.maker} onChange={(e) => updatecomp(i, "maker", e)}>
                                        <option value="">Select Brand</option>
                                        {comp.collection && comp.type !== "other" && prodData &&
                                            [...new Set(prodData[comp.collection].filter(_ => _.type === comp.type).map(item => item.maker))].sort().map((maker, j) =>
                                            <option key={j} value={maker}>{maker}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td>
                                    {comp.type !== "other" &&
                                    <Select classNamePrefix="kuro-search-select" options={prodOptions(comp)} defaultValue={{ value: comp.productid, label: comp.title }} onChange={(e) => updatecompmodel(i, j, e)} placeholder="Select Component" className="react-select-container" />
                                    }
                                </td>
                                <td>
                                    <textarea value={comp.title} onChange={(e) => updatecomp(i, j, "title", e)} />
                                </td>
                                <td>
                                    <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatecomp(i, j, "quantity", e, true)} />
                                </td>
                                </>
                                )}
                                <td>
                                    <span className="prod-span" onClick={() => removecomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => addcomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    )}
                    <h3>Peripherals</h3>
                    <ul className='btns'>
                        <li>
                            <button onClick={() => addprod(-1)}>Add Peripherals [+]</button>
                        </li>
                    </ul>
                    {orderdata.products && orderdata.products.length > 0 &&
                    <table className="border" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td>Collection</td>
                                <td>Type</td>
                                <td>Category</td>
                                <td>Brand</td>
                                <td>Model</td>
                                <td>Title</td>
                                <td>Price</td>
                                <td>Tax Rate</td>
                                <td>Quantity</td>
                                <td>Discount<br/>(Per Unit)</td>
                                <td>Total Price</td>
                                <td></td>
                            </tr>
                            {orderdata.products.map((prod, i) =>
                            <tr key={i}>
                                <td>
                                    <select className='small' value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                        <option value="">Select Collection</option>
                                        {prodData && Object.keys(prodData).sort().map((coll, j) =>
                                            <option key={j} value={coll}>{getoptionlabel(i, "collection", coll)}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select className='small' value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                        <option value="">Select Type</option>
                                        {prod.collection && prodData &&
                                            [...new Set(prodData[prod.collection].map(_ => _.type))].sort().map((type, j) =>
                                            <option key={j} value={type}>{getoptionlabel(i, "type", type)}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                        <option value="">Select Category</option>
                                    {prod.collection && prod.type && prodData &&
                                        [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(_ => _.category))].sort().map((cat, j) =>
                                            cat !== "" &&
                                        <option key={j} value={cat}>{getoptionlabel(i, "category", cat)}</option>
                                        )
                                    }
                                    </select>
                                </td>
                                <td>
                                    <select className='small' value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                        <option value="">Select Brand</option>
                                        {prod.collection && prod.type && prodData &&
                                            [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(item => item.maker))].sort().map((maker, j) =>
                                            <option key={j} value={maker}>{maker}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                                </td>
                                <td>
                                    <textarea value={prod.title} onChange={(e) => updateprod(i, "title", e)} />
                                </td>
                                <td>
                                    <input type="number" className="small" onWheel={disableScroll} value={prod.prodprice} onChange={(e) => updateprod(i, "prodprice", e, true)} />
                                </td>
                                <td>
                                    <select value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                        <option value={5}>5</option>
                                        <option value={12}>12</option>
                                        <option value={18}>18</option>
                                        <option value={28}>28</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" className="small" onWheel={disableScroll} value={prod.quantity} min="1" max="100" onChange={(e) => updateprod(i, "quantity", e, true)} />
                                </td>
                                <td>
                                    <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} />
                                </td>
                                <td>
                                    {prod.totalprice}
                                </td>
                                <td>
                                    <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                    <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    }
                    <table className="border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Taxable Amount</td>
                                <td>{orderdata.totalpricebgst}</td>
                            </tr>
                            {orderdata.billadd.state === "Telangana" ? (
                            <>
                            <tr>
                                <td>SGST</td>
                                <td>{orderdata.cgst}</td>
                            </tr>
                            <tr>
                                <td>CGST</td>
                                <td>{orderdata.cgst}</td>
                            </tr>
                            </>
                            ) : (
                            <tr>
                                <td>IGST</td>
                                <td>{orderdata.gst}</td>
                            </tr>
                            )}
                            <tr>
                                <td>Round Off</td>
                                <td>{orderdata.roundoff}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{orderdata.totalprice}</td>
                            </tr>
                            <tr>
                                <td>Total Discount</td>
                                <td>{orderdata.totaldiscount}</td>
                            </tr>
                        </tbody>
                    </table>
                    <ul className='btns'>
                        <li>
                            <button onClick={cancelupdates}>Cancel</button>
                        </li>
                        <li>
                            <button onClick={submitOrder}>Submit Order</button>
                        </li>
                    </ul>
                </div>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(EstimateOrder)