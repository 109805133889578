import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import axios from "axios"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const EmployeeAccessLevel = ({ user: { token }, admin: { employees, accesslevels } }) => {
    const [empaccesses, setempaccesses] = useState(null)
    const access = accesslevels.find(item => item.employee_accesslevel !== "NA")
    const hasEditAccess = accesslevels.find(item => item.employee_accesslevel === "edit")
    const [tempAccess, setTempAccess] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState({ visibility: false, emp: null })
    const history = useHistory()

    useEffect(() => {
        if (accesslevels.length > 0 && !access) {
            history.push('/unauthorized')
            return
        }
    }, [accesslevels])

    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'accesslevel', config).then(res => {
            setempaccesses(res.data)
        })
    }, [])

    const handleButtonClick = (empid) => {
        let emp_accesses = {}
        if (empaccesses !== null) {
            let empaccess = empaccesses.filter(_ => _.userid === empid)
            empaccess.forEach((entaccess) => {
                emp_accesses[entaccess.entity] = { // Ensure entity is created
                    read: [],
                    write: [],
                    edit: [],
                }
                Object.keys(entaccess).forEach((key) => {
                    if (entaccess[key === "read"] || entaccess[key] === "write" || entaccess[key] === "edit") {
                        emp_accesses[entaccess.entity][entaccess[key]].push(key)
                    }
                })
            })
        }

        setTempAccess(emp_accesses)

        console.log(emp_accesses)

        setIsModalOpen({
            visibility: true,
            emp: emp_accesses,
        })

        // // Update state and open modal
        // setTempAccess(tempAccessLevels)
        // setIsModalOpen({
        //     visibility: true,
        //     emp: tempAccessLevels,
        // })
    }

    const handleDropDownClose = () => {
        setIsModalOpen({ visibility: false, emp: null })
        setTempAccess(null)
    }

    return (
        <div>
            <h2 className="txt-light-grey">Employee Access Level</h2>
            <table style={{ color: "white" }} className="border table_mob">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Access Level</th>
                        {hasEditAccess && <th>Manage Access</th>}
                    </tr>
                </thead>
                <tbody>
                {employees !== null && employees.map((emp, i) =>
                    <tr key={i}>
                        <td>{emp.userid}</td>
                        <td>{emp.name}</td>
                        <td><button onClick={() => handleButtonClick(emp.userid)} className="button_mob">Show Access</button></td>
                        <td>
                            {hasEditAccess && <Link to={`/employee-accesslevel/${emp.userid}`}>Manage Access</Link>}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <Modal
                isOpen={isModalOpen.visibility}
                onRequestClose={handleDropDownClose}
                contentLabel="Show Access"
                className="modal"
                overlayClassName="overlay"
                style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                }}
            >
            {tempAccess ? (
          <>
            <h5>Access Levels</h5>
            <div className="list-container">
                {Object.keys(tempAccess).map((key, i) =>
                <table className="border" key={i}>
                    <tbody>
                        <tr>
                            <td colSpan="3">{key}</td>
                        </tr>
                        <tr>
                            <td>Read Only</td>
                            <td>Read/Write</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>
                            {tempAccess[key]["read"].length > 0 ? (
                                tempAccess[key]["read"].map((item, j) => 
                                    <>
                                    {item}<br/>
                                    </>
                                )
                            ): (
                                "NA"
                            )}
                            </td>
                            <td>
                            {tempAccess[key]["write"].length > 0 ? (
                                tempAccess[key]["write"].map((item, j) => 
                                    <>
                                    {item}<br/>
                                    </>
                                )
                            ): (
                                "NA"
                            )}
                            </td>
                            <td>
                            {tempAccess[key]["edit"].length > 0 ? (
                                tempAccess[key]["edit"].map((item, j) => 
                                    <>
                                    {item}<br/>
                                    </>
                                )
                            ): (
                                "NA"
                            )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                )}
              {/* {Object.entries(tempAccess).map(([entity, permissions]) => (
                <div key={entity}>
                  <h2>{entity}</h2>
                  {['read', 'write', 'edit'].map((key) => (
                    <div key={key}>
                      <strong>{key}:</strong> {permissions[key].length > 0 ? permissions[key].join(', ') : 'No Access'}
                    </div>
                  ))}
                </div>
              ))} */}
            </div>
          </>
        ) : (
          <p className="text-center">No Access</p>
        )}
        <button className="arrow" onClick={handleDropDownClose}>
          Close
        </button>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(EmployeeAccessLevel)