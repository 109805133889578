import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './components/common/Routes'
import Layout from './components/common/Layout'
import { Provider } from 'react-redux'
// import { loadUser } from './actions/user'
import store from './store'
import './App.css'
import withClearCache from "./ClearCache"
import { updateBGResponse } from './actions/admin'

const MainApp = () => {
    useEffect(() => {
        // store.dispatch(loadUser()) *** already called inside the updateBGResponse function
        store.dispatch(updateBGResponse())
    }, [])

    return (
        <Provider store={store}>
            <Router>
                <Layout>
                    <Routes />
                </Layout>
            </Router>
        </Provider>
    )
}

const ClearCacheComponent = withClearCache(MainApp)

function App() {
    return <ClearCacheComponent />
}

export default App