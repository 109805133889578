import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { updateBGResponse } from "../actions/admin"; // Import the action
import store from "../store";

const Switchgroup = ({ user: { token, userDetails }, updateBGResponse }) => {
    const [bgDetails, setBgDetails] = useState(null);
    const history = useHistory();

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_KC_API_URL}bggroup`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
        ).then((response) => {
            setBgDetails(response.data)
        })
    }, [token])

    const updateBG = (group_name) => {
        localStorage.setItem('bg-code', group_name)
        updateBGResponse()
        sessionStorage.getItem("path") ? history.push(sessionStorage.getItem("path")) : history.push("/");
    }


    return (
        <div className="txt-light-grey">
            <table
                className="even_odd border txt-light-grey table_mob"
                cellPadding="0"
                cellSpacing="0"
            >
                <thead>
                    <tr>
                        <th>Business Group Name</th>
                    </tr>
                </thead>
                <tbody>
                    {userDetails?.businessgroups?.map((item, i) => (
                        <tr key={i}>
                            <td>
                                <button onClick={() => updateBG(item)}>
                                    {bgDetails &&
                                        bgDetails.find((bg) => bg.bg_code === item)?.bg_name}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, { updateBGResponse })(Switchgroup);
