import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import { mapData } from '../data/mappings'
import '../styles/react-select.css'

const GenerateInvoice = ({ user: {token}, products: { prodData, presetData }, admin: { provinces, accesslevels, bgDetails }}) => {

    let history = useHistory()
    const access = accesslevels.find(item => item.outward_invoices !== "NA")
    const definvoice = {
        'builds': [],
        'products': [],
        'services': [],
        'totalpricebgst': 0,
        'entity': accesslevels.find(item => item.outward_invoices !== "NA" && item.outward_invoices !== "read").entity,
        'gst': 0,
        'cgst': 0,
        'totalprice': 0,
        'roundoff': 0,
        'taxes': {
            '5': 0,
            '12': 0,
            '18': 0,
            '28': 0
        },
        'user': {
            'name': '',
            'phone': ''
        },
        'billadd': {
            'company': '',
            'name': '',
            'phone': '',
            'addressline1': '',
            'addressline2': '',
            'city': '',
            'pincode': '',
            'state': '',
            'gstin': '',
            'pan': ''
        },
        'addressflag': true,
        'invoice_date': '',
        'orderid': '',
        'po_ref': '',
        'create_order': true
    }

    const defbuild = {
        'title': 'Kuro Custom PC',
        'components': [
            {
                "collection": "components",
                "type": "cpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "mob",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ram",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "gpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "psu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ssd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "hdd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "cooler",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "tower",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "fan",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "networking",
                "type": "wifi",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "os",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            }
        ],
        "buildType": "custom",
        "hsncode": "",
        "tax_rate": 18,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "pricebgst": 0,
        "price": 0,
        "quantity": 1,
        "totalpricebgst": 0,
        "totalprice": 0
    }

    const [invoicedata, setinvoicedata] = useState(definvoice)
    const [preview, setpreview] = useState(false)
    const [errmsg, seterrmsg] = useState({
        "state": "",
        "pan": "",
        "gstin": ""
    })

    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return
        }
    }, [])

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempinvoice) => {
        let roundoff = 0
        if (tempinvoice.billadd.state === "Telangana") {
            roundoff = ((tempinvoice.totalprice * 100) - (200 * tempinvoice.cgst) - (100 * tempinvoice.totalpricebgst))
        } else {
            roundoff = ((tempinvoice.totalprice * 100) - (100 * tempinvoice.gst) - (100 * tempinvoice.totalpricebgst))
        }
        tempinvoice.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return tempinvoice
    }

    const updatetotal = (tempinvoice) => {
        tempinvoice.taxes = {}
        let totalprice = 0
        let totaldiscount = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }

        for (let i=0; i<tempinvoice.builds.length; i++) {
            totaldiscount += tempinvoice.builds[i].totaldiscount
            subtotals[tempinvoice.builds[i].tax_rate] += tempinvoice.builds[i].totalprice
            totalprice += tempinvoice.builds[i].totalprice
        }
        for (let i=0; i<tempinvoice.products.length; i++) {
            totaldiscount += tempinvoice.products[i].totaldiscount
            subtotals[tempinvoice.products[i].tax_rate] += tempinvoice.products[i].totalprice
            totalprice += tempinvoice.products[i].totalprice
        }
        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (tempinvoice.billadd.state === "Telangana") {
                tempinvoice.taxes[key] = trcgst
            } else {
                tempinvoice.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        tempinvoice.totaldiscount = totaldiscount
        tempinvoice.totalprice = totalprice
        tempinvoice.totalpricebgst = round("round", totalpricebgst, 2)
        tempinvoice.gst = round("round", gst, 2)
        tempinvoice.cgst = round("round", cgst, 2)

        tempinvoice = updateroundoff(tempinvoice)
        return tempinvoice
    }

    const updateinvoice = (key, e) => {
        let tempinvoice = {...invoicedata}
        tempinvoice[key] = e.target.value
        setinvoicedata(tempinvoice)
    }

    const updateinvoicedate = (date) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.invoice_date = (date === null) ? "" : date
        setinvoicedata(tempinvoice)
    }

    const updateuser = (key, e) => {
        let tempinvoice = {...invoicedata}
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempinvoice.user[key] = value
        tempinvoice.billadd[key] = value
        setinvoicedata(tempinvoice)
    }

    const updatepangstin = (tempinvoice) => {
        let tempmsg = {...errmsg}
        if (tempinvoice.billadd.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(tempinvoice.billadd.pan)) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (tempinvoice.billadd.gstin !== "" && (tempinvoice.billadd.gstin.length !== 15 || tempinvoice.billadd.gstin.slice(2,12) !== tempinvoice.billadd.pan || !(/^[0-9]*$/).test(tempinvoice.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let tempinvoice = {...invoicedata}
        if (key === "billadd" && subkey === "state") {
            if (value === "") {
                seterrmsg(msg => ({...msg, state: "Please select the State"}))
            } else {
                seterrmsg(msg => msg.state === "")
            }
            tempinvoice[key][subkey] = value
            tempinvoice = updatetotal(tempinvoice)
        } else if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                tempinvoice[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempinvoice[key][subkey] = value.toUpperCase()
            }
            updatepangstin(tempinvoice)
        } else {
            tempinvoice[key][subkey] = value
        }
        setinvoicedata(tempinvoice)
    }

    const updateaddressflag = (value) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.addressflag = value
        if (value) {
            delete tempinvoice.shpadd
        } else {
            tempinvoice.shpadd = {'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': ''}
        }
        setinvoicedata(tempinvoice)
    }

    const updateorderstatus = (value) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.create_order = value
        setinvoicedata(tempinvoice)
    }

    const buildtotal = (tempinvoice, bindex) => {
        if (tempinvoice.builds.length > 0) {
            let price = tempinvoice.builds[bindex].price
            let quantity = tempinvoice.builds[bindex].quantity
            let totalprice = price * quantity
            tempinvoice.builds[bindex].pricebgst = round("round", price / 1.18, 2)
            tempinvoice.builds[bindex].totalprice = totalprice
            tempinvoice.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            tempinvoice = updatetotal(tempinvoice)
        }
        return tempinvoice
    }

    const createBuild = (prod) => {
        let tempinvoice = {...invoicedata}
        let builds = []
        let build = {}
        build.title = "Kuro Custom PC"
        build.buildType = "custom"
        build.components = []
        for (let item in prod.presets) {
            let comp = {}
            const presetid = prod.presets[item]
            if (item !== 'shp_fees' && item !== 'build_fees' && item !== 'margin' && item !== 'warranty') {
                if (presetid[0] !== "") {
                    if (item === "wifi") {
                        comp.collection = "networking"
                    } else {
                        comp.collection = "components"
                    }
                    comp.type = item
                    comp.maker = ""
                    comp.productid = ""
                    comp.title = presetData.filter(ps => ps.type === item)[0].list.filter(item => item.presetid === presetid[0])[0].title
                    comp.quantity = 1
                    build.components.push(comp)
                }
            }
        }
        let price = prod.price.kuro_on
        build.hsncode = ""
        build.tax_rate = 18
        build.buildprice = price
        build.discount = 0
        build.totaldiscount = 0
        build.pricebgst = 0
        build.price = price
        build.quantity = 1
        build.totalpricebgst = 0
        build.totalprice = 0
        builds.push(build)
        tempinvoice.builds = builds
        tempinvoice = buildtotal(tempinvoice, 0)
        setinvoicedata(tempinvoice)
    }

    const searchHandler = (e) => {
        const type = e.target.closest('.search_wrap').querySelector('.type').value
        let prodvalue = e.target.closest('.search_wrap').querySelector('.prodvalue').value
        if (prodvalue !== "") {
            if (type === "prod_url" && prodvalue.indexOf('/b/') > 0) {
                prodvalue = prodvalue.split("/b/")[1]
            }
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?' + type + '=' + prodvalue).then(res => {
                createBuild(res.data[0])
            })
        }
    }

    const clearHandler = () => {
        document.querySelector('#search_text').value = ''
    }

    const addbuild = (bindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds.splice(bindex+1, 0, defbuild)
        setinvoicedata(tempinvoice)
    }

    const removebuild = (bindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds.splice(bindex, 1)
        tempinvoice = updatetotal(tempinvoice)
        setinvoicedata(tempinvoice)
    }

    const updatebuild = (bindex, e, key, num=false) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            tempinvoice.builds[bindex].buildprice = tempinvoice.builds[bindex].price
            tempinvoice = buildtotal(tempinvoice, bindex)
        }
        setinvoicedata(tempinvoice)
    }

    const addcomp = (bindex, cindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex].components.splice(cindex+1, 0, {'collection': 'components', 'type': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1})
        setinvoicedata(tempinvoice)
    }

    const removecomp = (bindex, cindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex].components.splice(cindex, 1)
        setinvoicedata(tempinvoice)
    }

    const updatecomp = (bindex, cindex, key, e, num=false) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.builds[bindex].components[cindex][key] = num ? Number(e.target.value) : e.target.value
        setinvoicedata(tempinvoice)
    }

    const updatecompmodel = (bindex, cindex, e) => {
        let tempinvoice = {...invoicedata}
        let prodid = e.value

        if (e) {
            tempinvoice.builds[bindex].components[cindex].productid = prodid
            tempinvoice.builds[bindex].components[cindex].title = (prodid === "other") ? "" : prodData[tempinvoice.builds[bindex].components[cindex].collection].filter(item => item.productid === prodid)[0].title
        }
        setinvoicedata(tempinvoice)
    }

    const addprod = (pindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products.splice(pindex+1, 0, {'collection': '', 'type': '', 'category': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'prodprice': 0, 'discount': 0, 'pricebgst': 0, 'price': 0, 'totaldiscount': 0, 'totalpricebgst': 0, 'totalprice': 0, 'hsncode': '', 'tax_rate': 18})
        setinvoicedata(tempinvoice)
    }

    const removeprod = (pindex) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products.splice(pindex, 1)
        tempinvoice = updatetotal(tempinvoice)
        setinvoicedata(tempinvoice)
    }

    const prodtotal = (tempinvoice, pindex) => {
        if (tempinvoice.products.length > 0) {
            const quantity = tempinvoice.products[pindex].quantity
            const price = tempinvoice.products[pindex].prodprice - tempinvoice.products[pindex].discount
            const totalprice = price * quantity
            tempinvoice.products[pindex].pricebgst = round("round", price / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].price = price
            tempinvoice.products[pindex].totalpricebgst = round("round", totalprice / (1 + (tempinvoice.products[pindex].tax_rate / 100)), 2)
            tempinvoice.products[pindex].totalprice = totalprice
            tempinvoice = updatetotal(tempinvoice)
        }
        return tempinvoice
    }

    const getoptionlabel = (pindex, key, value) => {
        let prod = invoicedata.products[pindex]
        if (key === "collection") {
            return mapData[0].collections.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "type") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === value)[0]?.title ?? value
        } else if (key === "category") {
            return mapData[0].collections.filter(_ => _.name === prod.collection)[0]?.types.filter(_ => _.name === prod.type)[0]?.categories.filter(_ => _.name === value)[0]?.title ?? value
        }
    }

    const updateprod = (pindex, key, e, num=false) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products[pindex].discount = 0
        tempinvoice.products[pindex].totaldiscount = 0
        tempinvoice.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "collection" && key === "type" && key === "category" && key === "maker") {
            tempinvoice.products[pindex].productid = ""
            tempinvoice.products[pindex].title = ""
            tempinvoice.products[pindex].prodprice = 0
        }
        if (key !== "title") {
            tempinvoice = prodtotal(tempinvoice, pindex)
        }
        setinvoicedata(tempinvoice)
    }

    const updateprodmodel = (pindex, e, collection) => {
        const prodid = e.value
        let tempinvoice = {...invoicedata}
        tempinvoice.products[pindex].productid = prodid
        tempinvoice.products[pindex].discount = 0
        tempinvoice.products[pindex].totaldiscount = 0
        if (e) {
            tempinvoice.products[pindex].title = (prodid === "other") ? "" : prodData[collection].filter(item => item.productid === prodid)[0].title
            tempinvoice.products[pindex].prodprice = (prodid === "other") ? 0 : prodData[collection].filter(item => item.productid === prodid)[0].price.kuro_on
        } else {
            tempinvoice.products[pindex].title = ''
            tempinvoice.products[pindex].productid = ''
            tempinvoice.products[pindex].prodprice = 0
        }
        tempinvoice = prodtotal(tempinvoice, pindex)
        setinvoicedata(tempinvoice)
    }

    const updateproddiscount = (pindex, e) => {
        let tempinvoice = {...invoicedata}
        tempinvoice.products[pindex].discount = Number(e.target.value)
        tempinvoice.products[pindex].totaldiscount = tempinvoice.products[pindex].discount * tempinvoice.products[pindex].quantity
        tempinvoice = prodtotal(tempinvoice, pindex)
        setinvoicedata(tempinvoice)
    }

    const prodOptions = (prod) => {
        let options = []
        if (prodData && prod.collection && prod.type) {
            options = prodData[prod.collection].filter(_ => _.type === prod.type && _.maker === prod.maker).map(item => {
                return { value: item.productid, label: item.title }
            })
        }
        return options
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    var fileDownload = require('js-file-download')

    const invoiceHandler = () => {
        if (invoicedata.invoice_date !== "" && invoicedata.billadd.state !== "") {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }

            let invoice = invoicedata
            invoice["creditnote_issued"] = false
            for (let i=0; i<invoice.builds.length; i++) {
                delete invoice.builds[i].presets
                delete invoice.builds[i].status
                delete invoice.builds[i].delete_flag
                invoice.builds[i].price = parseFloat(invoice.builds[i].price)
            }

            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices', invoice, config)
            .then((res) => {
                fileDownload(res.data, 'invoice.pdf')
                history.push("/outward-invoices")
            })
        } else {
            alert("State and Invoice Date are mandatory")
        }
    }

    return (
        <div className="hp20 invoice-creation txt-light-grey">
            {preview ? (
            <div className="">
                <h2>Preview</h2>
                <table className="border even_odd" cellSpacing="0" cellPadding="0">
                    <tr>
                        <th>S.No.</th>
                        <th colSpan="9">Product Description</th>
                        <th colSpan="2">HSN / SAC CODE</th>
                        <th>QTY</th>
                        <th colSpan="3">Unit Price<br/>(Excl. GST)</th>
                        <th colSpan="3">Tax Rate</th>
                        <th colSpan="3">Total Amount</th>
                    </tr>
                    {invoicedata.builds.map((build, i) =>
                    <tr key={i}>
                        <td></td>
                        <td colSpan="9">
                        {build.buildType === "custom" ? (
                            <>
                            <b>{build.title}</b><br/>
                            {Object.keys(build.components).map((comp) =>
                                comp.includes("title") && build.components[comp] !== "" && 
                                <>
                                {build.components[comp]}<br/>
                                </>
                            )}
                            </>
                        ) : (
                            build.title
                        )}
                        </td>
                        <td colSpan="2">{build.hsncode}</td>
                        <td>{build.quantity}</td>
                        <td colSpan="3">{build.pricebgst}</td>
                        <td colSpan="3">
                        {invoicedata.billadd.state === "Telangana" ? (
                        <>
                        CGST @ 9%<br/>SGST @ 9%
                        </>
                        ) : (
                            "IGST @ 18%"
                        )}
                        </td>
                        <td className="text_right" colSpan="3">{build.totalpricebgst}</td>
                    </tr>
                    )}
                    {invoicedata.products.map((prod, i) =>
                    <tr>
                        <td></td>
                        <td colSpan="9">{prod.title}</td>
                        <td colSpan="2">{prod.hsncode}</td>
                        <td>{prod.quantity}</td>
                        <td colSpan="3">{prod.pricebgst}</td>
                        <td colSpan="3">
                        {invoicedata.billadd.state === "Telangana" ? (
                        <>
                        CGST @ 9%<br/>SGST @ 9%
                        </>
                        ) : (
                            "IGST @ 18%"
                        )}
                        </td>
                        <td className="text_right" colSpan="3">{prod.totalpricebgst}</td>
                    </tr>
                    )}
                    <tr>
                        <td className="text_right" colSpan="19"><b>Amount Taxable</b></td>
                        <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalpricebgst}</b></td>
                    </tr>
                    <tr>
                        <td className="text_right" colSpan="19">
                            Tax Summary<br/>
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            CGST @ 9%<br/>SGST @ 9%
                            </>
                            ) : (
                            "IGST @ 18%"
                            )}
                        </td>
                        <td className="text_right" colSpan="3">
                            &nbsp;<br/>
                            {invoicedata.billadd.state === "Telangana" ? (
                            <>
                            Rs.{invoicedata.cgst}<br/>
                            Rs.{invoicedata.cgst}
                            </>
                            ) : (
                                invoicedata.gst
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text_right" colSpan="19">Round off</td>
                        <td className="text_right" colSpan="3">{invoicedata.roundoff}</td>
                    </tr>
                    <tr>
                        <td className="text_right" colSpan="19"><b>Grand Total</b></td>
                        <td className="text_right" colSpan="3"><b>Rs.{invoicedata.totalprice}</b></td>
                    </tr>
                </table>
                <ul className="btns">
                    <li>
                        <button onClick={() => setpreview(false)}>Back</button>
                    </li>
                    <li>
                        <button onClick={invoiceHandler}>Generate Invoice</button>
                    </li>
                </ul>
            </div>
            ) : (
            <>
            <h2>Generate Invoice</h2>
            <div className="invoice-wrap">
                <div style={{marginBottom: '20px'}}>
                    <span className='txt-light'>Create Order</span><input type='checkbox' className='checkbox' onChange={() => updateorderstatus(!invoicedata.create_order)} checked={invoicedata.create_order} />
                </div>
                <div className="prod-search">
                    <div className="search_wrap">
                        <select className="type">
                            <option value="prod_url">ProductUrl</option>
                            <option value="productid">ProductId</option>
                        </select>
                        <input id="search_text" className="prodvalue" />
                        <ul className='btns'>
                            <li><button onClick={(e) => searchHandler(e)}>Search</button></li>
                            <li><button onClick={clearHandler}>Clear</button></li>
                        </ul>
                    </div>
                </div>
                <div className='title_wrap col_mob'>
                    <p>Builds</p>
                    <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                </div>
                {invoicedata.builds && invoicedata.builds.map((build, i) =>
                <table className="border even_odd" cellSpacing="0" cellPadding="0">
                    <tbody key={i}>
                        <tr>
                            <td>Build Title</td>
                            <td colSpan="5"><textarea className='' onChange={(e) => updatebuild(i, e, "title")} value={build.title} /></td>
                            <td>
                                <span className="prod-span" onClick={() => removebuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                <span className="prod-span" onClick={() => addbuild(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                </td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td><input value={build.price} onChange={(e) => updatebuild(i, e, "price")} /></td>
                            <td>Build Quantity</td>
                            <td><input type="number" min="1" max="100" onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(i, e, "quantity", true)} className='small'/></td>
                            <td>Total Price</td>
                            <td>{build.totalprice}</td>
                            <td><span className="prod-span" onClick={() => addcomp(i, -1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span></td>
                        </tr>
                        {build.components.map((comp, j) =>
                        <tr key={j}>
                            <td>
                                <select value={comp.collection} onChange={(e) => updatecomp(i, j, "collection", e)}>
                                    <option value="">Select Collection</option>
                                    <option value="components">Components</option>
                                    <option value="networking">Networking</option>
                                </select>
                            </td>
                            <td>
                                <select value={comp.type} onChange={(e) => updatecomp(i, j, "type", e)}>
                                    <option value="">Select Type</option>
                                {comp.collection === "components" &&
                                    mapData[0].collections.filter(_ => _.name === "components")[0].types.map((item, i) =>
                                        <option value={item.name}>{item.title}</option>
                                )}
                                {comp.collection === "networking" && <option value="wifi-card">WiFi Card</option>}
                                </select>
                            </td>
                            <td>
                                <select className="large" value={comp.maker} onChange={(e) => updatecomp(i, j, "maker", e)}>
                                    <option value="">Select Brand</option>
                                    {comp.collection && comp.type !== "other" && prodData &&
                                        [...new Set(prodData[comp.collection].filter(_ => _.type === comp.type).map(item => item.maker))].sort().map((maker, j) =>
                                        <option key={j} value={maker}>{maker}</option>
                                        )
                                    }
                                </select>
                            </td>
                            <td>
                                <Select classNamePrefix="kuro-search-select" options={prodOptions(comp)} defaultValue={{ value: comp.productid, label: comp.title }} onChange={(e) => updatecompmodel(i, j, e)} placeholder="Select Component" className="react-select-container" />
                            </td>
                            <td width={"350px"}>
                                <textarea value={comp.title} onChange={(e) => updatecomp(i, j, "title", e)} />
                            </td>
                            <td>
                                <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatecomp(i, j, "quantity", e, true)}  className='small'/>
                            </td>
                            <td>
                                <span className="prod-span" onClick={() => addcomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                <span className="prod-span" onClick={() => removecomp(i, j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                )}
                <div className='title_wrap col_mob'>
                    <p>Peripherals</p>
                    <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                </div>
                {invoicedata.products.length > 0 &&
                <table className="border" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>Collection</td>
                            <td>Type</td>
                            <td>Category</td>
                            <td>Brand</td>
                            <td>Model</td>
                            <td>Title</td>
                            <td>HSNCode</td>
                            <td>Price</td>
                            <td>Tax Rate</td>
                            <td>Quantity</td>
                            <td>Discount<br/>(Per Unit)</td>
                            <td>Total Price</td>
                            <td>
                                <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </td>
                        </tr>
                        {invoicedata.products.map((prod, i) =>
                        <tr key={i}>
                            <td>
                                <select className='small' value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                    <option value="">Select Collection</option>
                                    {prodData && Object.keys(prodData).sort().map((coll, j) =>
                                        <option key={j} value={coll}>{getoptionlabel(i, "collection", coll)}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                <select className='small' value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                    <option value="">Select Type</option>
                                    {prod.collection && prodData &&
                                        [...new Set(prodData[prod.collection].map(_ => _.type))].sort().map((type, j) =>
                                        <option key={j} value={type}>{getoptionlabel(i, "type", type)}</option>
                                        )
                                    }
                                </select>
                            </td>
                            <td>
                                <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                    <option value="">Select Category</option>
                                {prod.collection && prod.type && prodData &&
                                    [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(_ => _.category))].sort().map((cat, j) =>
                                        cat !== "" &&
                                    <option key={j} value={cat}>{getoptionlabel(i, "category", cat)}</option>
                                    )
                                }
                                </select>
                            </td>
                            <td>
                                <select className='small' value={prod.maker} onChange={(e) => updateprod(i, "maker", e)}>
                                    <option value="">Select Brand</option>
                                    {prod.collection && prod.type && prodData &&
                                        [...new Set(prodData[prod.collection].filter(_ => _.type === prod.type).map(item => item.maker))].sort().map((maker, j) =>
                                        <option key={j} value={maker}>{maker}</option>
                                        )
                                    }
                                </select>
                            </td>
                            <td>
                                <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} defaultValue={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprodmodel(i, e, prod.collection)} placeholder="Select Model" className="react-select-container" />
                            </td>
                            <td>
                                <textarea value={prod.title} type="textarea" onChange={(e) => updateprod(i, "title", e)}></textarea>
                            </td>
                            <td>
                                <input value={prod.hsncode} onChange={(e) => updateprod(i, "hsncode", e)} />
                            </td>
                            <td>
                                <input value={prod.prodprice} type="number" className="small" min="0" onWheel={disableScroll} onChange={(e) => updateprod(i, "prodprice", e, true)} />
                            </td>
                            <td>
                                <select className="small" value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e, true)}>
                                    <option value={5}>5</option>
                                    <option value={12}>12</option>
                                    <option value={18}>18</option>
                                    <option value={28}>28</option>
                                </select>
                            </td>
                            <td>
                                <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={prod.quantity} onChange={(e) => updateprod(i, "quantity", e, true)} />
                            </td>
                            <td>
                                <input className='small' value={prod.discount} type="number" onWheel={disableScroll} onChange={(e) => updateproddiscount(i, e)} />
                            </td>
                            <td>
                                {prod.totalprice}
                            </td>
                            <td>
                                <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                }
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Taxable Amount</td>
                            <td>{invoicedata.totalpricebgst}</td>
                        </tr>
                        {invoicedata.billadd.state === "Telangana" ? (
                        <tr>
                            <td>CGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>) : (<tr>
                            <td>IGST</td>
                            <td>{invoicedata.gst}</td>
                        </tr>)}
                        {invoicedata.billadd.state === "Telangana" ? (
                        <tr>
                            <td>SGST</td>
                            <td>{invoicedata.cgst}</td>
                        </tr>) : null}
                        <tr>
                            <td>Round off</td>
                            <td>{invoicedata.roundoff}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{invoicedata.totalprice}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Invoice Date</td>
                            <td>
                                <DatePicker dateFormat='dd-MMM-yyyy' selected={invoicedata.invoice_date} onChange={date => updateinvoicedate(date)}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td><input className="order" value={invoicedata.orderid} onChange={(e) => updateinvoice("orderid", e)}  /></td>
                        </tr>
                        <tr>
                            <td>entity</td>
                            <td>
                                        <select className="type" name="entity" value={invoicedata.entity} onChange={(e) => updateinvoice("entity", e)} >
                                            {accesslevels.map((item, index) => (item.outward_invoices === "write" || item.outward_invoices === "edit") ? (<><option key={index} value={item.entity ? item.entity : null}>{bgDetails && bgDetails.entities.map( ent => ent.name===item.entity ? ent.title : null )}</option></>) : (null))}
                                        </select>
                                    </td>
                        </tr>
                        <tr>
                            <td>PO/Ref No</td>
                            <td><input className="po_ref" value={invoicedata.po_ref} onChange={(e) => updateinvoice("po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.user.name} onChange={(e) => updateuser("name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.user.phone} onChange={(e) => updateuser("phone", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Billing Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province.state}>{province.state}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                            Shipping Address same as Billing Address 
                                <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!invoicedata.addressflag)} checked={invoicedata.addressflag} />
                            </td>
                        </tr>
                        {!invoicedata.addressflag &&
                        <>
                        <tr>
                            <td colSpan="2">Shipping Address</td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="company" value={invoicedata.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="name" value={invoicedata.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="phone" value={invoicedata.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="address" value={invoicedata.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="city" value={invoicedata.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="pincode" value={invoicedata.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="state" value={invoicedata.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province.state}>{province.state}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="pan" value={invoicedata.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="gstin" value={invoicedata.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                        </>
                        }
                        <tr>
                            <td colSpan="2">
                                <ul className="btns">
                                    <li><button onClick={() => setpreview((invoicedata.totalprice > 200000 && invoicedata.billadd.pan === "") ? seterrmsg("*** Invoice value is more than 2 lakhs, So PAN is mandatory") : true)}>Preview Invoice</button></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {Object.keys(errmsg).map((err) =>
                    <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
                )}
                <ul className='btns'>
                    <li><KuroLink to="/outward-invoices"><button>Back</button></KuroLink></li>
                </ul>
                <p>&nbsp;</p>
            </div>
            </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(GenerateInvoice)