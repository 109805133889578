import axios from 'axios';
import { UPDATE_PRODUCTS, UPDATE_PRESETS } from './types';

export const getProducts = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/storedata')
        .then((res) => {
            dispatch({
                type: UPDATE_PRODUCTS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });

};

// Get the Presets
export const getPresets = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets')
        .then((res) => {
            dispatch({
                type: UPDATE_PRESETS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};