import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ScrollToTop from './ScrollToTop'
import Header from './Header'
import Footer from './Footer'
import Switchgroup from '../../pages/Switchgroup'

const Layout = ({user: { isAuthenticated, userDetails }, children}) => {

    const [scrollPos, setScrollPos] = useState(false)

    const handleScroll = () => {
        if (window.scrollY > 1000){
            setScrollPos(true)
        } else {
            setScrollPos(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', handleScroll)
    }, [])
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return ( 
        <Fragment>
                {isAuthenticated && (
                <>
                    <ScrollToTop />
                    <Header />
                </>
            )}
            <div className="main pg_mh">
                {children}
                <button style={{ display: scrollPos  ? 'flex' : 'none'}} className='scroll-up' onClick={()=> scrollToTop() }>&#8673;</button>
            </div>
            {isAuthenticated &&
            <Footer/>
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Layout)