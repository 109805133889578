import React, { useState, useEffect } from 'react'
import axios from 'axios'
import EditableBuild from '../../components/EditableBuild'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const PreBuiltsFinder = ({ type, admin: { accesslevels } }) => {

    const [prodData, setProdData] = useState(null)
    let history = useHistory();
    const access = accesslevels.find((x) => x.pre_builts_finder !== "NA")

    useEffect(() => {
        if (!access) {
            history.push('/unauthorized')
            return;
        }
    })

    const searchHandler = (e) => {
        setProdData(null)
        const type = e.target.closest('.search_wrap').querySelector('.type').value
        let prodvalue = e.target.closest('.search_wrap').querySelector('.prodvalue').value
        if (type === "prod_url") {
            if (prodvalue.indexOf('/b/') > 0) {
                prodvalue = prodvalue.split("/b/")[1]
            }
        }
        if (prodvalue !== "") {
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?purpose=update&' + type + '=' + prodvalue).then(res => {
                setProdData(res.data)
            })
        }
    }

    const clearHandler = () => {
        setProdData(null)
        document.querySelector('#search_text').value = ''
    }

    return (
        <div className="pre-builts mx-width">
            <div className="page-title">
                <h1>Pre-Builts Finder</h1>
            </div>
            <div className="search_wrap">
                <select className="type">
                    <option value="prod_url">ProductUrl</option>
                    <option value="productid">ProductId</option>
                </select>
                <input id="search_text" className="prodvalue" />
                <ul className='btns'>
                    <li><button onClick={searchHandler}>Search</button></li>
                    <li><button onClick={clearHandler}>Clear</button></li>
                </ul>
            </div>
            <div className="search-results txt-light-grey">
                {prodData === null ? (
                    <p>No results to show</p>
                ) : (
                    <EditableBuild prod={prodData} type={type} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
})

export default connect(mapStateToProps)(PreBuiltsFinder)