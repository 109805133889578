import React, { useState, useEffect } from 'react'
import axios from 'axios'
import EditableProd from '../../components/EditableProd'
import "../../styles/products.css"
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ProdFinder = ({ admin: { accesslevels } }) => {

    const [query, setquery] = useState({
        "type": "prod_url",
        "coll": "components",
        "prod": ""
    })
    const history = useHistory();
    const access = accesslevels.filter(item => item.products !== "NA")

    useEffect(() => {
        if (!access) {
            history.push('/unauthorized')
            return;
        }
    })

    const queryHandler = (e, key) => {
        let tempquery = { ...query }
        tempquery[key] = e.target.value
        setquery(tempquery)
    }

    const [prodData, setProdData] = useState(null)

    const searchHandler = (e) => {
        setProdData(null)
        let prodvalue = query.prod
        if (query.type === "prod_url") {
            prodvalue = prodvalue.split('/').pop()
        }
        if (prodvalue !== "") {
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/' + query.coll + '?' + query.type + '=' + prodvalue).then(res => {
                setProdData(res.data)
            })
        }
    }

    return (
        <div className="prod-search txt-light-grey">
            <h2>Product Finder</h2>
            <div className="search_wrap2">
                <select className="type" value={query.type} onChange={(e) => queryHandler(e, "type")}>
                    <option value="prod_url">ProductUrl</option>
                    <option value="productid">ProductId</option>
                </select>
                <select className="collection" value={query.coll} onChange={(e) => queryHandler(e, "coll")}>
                    <option value="components">Components</option>
                    <option value="monitors">Monitors</option>
                    <option value="accessories">Accessories</option>
                    <option value="external">External Devices</option>
                    <option value="networking">Networking</option>
                </select>
                <input className="prod" value={query.prod} onChange={(e) => queryHandler(e, "prod")} />
                <ul className='btns'>
                    <li> <button onClick={searchHandler}>Search</button></li>
                </ul>
            </div>
            <div className="search-results">
                {prodData === null || prodData.length === 0 ? (
                    <p>No results to show</p>
                ) : (
                    <EditableProd prod={prodData[0]} collection={query.coll} />
                )}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin,
})
export default connect(mapStateToProps)(ProdFinder)