import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


const StockProd = ({ match, user: { token }, products: { prodData } }) => {

    const { params: { prodId } } = match
    const [stockdata, setstockdata] = useState(null)
    const history = useHistory()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }
        let url = process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?productid=' + prodId
        axios(url, config).then(res => {
            setstockdata(res.data[0])
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [prodId])

    return (
        <div className="core-comp mx-width txt-light-grey">
            {stockdata !== null ? (
            <div>
                <p className="breadcrumb txt-light-grey"><KuroLink to="/stock-register">Stock Register</KuroLink> &gt; <KuroLink to={"/stock-register/" + stockdata.collection + "/" + stockdata.type}>{stockdata.type}</KuroLink></p>
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Title</td>
                            <td>{prodData[stockdata.collection].filter(_ => _.productid === stockdata.productid)[0].title}</td>
                        </tr>
                    {stockdata.stock.map((item, i) =>
                        <tr key={i}>
                            <td><KuroLink to={"/inward-invoices/" + item.invoiceid}>{item.invoiceid}</KuroLink></td>
                            <td>
                            {item.sr_nos.map((sr, i) =>
                                <>
                                <span className='util'>{sr}</span><br/>
                                </>
                            )}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            ) : (
                <p>No data available</p>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(StockProd)