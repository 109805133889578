import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import note from '../assets/img/button.png'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const Users = ({user: {userDetails}, admin: { accesslevels }}) => {

    const [userData, setuserData] = useState(null)
    const [limit, setlimit] = useState(15)
    const history=useHistory();
    const access= accesslevels.find(item => item.user_list !== "NA")
    useEffect(()=>{
if(access === undefined || access.length <= 0){
    history.push("/unauthorized")
    return;
}
    },[])
    useEffect(() => {
        let url = process.env.REACT_APP_KG_API_URL + 'kuroadmin/userlist?phone=9492540571'
        if (limit !== 0) url += '&limit=' + limit
        axios(url).then(res => {
            setuserData(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }, [limit])

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 15)
        }
    }

    return (
        <div className="users txt-light-grey">
            <h2>User List</h2>
            <div className="note"><p>Instructions </p> 
                <img src={note} alt='instruction' className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            <table className="border table_mob" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Userid</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Username</th>
                        <th>Last Login</th>
                        <th>Orders</th>
                    </tr>
                {userData !== null && userData.map((user, i) =>
                    <tr key={i}>
                        <td><KuroLink to={'/users/' + user.userid}>{user.userid}</KuroLink></td>
                        <td>{user.name}</td>
                        <td>{user.phone}</td>
                        <td>{user.email}</td>
                        <td>{user.username}</td>
                        <td>{user.last_login}</td>
                        <td>{user.orders === 0 ? "No Orders" :<KuroLink to={{pathname: '/orders/' + user.userid, state: {userid: user.userid}}}>{user.orders}</KuroLink>}</td>
                    </tr>
                )}
                </tbody>
            </table>
            {limit !== 0 && userData !== null &&
            <div className='btn_container'>
                <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                <button className='btn'onClick={() => limitHandler(true)}>Show All</button>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
})
export default connect(mapStateToProps)(Users);