import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import KuroLink from '../components/common/KuroLink'
import { getStates, getVendors } from '../actions/admin'
import '../styles/table.css'
import note from '../assets/img/button.png'
import { useHistory } from "react-router-dom"

const Vendors = ({ user: { token, userDetails }, admin: { provinces, vendors, accesslevels }, getStates, getVendors }) => {
    const history = useHistory()
    const [vendorflag, setvendorflag] = useState(false)
    const [submitflag, setsubmitflag] = useState(false)
    const [errmsg, seterrmsg] = useState("")
    const selectAllRef = useRef(null)
    const [tagselectall, setTagselectall] = useState(false)
    const [newvendor, setnewvendor] = useState(
        {
            'name': '',
            'type': '',
            'payment_type': 'Post Paid',
            "tags": [],
            'regaddress': {
                'line1': '',
                'line2': '',
                'city': '',
                'province': '',
                'pincode': ''
            },
            'credit': 0,
            'pan': '',
            'tan': '',
            'gstdetails': [
                {
                    'gst': {
                        'gstin': ''
                    },
                    'address': {
                        'line1': '',
                        'line2': '',
                        'city': '',
                        'province': '',
                        'pincode': ''
                    }
                }
            ]
        }
    )

    const typesOfVendors = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Rent",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Expenses - Petty Cash",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    const tagOptionsHandler = () => {
        const tagOptions = {
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Rent": ["Rent"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        }
        return tagOptions[newvendor.type] || []
    }

    useEffect(() => {
        const access = accesslevels.find(item => item.vendors !== "NA");
        if (access === undefined || access.length <= 0) {
            history.push('/unauthorized')
            return;
        }
        getStates()
        getVendors()
    }, [])

    useEffect(() => {
        if (selectAllRef.current) {
            if (newvendor.tags.length === 0) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = false
            } else if (newvendor.tags.length === tagOptionsHandler(newvendor.type).length) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = true
            } else {
                selectAllRef.current.indeterminate = true
            }
        }
    }, [newvendor.tags, newvendor.type])

    const addvendor = () => {
        setvendorflag(true)
    }

    const updatesubmitflag = (vendor) => {
        let tempflag = true
        let tempmsg = ""
        if (vendor.name === "") {
            tempflag = false
            tempmsg = "*** Vendor Name should not be empty"
        } else if (vendor.regaddress.province === "") {
            tempflag = false
            tempmsg = "*** Please select the vendor state"
        } else if (vendor.pan.length !== 10 || !(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(vendor.pan)) {
            tempflag = false
            tempmsg = "*** Please Enter Valid PAN number"
        } else if (vendor.gstdetails[0].gst.gstin.length !== 15 || vendor.gstdetails[0].gst.gstin.slice(2, 12) !== vendor.pan || !(/^[0-9]*$/).test(vendor.gstdetails[0].gst.gstin.slice(0, 2))) {
            tempflag = false
            tempmsg = "*** Please Enter Valid GSTIN number"
        }
        seterrmsg(tempmsg)
        setsubmitflag(tempflag)
    }

    const toggleselectall = () => {
        let tempVendor = { ...newvendor }
        let selectedTags = []
        if (tagselectall) {
            setTagselectall(false);
            selectedTags = []
        } else {
            tagOptionsHandler(newvendor.type).map((tag) => {
                selectedTags.push(tag)
            })
            setTagselectall(true)
        }
        tempVendor.tags = [...selectedTags];
        setnewvendor(tempVendor)
    }

    const updateTag = (tag) => {
        let selectedTags = [...newvendor.tags] // Create a new array instead of mutating the original
        if (selectedTags.includes(tag)) {
            selectedTags = selectedTags.filter((t) => t !== tag) // Remove the tag
        } else {
            selectedTags.push(tag) // Add the tag
        }
        setnewvendor((prev) => ({ ...prev, tags: selectedTags })) // Update state with the new array
    }

    const updatevendor = (e, key, subkey = "") => {
        let tempvendor = { ...newvendor }
        let value = e.target.value
        if (key === "type") {
            tempvendor.type = value
            tempvendor.tags = []
        } else if (key === "regaddress") {
            if (subkey === "city") {
                if ((/^[a-zA-Z\s]*$/).test(value)) {
                    tempvendor[key][subkey] = value
                }
            } else if (subkey === "pincode") {
                if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                    tempvendor[key][subkey] = value
                }
            } else {
                tempvendor[key][subkey] = value
            }
        } else {
            if (key === "pan") {
                if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                    tempvendor[key] = value.toUpperCase()
                }
            } else if (key === "name") {
                tempvendor[key] = value.toUpperCase()
            } else {
                tempvendor[key] = value
            }
        }
        updatesubmitflag(tempvendor)
        setnewvendor(tempvendor)
    }

    const updatevendorgst = (e, key, subkey) => {
        let tempvendor = { ...newvendor }
        let value = e.target.value
        if (key === "gst") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                tempvendor.gstdetails[0][key][subkey] = value.toUpperCase()
            }
            updatesubmitflag(tempvendor)
        } else {
            if (subkey === "city") {
                if ((/^[a-zA-Z\s]*$/).test(value)) {
                    tempvendor.gstdetails[0][key][subkey] = value
                }
            } else if (subkey === "pincode") {
                if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                    tempvendor.gstdetails[0][key][subkey] = value
                }
            } else {
                tempvendor.gstdetails[0][key][subkey] = value
            }
        }
        setnewvendor(tempvendor)
    }

    const updatebankdetails = (e, key) => {
        let tempvendor = { ...newvendor }
        tempvendor[key] = e.target.value
        setnewvendor(tempvendor)
    }

    const cancelHandler = () => {
        setvendorflag(false)
        seterrmsg("")
    }

    const submitvendor = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        newvendor.name = newvendor.name.trim()

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/vendors', newvendor, config)
        setvendorflag(false)
    }

    return (
        <div className="vendors txt-light">
            {vendorflag ? (
                <div className="new-vendor">
                    <h3>New Vendor</h3>
                    <div className="note"><p>Instructions </p>
                        <img src={note} alt='instruction' className='note_img' />:
                        <span className="note_text">lorem text</span>
                    </div>
                    <div>
                        <p className='txt-right'>Note:lorem text</p><br />
                    </div>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>
                                    <table className="border" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td><textarea className="large" name="name" onChange={(e) => updatevendor(e, "name")} value={newvendor.name} /></td>
                                            </tr>
                                            <tr>
                                                <td>Type</td>
                                                <td>
                                                    <select value={newvendor.type} onChange={(e) => updatevendor(e, "type")}>
                                                        <option value="">Select Vendor Type</option>
                                                        {typesOfVendors.map((type, index) =>
                                                            <option key={index} value={type}  >
                                                                {type}
                                                            </option>
                                                        )}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Payment Type</td>
                                                <td>
                                                    <select value={newvendor.payment_type} onChange={(e) => updatevendor(e, "payment_type")}>
                                                        <option value="">Select Payment Type</option>
                                                        <option value="Post Paid">Post Paid</option>
                                                        <option value="Auto Paid">Auto Paid</option>
                                                        <option value="Pre Paid">Pre Paid</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            {
                                                (newvendor.type && tagOptionsHandler(newvendor.type).length > 0) &&
                                                <tr>
                                                    <th>Tags</th>
                                                    <td>
                                                        {tagOptionsHandler(newvendor.type).length > 1 && <div className="checkbox-container">
                                                            <span>
                                                                <input
                                                                    type="checkbox"
                                                                    ref={selectAllRef}
                                                                    checked={tagselectall}
                                                                    onChange={() => toggleselectall()}
                                                                />
                                                            </span>
                                                            <span>Select All</span>
                                                        </div>}

                                                        {tagOptionsHandler(newvendor.type).map((vendortype) => (
                                                            <div key={vendortype} className="checkbox-container-inner" >
                                                                <span>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={(newvendor.tags).includes(vendortype)}
                                                                        onChange={(e) => updateTag(vendortype)}
                                                                    />
                                                                </span>
                                                                <span>{vendortype}</span>
                                                            </div>
                                                        ))}

                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td colSpan="2">Registered Address</td>
                                            </tr>
                                            <tr>
                                                <td>Address Line1</td>
                                                <td><textarea className="large" name="line1" onChange={(e) => updatevendor(e, "regaddress", "line1")} value={newvendor.regaddress.line1} /></td>
                                            </tr>
                                            <tr>
                                                <td>Address Line2</td>
                                                <td><textarea className="large" name="line2" onChange={(e) => updatevendor(e, "regaddress", "line2")} value={newvendor.regaddress.line2} /></td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td><input className="large" name="city" onChange={(e) => updatevendor(e, "regaddress", "city")} value={newvendor.regaddress.city} /></td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>
                                                    <select className="large" name="province" onChange={(e) => updatevendor(e, "regaddress", "province")} value={newvendor.regaddress.province}>
                                                        <option value="">Select State</option>
                                                        {provinces.map((province, i) =>
                                                            <option key={i} value={province["state"]}>{province["state"]}</option>
                                                        )}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pincode</td>
                                                <td><input className="large" name="pincode" onChange={(e) => updatevendor(e, "regaddress", "pincode")} value={newvendor.regaddress.pincode} /></td>
                                            </tr>
                                            <tr>
                                                <td>PAN</td>
                                                <td><input className="large" name="pan" onChange={(e) => updatevendor(e, "pan")} value={newvendor.pan} /></td>
                                            </tr>
                                            <tr>
                                                <td>TAN</td>
                                                <td><input className="large" name="tan" onChange={(e) => updatevendor(e, "tan")} value={newvendor.tan} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <h6>GST Details</h6>
                                    {newvendor.gstdetails.map((item, i) =>
                                        <table className='border' key={i}>
                                            <tbody>
                                                <tr>
                                                    <td>GSTIN</td>
                                                    <td><input className="large" onChange={(e) => updatevendorgst(e, "gst", "gstin")} value={item.gst.gstin} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line1</td>
                                                    <td><textarea className="large" name="line1" onChange={(e) => updatevendorgst(e, "address", "line1")} value={item.address.line1} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Address Line2</td>
                                                    <td><textarea className="large" name="line2" onChange={(e) => updatevendorgst(e, "address", "line2")} value={item.address.line2} /></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input className="large" name="city" onChange={(e) => updatevendorgst(e, "address", "city")} value={item.address.city} /></td>
                                                </tr>
                                                <tr>
                                                    <td>State</td>
                                                    <td>
                                                        <select className="large" name="province" onChange={(e) => updatevendorgst(e, "address", "province")} value={item.address.province}>
                                                            <option value="">Select State</option>
                                                            {provinces.map((province, i) =>
                                                                <option key={i} value={province["state"]}>{province["state"]}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pincode</td>
                                                    <td><input className="large" name="pincode" onChange={(e) => updatevendorgst(e, "address", "pincode")} value={item.address.pincode} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                    <h6>Bank Details</h6>
                                    <table className='border'>
                                        <tbody>
                                            <tr>
                                                <td>Beneficiary Name</td>
                                                <td><input className="large" onChange={(e) => updatebankdetails(e, "bfc_name")} value={newvendor.bfc_name} /></td>
                                            </tr>
                                            <tr>
                                                <td>Beneficiary Code</td>
                                                <td><input className="large" onChange={(e) => updatebankdetails(e, "bfc_code")} value={newvendor.bfc_code} /></td>
                                            </tr>
                                            <tr>
                                                <td>Bank Account No</td>
                                                <td><input className="large" onChange={(e) => updatebankdetails(e, "accountno")} value={newvendor.accountno} /></td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td><input className="large" onChange={(e) => updatebankdetails(e, "ifsc_code")} value={newvendor.ifsc_code} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul className="btns">
                        <li><button onClick={cancelHandler}>Cancel</button></li>
                        <li>
                            {submitflag ? (
                                <button onClick={submitvendor}>Submit</button>
                            ) : (
                                <button style={{ background: "#87858e", color: "#000" }}>Submit</button>
                            )}
                        </li>
                        <p>{errmsg !== "" && errmsg}</p>
                    </ul>
                </div>
            ) : (vendors !== null ? (
                <div className="vendors txt-light-grey">
                    <h2>Vendors</h2>
                    {(accesslevels.find(item => item.vendors === "edit" || item.vendors === "write")) && <ul className="btns">
                        <li><button onClick={addvendor}>Add New Vendor</button></li>
                    </ul>}

                    <table cellPadding="0" cellSpacing="0" className="border table_mob txt-light">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Payment Type</th>
                                <th>Credit</th>
                                <th>PAN</th>
                                <th>TAN</th>
                            </tr>
                            {vendors !== null && vendors.map((vendor, i) =>
                                <tr key={i}>
                                    <td><KuroLink to={'vendors/' + vendor.vendor_code}>{vendor.name}</KuroLink></td>
                                    <td>{vendor.type}</td>
                                    <td>{vendor.payment_type}</td>
                                    <td>{vendor.credit !== 0 ? (vendor.credit + " Days") : ("No Credit")}</td>
                                    <td>{vendor.pan}</td>
                                    <td>{vendor.tan}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                </div>
            ) : (
                <div className="vendors txt-light-grey">
                    <h2>Vendors</h2>
                    <p>No vendors found</p>
                </div>
            )
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getStates, getVendors })(Vendors)