import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import { getVendors } from '../actions/admin'
import KuroLink from '../components/common/KuroLink'
import Select from 'react-select'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const CreatePV = ({ user: { token }, admin: { vendors, accesslevels, bgDetails }, getVendors }) => {
    const history = useHistory()
    const location = useLocation()
    const access = accesslevels.find(item => (item.paymentvouchers === "edit" || item.paymentvouchers === "write"))
    const selectAllRef = useRef(null)
    const [emps, setemps] = useState(null)
    const [tagselectall, setTagselectall] = useState(false)

    const [submitted, setsubmitted] = useState(false)
    const [pvdata, setpvdata] = useState(null)
    const [podata, setpodata] = useState(null)
    const [pooptions, setpooptions] = useState(null)
    const typesOfpv = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Rent",
        "Expenses - Salaries",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Expenses - Petty Cash",
        "Bank Loan",
        "Bank Charges",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    let emptypv = {
        "create_po": false,
        "po_no": "",
        "vendor": "",
        "type": "",
        "tags": [],
        "amount": 0,
        "paid_by": "KURO CADENCE LLP",
        "pay_method": "NEFT",
        "pay_account": "KUROINDB0001",
        "pay_date": "",
        "pay_ref_utr": "",
        "narration": [],
        "status": "",
        "entity": accesslevels[0] ? accesslevels.find(item => (item.paymentvouchers === "edit" || item.paymentvouchers === "write")).entity : ""
    }

    if (location.state !== undefined) {
        let invoice = location.state.invoice
        emptypv = {
            "create_po": false,
            "po_no": invoice.po_no,
            "type": invoice.invoice_type,
            "tags": [],
            "vendor": invoice.vendor ? invoice.vendor : "",
            "amount": invoice.totalprice,
            "paid_by": "KURO CADENCE LLP",
            "pay_method": "NEFT",
            "pay_account": "KUROINDB0001",
            "pay_date": new Date(),
            "pay_ref_utr": "",
            "narration": [],
            "status": "",
            "entity": invoice.entity
        }
    }

    const [paymentvoucher, setpaymentvoucher] = useState(emptypv)
    const VendorOptions = vendors ? vendors.map((vendor) => ({ value: vendor.vendor_code, label: vendor.name })) : [];
    const typeOptions = typesOfpv ? typesOfpv.map((type) => ({ value: type, label: type })) : [];
    const empOptions = emps ? emps.filter(emp => emp.user_status === "Active").map(emp => (emp.name)) : [];
    const tagOptionsHandler = (type) => {
        const tagOptions = {
            "vendor": VendorOptions,
            "Expenses - Utilities": ["Electricity Bill", "Water Bill", "Internet Service"],
            "Expenses - Salaries": empOptions,
            "Expenses - Rent": ["Rent"],
            "Expenses - Food & Entertainment": ["Food", "Beverages"],
            "Expenses - Office Supplies": ["Stationary", "Cleaning"],
            "Expenses - Financial Services": ["Payment Gateway", "POS Machine"],
            "Expenses - Services": ["TP Commission", "Advertisement", "Cloud Services"],
        }
        return tagOptions[type] || []
    }

    useEffect(() => {
        if (access === undefined || access.length <= 0) {
            history.push("/unauthorized");
        }
    }, [])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?limit=0', config).then(res => {
            setpodata(res.data)
            let po_noOptions = res.data ? res.data.map(item => ({ value: item.po_no, label: item.po_no })) : []
            setpooptions(po_noOptions)
        })
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', config)
            .then((res) => {
                setemps(res.data)
            })
    }, [getVendors])

    useEffect(() => {
        if (selectAllRef.current) {
            if (paymentvoucher.tags.length === 0) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = false
            } else if (paymentvoucher.tags.length === tagOptionsHandler(paymentvoucher.type).length) {
                selectAllRef.current.indeterminate = false
                selectAllRef.current.checked = true
            } else {
                selectAllRef.current.indeterminate = true
            }
        }
    }, [paymentvoucher.tags, paymentvoucher.type])

    const updatepostatus = (value) => {
        let tempvoucher = { ...paymentvoucher }
        tempvoucher["create_po"] = value
        tempvoucher["po_no"] = ""
        tempvoucher["amount"] = 0
        setpaymentvoucher(tempvoucher)
    }

    const getselectedoption = (key) => {
        if (key === "vendor") {
            return ({ value: paymentvoucher[key], label: vendors.filter((item) => item.vendor_code === paymentvoucher[key]) })[0]
        } else {
            return ({ value: paymentvoucher[key], label: typesOfpv.filter((item) => item === paymentvoucher[key])[0] })
        }
    }

    const updatevoucher = (key, e, num = false) => {
        let tempvoucher = { ...paymentvoucher }

        if (key === "po_no") {
            if (e.value === "") {
                tempvoucher["amount"] = 0
            } else {
                tempvoucher[key] = e.value
                let temppo = podata.filter(po => po.po_no === e.value)[0]
                tempvoucher["vendor"] = temppo.vendor
                tempvoucher["amount"] = temppo.total_amount
                tempvoucher["type"] = temppo.type
                tempvoucher["tags"] = temppo.tags
                tempvoucher["entity"] = temppo.entity
            }
        } else if (key === "vendor") {
            tempvoucher[key] = e.value
            tempvoucher['type'] = vendors.filter((item) => item.vendor_code === e.value)[0]['type']
        }
        else if (key === "type") {
            tempvoucher[key] = e.value
            tempvoucher.tags = []
        } else if (key === "tags") {
            const value = e.target.value
            const checked = e.target.checked

            let newtags = paymentvoucher.tags

            if (paymentvoucher.type === "Expenses - Salaries") {
                if (checked) {
                    newtags = [value]
                } else {
                    newtags = []
                }
            } else {
                if (checked) {
                    newtags.push(value)
                } else {
                    newtags = newtags.filter(tag => tag !== value)
                }
            }

            tempvoucher.tags = newtags
        } else {
            tempvoucher[key] = (num === true) ? Number(e.target.value) : e.target.value
        }
        setpaymentvoucher(tempvoucher)
    }

    const toggleselectall = () => {
        let tempvoucher = { ...paymentvoucher }
        let selectedTags = []
        if (tagselectall) {
            setTagselectall(false)
            selectedTags = []
        } else {
            selectedTags = tagOptionsHandler(tempvoucher.type)
            setTagselectall(true)
        }
        tempvoucher.tags = [...selectedTags]
        setpaymentvoucher(tempvoucher)
    }

    const updatevoucherdate = (key, date) => {
        let tempvoucher = { ...paymentvoucher }
        tempvoucher[key] = date
        setpaymentvoucher(tempvoucher)
    }

    const updatenarration = (e, index) => {
        let tempvoucher = { ...paymentvoucher }
        tempvoucher["narration"][index]["desc"] = e.target.value
        setpaymentvoucher(tempvoucher)
    }

    const addrow = () => {
        let tempvoucher = { ...paymentvoucher }
        tempvoucher["narration"].push({ 'desc': '', 'narration_date': '', 'narration_by': '' })
        setpaymentvoucher(tempvoucher)
    }

    const removerow = (id) => {
        let tempvoucher = { ...paymentvoucher }
        tempvoucher.narration = tempvoucher.narration.filter((_, i) => i !== id)
        setpaymentvoucher(tempvoucher)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/paymentvouchers', paymentvoucher, config)
            .then((res) => {
                setpvdata(res.data[0])
                setsubmitted(true)
                setpaymentvoucher(emptypv)
            })
    }

    const backHandler = () => {
        setpvdata(null)
        setsubmitted(false)
    }

    return (
        <div className="kuro-po txt-light">
            <h2 className="txt-light-grey">Payment Voucher</h2>
            {submitted && pvdata !== null ? (
                <div>
                    <p>Payment Voucher is Succesfully Created.</p>
                    <p>PV Number: <strong>{pvdata.pv_no}</strong></p>
                    {pvdata.vendor !== "" &&
                    <p>Vendor: <strong>{vendors.filter(vendor => vendor.vendor_code === pvdata.vendor)[0].name}</strong></p>
                    }
                    <p>Amount: <strong>{pvdata.amount}</strong></p>
                    <p>Payment Date: {moment(pvdata.pay_date).format('DD-MM-YYYY')}</p>
                    <ul className="btns">
                        <li>
                            <button onClick={backHandler}>Back</button>
                        </li>
                        <li>
                            <KuroLink to="/payment-vouchers"><button>PV Home</button></KuroLink>
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            ) : (
                <>
                    <table className='border' cellPadding="0" cellSpacing="0">
                        <tbody>
                            {location.state === undefined &&
                                <tr>
                                    <th>Create PO</th>
                                    <td>
                                        <input type="checkbox" onChange={() => updatepostatus(!paymentvoucher.create_po)} checked={paymentvoucher.create_po} />
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>PO No</th>
                                <td>
                                    {paymentvoucher.create_po ? (
                                        "Not Applicable"
                                    ) : (
                                        location.state === undefined ? (
                                            pooptions !== null && <Select classNamePrefix="kuro-search-select" options={pooptions} value={{value: paymentvoucher["po_no"], label: paymentvoucher["po_no"]}} onChange={(e) => updatevoucher("po_no", e)} placeholder="Select Vendor" className="react-select-container" />
                                        ) : (
                                            paymentvoucher.po_no
                                        )
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Entity</th>
                                <td>
                                    <select className="type" name="entity" value={paymentvoucher.entity} onChange={(e) => updatevoucher("entity", e)} >
                                        {accesslevels.map((item, index) => (item.paymentvouchers === "edit" || item.paymentvouchers === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option>) : (null))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Vendor</th>
                                <td>
                                    {vendors !== null &&
                                        (paymentvoucher.create_po ? (
                                            <Select
                                                classNamePrefix="kuro-search-select"
                                                options={VendorOptions}
                                                value={getselectedoption("vendor")}
                                                onChange={(e) => updatevoucher("vendor", e)}
                                                placeholder="Select Vendor"
                                                className="react-select-container"
                                            />
                                        ) : (
                                            vendors.filter(vendor => vendor.vendor_code === paymentvoucher.vendor).map((vendor) =>
                                                vendor.name
                                            )
                                        ))
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>
                                    <Select
                                        classNamePrefix="kuro-search-select"
                                        options={typeOptions}
                                        value={getselectedoption("type")}
                                        onChange={(e) => updatevoucher("type", e)}
                                        placeholder="Select Type Of PV"
                                        className="react-select-container"
                                    />
                                </td>
                            </tr>
                            {(paymentvoucher.type && tagOptionsHandler(paymentvoucher.type).length > 0) &&
                                <tr>
                                    <th>Tags</th>
                                    <td>
                                        {(paymentvoucher.type !== "Expenses - Salaries" && tagOptionsHandler(paymentvoucher.type).length > 1) &&
                                            <div className="checkbox-container">
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        ref={selectAllRef}
                                                        checked={tagselectall}
                                                        onChange={() => toggleselectall()}
                                                    />
                                                </span>
                                                <span>Select All</span>
                                            </div>
                                        }
                                        {tagOptionsHandler(paymentvoucher.type).map((type) =>
                                            <div key={type} className="checkbox-container-inner" >
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        value={type}
                                                        checked={paymentvoucher.tags.includes(type)}
                                                        onChange={(e) => updatevoucher("tags", e)}
                                                    />
                                                </span>
                                                <span>{type}</span>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>Voucher Amount</th>
                                <td>
                                    <input type="number" value={paymentvoucher.amount} onChange={(e) => updatevoucher("amount", e, true)} />
                                </td>
                            </tr>
                            <tr>
                                <th>Paid By</th>
                                <td>
                                    <select value={paymentvoucher.paid_by} onChange={(e) => updatevoucher("paid_by", e)}>
                                        <option value="Payment Pending">Payment Pending</option>
                                        <option value="KURO CADENCE LLP">KURO CADENCE LLP</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Payment Method</th>
                                <td>
                                    <select value={paymentvoucher.pay_method} onChange={(e) => updatevoucher("pay_method", e)}>
                                        <option value="">Select Payment Method</option>
                                        <option value="NEFT">NEFT</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="Cash">Cash</option>
                                        <option value="UPI">UPI</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Debit Card">Debit Card</option>
                                        <option value="Auto Debit">Auto Debit</option>
                                        {/* <option value="Credit Card">Credit Card</option> */}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Pay Account</th>
                                <td>
                                    <select value={paymentvoucher.pay_account} onChange={(e) => updatevoucher("pay_account", e)}>
                                        <option value="">Payment Pending</option>
                                        {paymentvoucher.pay_method === "Cash" ? (
                                            <option value="Cash">Cash</option>
                                        ) : (
                                            <>
                                                <option value="KUROINDB0001">CA INDUSIND BANK 0571</option>
                                                <option value="KUROHDFC0002">CA HDFC BANK 8683</option>
                                                <option value="KUROUBIN0003">CA Union Bank of India 0069</option>
                                            </>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Pay Date</th>
                                <td>
                                    <DatePicker selected={paymentvoucher.pay_date} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updatevoucherdate("pay_date", date)} />
                                </td>
                            </tr>
                            <tr>
                                <th>Pay Ref/UTR</th>
                                <td>
                                    <input value={paymentvoucher.pay_ref_utr} onChange={(e) => updatevoucher("pay_ref_utr", e)} />
                                </td>
                            </tr>
                            <tr>
                                <th>Payment Narration</th>
                                <td>
                                    {paymentvoucher.narration.length > 0 ? (
                                        paymentvoucher.narration.map((item, i) =>
                                            <div key={i}>
                                                <textarea value={item.desc} onChange={(e) => updatenarration(e, i)} />
                                                <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                {i === paymentvoucher.narration.length - 1 && <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                                            </div>
                                        )
                                    ) : (
                                        <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>
                                    <select value={paymentvoucher.status} onChange={(e) => updatevoucher("status", e)}>
                                        <option value="">Pending</option>
                                        <option value="Paid">Paid</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul className="btns">
                        <li>
                            <button onClick={submitHandler}>Submit</button>
                        </li>
                        <li>
                            <KuroLink to="/payment-vouchers"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreatePV)