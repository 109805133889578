import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getStates } from '../actions/admin'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"


const NewOrder = ({ user: {token, userDetails}, admin: { provinces, accesslevels, bgDetails }, updateorderflag }) => {

    const defaultOrder = {
        'channel': '',
        'tporderid': '',
        'po_ref': '',
        'order_date': '',
        'dispatchby_date': '',
        'user': {
            'name': '',
            'phone': '',
            'email': ''
        },
        'billadd': {
            'company': '',
            'name': '',
            'phone': '',
            'addressline1': '',
            'addressline2': '',
            'city': '',
            'pincode': '',
            'state': '',
            'gstin': '',
            'pan': ''
        },
        'shpadd': {
            'company': '',
            'name': '',
            'phone': '',
            'addressline1': '',
            'addressline2': '',
            'city': '',
            'pincode': '',
            'state': '',
            'gstin': '',
            'pan': ''
        },
        'addflag': true,
        'builds': [],
        'products': [],
        'services': [],
        'totalprice': 0,
        'totalpricebgst': 0,
        'gst': 0,
        'cgst': 0,
        'totaldiscount': 0,
        'roundoff': 0,
        'taxes': {
            '5': 0,
            '12': 0,
            '18': 0,
            '28': 0
        }
    }

    let history = useHistory()
    const access= accesslevels.find( item => item.orders === "edit" || item.orders === "write")
    const [neworder, setneworder] = useState(defaultOrder)
    const [errmsg, seterrmsg] = useState({
        "pan": "",
        "gstin": ""
    })

    useEffect(()=>{
        if(access === undefined || access.length <= 0){
            history.push("/unauthorized");
            return;
        }
    })
    const updateorder = (category, key, e) => {
        let temporder = {...neworder}
        if (category === "order") {
            temporder[key] = e.target.value
        } else {
            temporder[category][key] = e.target.value
            if (key !== "email") {
                temporder.billadd[key] = e.target.value
            }
        }
        if (key === "totalprice") {
            let reg = /^[0-9.]*$/
            if (!reg.test(temporder[key])) {
                return temporder[key]
            }
        }
        setneworder(temporder)
    }

    const updateorderdate = (key, date) => {
        let temporder = {...neworder}
        temporder[key] = (date === null) ? "" : date
        setneworder(temporder)
    }

    const updatepangstin = (order) => {
        let tempmsg = {...errmsg}
        if (order.billadd.pan !== "" && (!(/[A-Z]{5}[0-9]{4}[A-Z]{1}/).test(order.billadd.pan))) {
            tempmsg["pan"] = "*** Please Enter Valid PAN number"
        } else {
            tempmsg["pan"] = ""
        }
        if (order.billadd.gstin !== "" && (order.billadd.gstin.length !== 15 || order.billadd.gstin.slice(2,12) !== order.billadd.pan || !(/^[0-9]*$/).test(order.billadd.gstin.slice(0,2)))) {
            tempmsg["gstin"] = "*** Please Enter Valid GSTIN number"
        } else {
            tempmsg["gstin"] = ""
        }
        seterrmsg(tempmsg)
    }

    const updateaddress = (key, subkey, e) => {
        let value = e.target.value
        let temporder = {...neworder}
        if (subkey === "pincode") {
            if (value.length <= 6 && (/^[0-9]*$/).test(value)) {
                temporder[key][subkey] = value
            }
        } else if (subkey === "pan" || subkey === "gstin") {
            if ((/^[A-Z0-9]*$/).test(value.toUpperCase())) {
                temporder[key][subkey] = value.toUpperCase()
            }
            updatepangstin(temporder)
        } else {
            temporder[key][subkey] = value
        }
        setneworder(temporder)
    }

    const addressflag = (e) => {
        let temporder = {...neworder}
        temporder.addflag = e.target.checked
        setneworder(temporder)
    }

    const createOrder = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let orderData = neworder
        if (orderData.addflag) {
            orderData.shpadd = orderData.billadd
        }
        orderData.totalprice = parseFloat(orderData.totalprice)

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders', orderData, config)
        history.push("/tporders")
        setneworder(defaultOrder)
        updateorderflag("")
    }

    const cancelHandler = () => {
        setneworder(defaultOrder)
        updateorderflag("")
    }

    return (
        <div className="create-order txt-light-grey">
            <div className="order-details">
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Channel</td>
                            <td>
                                <select className="large" value={neworder.channel} onChange={(e) => updateorder("order", "channel", e)}>
                                    <option value="">Select Channel</option>
                                    <option value="Amazon">Amazon</option>
                                    <option value="Flipkart">Flipkart</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Third Party Order ID</td>
                            <td><input className="large" value={neworder.tporderid} onChange={(e) => updateorder("order", "tporderid", e)} /></td>
                        </tr>
                        <tr>
                            <td>PO/Ref No</td>
                            <td><input className="large" value={neworder.po_ref} onChange={(e) => updateorder("order", "po_ref", e)} /></td>
                        </tr>
                        <tr>
                            <td>Entity</td>
                            <td>
                                <select className="type" name="entity" value={neworder.entity} onChange={(e) => updateorder("order", "entity", e)} >
                                    {accesslevels.map((item, index) => (item.orders === "edit" || item.orders === "write") ? (<option key={index} value={item.entity ? item.entity : null}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option>) : (null))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Order Date</td>
                            <td>
                                <DatePicker className="large" dateFormat='dd-MMM-yyyy' selected={neworder.order_date} onChange={date => updateorderdate("order_date", date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Order Total</td>
                            <td><input className="large" min="0" value={neworder.totalprice} onChange={(e) => updateorder("order", "totalprice", e)} /></td>
                        </tr>
                        <tr>
                            <td>Dispatch Due Date</td>
                            <td>
                                <DatePicker className="large" dateFormat='dd-MMM-yyyy' selected={neworder.dispatchby_date} onChange={date => updateorderdate("dispatchby_date", date)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={neworder.name} onChange={(e) => updateorder("user", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={neworder.phone} onChange={(e) => updateorder("user", "phone", e)} /></td>
                        </tr>
                        {/* <tr>
                            <td>Email</td>
                            <td><input className="large" value={neworder.email} onChange={(e) => updateorder("user", "email", e)} /></td>
                        </tr> */}
                        <tr>
                            <td colSpan="2"><b>Billing Address</b></td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="large" value={neworder.billadd.company} onChange={(e) => updateaddress("billadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={neworder.billadd.name} onChange={(e) => updateaddress("billadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={neworder.billadd.phone} onChange={(e) => updateaddress("billadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="large" value={neworder.billadd.addressline1} onChange={(e) => updateaddress("billadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="large" value={neworder.billadd.addressline2} onChange={(e) => updateaddress("billadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="large" value={neworder.billadd.city} onChange={(e) => updateaddress("billadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="noarrows large" value={neworder.billadd.pincode} onChange={(e) => updateaddress("billadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="large" value={neworder.billadd.state} onChange={(e) => updateaddress("billadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province.state}>{province.state}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="large" value={neworder.billadd.pan} onChange={(e) => updateaddress("billadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="large" value={neworder.billadd.gstin} onChange={(e) => updateaddress("billadd", "gstin", e)} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                Shipping Address same as Billing Address 
                                <input type="checkbox" className='checkbox' onChange={(e) => addressflag(e)} defaultChecked={neworder.addflag} />
                            </td>
                        </tr>
                        {!neworder.addflag &&
                        <>
                        <tr>
                            <td colSpan="2"><b>Shipping Address</b></td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td><input className="large" value={neworder.shpadd.company} onChange={(e) => updateaddress("shpadd", "company", e)} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><input className="large" value={neworder.shpadd.name} onChange={(e) => updateaddress("shpadd", "name", e)} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input className="noarrows large" value={neworder.shpadd.phone} onChange={(e) => updateaddress("shpadd", "phone", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line1</td>
                            <td><textarea className="large" value={neworder.shpadd.addressline1} onChange={(e) => updateaddress("shpadd", "addressline1", e)} /></td>
                        </tr>
                        <tr>
                            <td>Address Line2</td>
                            <td><textarea className="large" value={neworder.shpadd.addressline2} onChange={(e) => updateaddress("shpadd", "addressline2", e)} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input className="large" value={neworder.shpadd.city} onChange={(e) => updateaddress("shpadd", "city", e)} /></td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td><input className="noarrows large" value={neworder.shpadd.pincode} onChange={(e) => updateaddress("shpadd", "pincode", e)} /></td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                <select className="large" value={neworder.shpadd.state} onChange={(e) => updateaddress("shpadd", "state", e)}>
                                    <option value="">Select State</option>
                                    {provinces.map((province, i) =>
                                    <option key={i} value={province.state}>{province.state}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>PAN</td>
                            <td><input className="large" value={neworder.shpadd.pan} onChange={(e) => updateaddress("shpadd", "pan", e)} /></td>
                        </tr>
                        <tr>
                            <td>GSTIN</td>
                            <td><input className="large" value={neworder.shpadd.gstin} onChange={(e) => updateaddress("shpadd", "gstin", e)} /></td>
                        </tr>
                        </>
                        }
                    </tbody>
                </table>
            </div>
            {Object.keys(errmsg).map((err) =>
                <>{errmsg[err] !== "" && <p>{errmsg[err]}</p>}</>
            )}
            <p>&nbsp;</p>
            <ul className='btns'>
                <li>
                    <button onClick={cancelHandler}>Cancel</button>
                </li>
                <li>
                    {(neworder.order_date !== "" && neworder.totalprice !== 0 && neworder.dispatchby_date !== "") ? (
                        <button onClick={createOrder}>Create Order</button>
                    ) : (
                        <button style={{background: "#87858e", color: "#000"}}>Create Order</button>
                    )}
                </li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getStates })(NewOrder)