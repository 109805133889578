import { useState } from "react"
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import axios from "axios"
import Select from 'react-select'
import note from '../assets/img/button.png'
import admin from "../reducers/admin"


const TPBuild = ({user: {token}, products: { presetData }, admin: { accesslevels }}) => {

    const [tpBuild, settpBuild] = useState({
        'title': 'Kuro Custom PC',
        'entity': accesslevels.find(item => (item.tp_builds === "edit" || item.tp_builds === "write")).entity,
        'asin' : "",
        'channel' : 'Amazon',
        'presets': [
            {
                "type": "cpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "mob",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ram",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "gpu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "psu",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "ssd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "hdd",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "cooler",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "tower",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "fans",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "wifi",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "os",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "shp_fees",
                "presetid": "",
                "title": "",
                "price": 800,
                "quantity": 1,
                "totalprice": 800
            },
            {
                "type": "build_fees",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                "type": "warranty",
                "presetid": "",
                "title": "",
                "price": 0,
                "quantity": 1,
                "totalprice": 0
            },
            {
                'type': 'margin',
                'presetid': 'PREMAR0006',
                'kmar': 10
            }
        ],
        'comptotal': 0,
        'tpcharges': 0,
        'subtotal': 0,
        'kmar': 10,
        'kmargin': 0,
        'buildtotal': 0,
        'kurototal': 0
    })

    let history = useHistory()

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updatekuroTotal = (e) => {
        let tempbuild = {...tpBuild}
        tempbuild.kurototal = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempbuild.kurototal)) {
            return tempbuild.kurototal
        }
        settpBuild(tempbuild)
    }

    const buildTotal = (tempbuild) => {
        let comptotal = 0
        let tpcharges = 0
        let subtotal = 0
        let buildtotal = 0
        let kmar = 10
        let kmargin = 0
        for (let preset of tempbuild.presets) {
            if (preset.type === "margin") {
                kmar = preset.kmar / 100
            } else {
                comptotal += Number(preset.totalprice)
            }
        }
        tpcharges = round("round", comptotal / 9, 2)
        subtotal = round("round", comptotal + tpcharges, 2)
        kmargin = round("round", (subtotal * kmar) / (1 - kmar), 2)
        buildtotal = round("round", subtotal + kmargin, 2)
        tempbuild.comptotal = round("round", comptotal, 2)
        tempbuild.tpcharges = tpcharges
        tempbuild.subtotal = subtotal
        tempbuild.kmargin = kmargin
        tempbuild.buildtotal = buildtotal
        return tempbuild
    }

    const addpreset = (pindex) => {
        let tempbuild = {...tpBuild}
        tempbuild.presets.push({'type': '', 'presetid': '', 'price': 0, 'quantity': 1, 'totalprice': 0})
        tempbuild.presets.splice(pindex+1, 0, {'type': '', 'presetid': '', 'price': 0, 'quantity': 1, 'title': '', 'totalprice': 0})
        settpBuild(tempbuild)
    }

    const removepreset = (pindex) => {
        let tempbuild = {...tpBuild}
        tempbuild.presets = tempbuild.presets.filter((_, i) => i !== pindex)
        tempbuild = buildTotal(tempbuild)
        settpBuild(tempbuild)
    }

    const updatepreset = (pindex, key, e, num=false) => {
        let tempbuild = {...tpBuild}
        tempbuild.presets[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "presetid") {
            let title = presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === e.target.value)[0].title
            let price = presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === e.target.value)[0].price
            let totalprice = price * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].title = title
            tempbuild.presets[pindex].price = price
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "quantity") {
            let totalprice = tempbuild.presets[pindex].price * e.target.value
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "price") {
            let totalprice = e.target.value * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else if (key === "title") {
            tempbuild.presets[pindex].title = e.target.value
        } else {
            tempbuild.presets[pindex].presetid = ""
            tempbuild.presets[pindex].price = 0
            tempbuild.presets[pindex].quantity = 1
            tempbuild.presets[pindex].totalprice = 0
        }
        settpBuild(tempbuild)
    }

    const updatemargin = (e) => {
        let tempbuild = {...tpBuild}
        tempbuild.presets.filter(ps => ps.type === "margin")[0].presetid = e.target.value
        tempbuild.presets.filter(ps => ps.type === "margin")[0].kmar = presetData.filter(ps => ps.type === "margin")[0]["list"].filter(item => item.presetid === e.target.value)[0]["kmar"]
        tempbuild = buildTotal(tempbuild)
        settpBuild(tempbuild)
    }

    const updateTPBuild = (e, key) => {
        let tempbuild = {...tpBuild}
        tempbuild[key] = e.target.value
        settpBuild(tempbuild)
    }

    const updateBuildComp = (pindex, e) => {
        let tempbuild = {...tpBuild}
        let presetid = e.value
        if (e) {
            tempbuild.presets[pindex].presetid = presetid
            let title = (presetid === "other") ? "" : presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].title
            let price = (presetid === "other") ? "" : presetData.filter(ps => ps.type === tempbuild.presets[pindex]["type"])[0]["list"].filter(item => item.presetid === presetid)[0].price
            let totalprice = price * tempbuild.presets[pindex].quantity
            tempbuild.presets[pindex].title = title
            tempbuild.presets[pindex].price = price
            tempbuild.presets[pindex].totalprice = totalprice
            tempbuild = buildTotal(tempbuild)
        } else {
            tempbuild.presets[pindex].title = ''
            tempbuild.presets[pindex].price = 0
            tempbuild.presets[pindex].totalprice = 0
            tempbuild = buildTotal(tempbuild)
        }
        settpBuild(tempbuild)
    }

    const getpresetlabel = (comp) => {
        let label = ""

        if (comp.presetid !== "") {
            if (comp.presetid === "other") {
                label = "Other"
            } else {
                label = presetData.filter(ps => ps.type === comp.type)[0].list.filter(item => item.presetid === comp.presetid)[0].kuro_title
            }
        }

        return label
    }

    const compOptions = (comp) => {
        const tempArray = []
        presetData !== null && comp.type !== "" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title})
            )
        })
        tempArray.push({value: "other", label: "Other"})
        return tempArray
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const createTPBuild = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let tpbuild = tpBuild
        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/tpbuilds', tpbuild, config).then(res => {
            settpBuild(res.data)
            history.push("/tpbuilds")
        })
    }

    const backHandler = () => {
        history.push("/tpbuilds")
    }

    return (
        <div className="tp_preset build">
            <div className='d-grid'>
                <div className='preset-tp'>
                    <div className='col_2'>
                        <p className="txt-light">TP Build</p>
                    </div>
                    <div className="note"><p>Instructions </p> 
                <img src={note} alt='instruction' className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div> 
                <div className="entity">
                    <span>TPbuild For:</span>
                    <select className="type" value={tpBuild.entity} onChange={(e) => updateTPBuild('entity', e)} >
                        {accesslevels.map((item, index) => (item.tp_builds === "edit" || item.tp_builds === "write")?(<><option key={index} value={item.entity}>{item.entity}</option></>):(null))
                        }
                    </select>
                </div>
                    {tpBuild !== null &&
                    <table className="home border even_odd tp_preset_details" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td colSpan="3">Build Title</td>
                                <td colSpan="1">Kuro SKQ</td>
                                <td colSpan="2">ASIN</td>
                                <td colSpan="2">Channel</td>
                            </tr>
                            <tr>
                                <td colSpan="3"><textarea className='lg_textarea' value={tpBuild.title} onChange={(e) => updateTPBuild(e, "title")} /></td>
                                <td colSpan="1"><input  value={tpBuild.kuro_skq} onChange={(e) => updateTPBuild(e, "kuro_skq")}/></td>
                                <td colSpan="2"><input  value={tpBuild.asin} onChange={(e) => updateTPBuild(e, "asin")}/></td>
                                <td colSpan="2">
                                    <select value={tpBuild.channel} onChange={(e) => updateTPBuild(e, "channel")}>
                                        <option value="">Select Channel</option>
                                        <option value="Amazon">Amazon</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>Component</td>
                                <td>Title</td>
                                <td>Price</td>
                                <td>Quantity</td>
                                <td>Total Price</td>
                                <td><span className="prod-span" onClick={() => addpreset(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span></td>
                            </tr>
                            {tpBuild.presets.map((comp, i) =>
                                <>
                            {comp.type !== "margin" &&
                            <tr key={i}>
                                <td>
                                    <select value={comp.type} onChange={(e) => updatepreset(i, "type", e)}>
                                        <option value="">Select Type</option>
                                        <option value="cpu">Processor</option>
                                        <option value="mob">Motherboard</option>
                                        <option value="ram">RAM</option>
                                        <option value="gpu">Graphics Card</option>
                                        <option value="psu">Power Supply</option>
                                        <option value="ssd">SSD</option>
                                        <option value="hdd">HDD</option>
                                        <option value="cooler">CPU Cooler</option>
                                        <option value="tower">Case</option>
                                        <option value="fans">Fan</option>
                                        <option value="wifi">WiFi</option>
                                        <option value="os">Operating System</option>
                                        <option value="shp_fees">Shipping Fees</option>
                                        <option value="build_fees">Build Fees</option>
                                        <option value="warranty">Warranty</option>
                                    </select>
                                </td>
                                    <td>
                                        <Select classNamePrefix="kuro-search-select" options={compOptions(comp)} value={{value: comp.presetid, label: getpresetlabel(comp)}} onChange={(e) => updateBuildComp(i, e)} placeholder="Select Component" className="react-select-container" />
                                    </td>
                                <td width={"350px"}>
                                    <textarea value={comp.title} onChange={(e) => updatepreset(i, "title", e)} />
                                </td>
                                <>
                                    {comp.type !== "margin" ? (
                                        <>
                                    <td width="110px">
                                        <input type="number" min="0" onWheel={disableScroll} value={comp.price} onChange={(e) => updatepreset(i, "price", e, true)} />
                                    </td>
                                    {comp.type !== "os" && comp.type !== "shp_fees" && comp.type !== "build_fees" && comp.type !== "warranty" ? (
                                    <td>
                                        <input type="number" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatepreset(i, "quantity", e, true)} />
                                    </td>
                                    ): (
                                        <td>NA</td>
                                    )}
                                        </>
                                    ) : (
                                        <>
                                        <td>
                                            <select value={comp.presetid} onChange={(e) => updatepreset(i, "presetid", e)}>
                                                {presetData !== null && comp.type !== "" && presetData.filter(ps => ps.type === comp.type)[0]["list"].map((item, k) =>
                                                <option key={k} value={item.presetid}>{item.kmar}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>NA</td>
                                        </>
                                    )}
                                    <td>
                                        {comp.totalprice}
                                    </td>
                                    <td>
                                        <span className="prod-span" onClick={() => addpreset(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"/></svg></span>
                                        <span className="prod-span" onClick={() => removepreset(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></span>
                                    </td>
                                </>
                            </tr>
                            }
                            </>
                            )}
                            <tr>
                                <td colSpan="3"></td>
                                <td colSpan="2">Build Comp Total</td>
                                <td>{tpBuild.comptotal}</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td colSpan="2">TP Commission</td>
                                <td>{tpBuild.tpcharges}</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td colSpan="2">Sub Total</td>
                                <td>{tpBuild.subtotal}</td>
                            </tr>
                            <tr>
                                <td  colSpan="2">KM(%)</td>
                                <td>
                                    <select value={tpBuild.presets.filter(comp => comp.type === "margin")[0].presetid} onChange={(e) => updatemargin(e)}>
                                        {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].map((item, k) =>
                                        <option key={k} value={item.presetid}>{item.title}</option>
                                        )}
                                    </select>
                                </td>
                                <td colSpan="2">Kuro Margin</td>
                                <td>{tpBuild.kmargin}</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td colSpan="2">Build Total</td>
                                <td>{tpBuild.buildtotal}</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td colSpan="2">Kuro Total</td>
                                <td><input onChange={(e) => updatekuroTotal(e)} value={tpBuild.kurototal} className="small"/></td>
                            </tr>
                        </tbody>
                    </table>
                    }
                    <ul className="btns">
                        <li><button onClick={createTPBuild}>Create TP Build</button></li>
                        <li><button onClick={backHandler}>Back</button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(TPBuild)