import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getVendors } from '../actions/admin'
import '../styles/table.css'
import moment from 'moment-timezone'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import DeleteBox from '../components/DeleteBox'
import KuroLink from '../components/common/KuroLink'
import { useHistory } from 'react-router-dom'

moment.tz.setDefault('Asia/Kolkata')

const InwardDebitNote = ({ match, user: { token, userDetails }, admin: { vendors, accesslevels }, getVendors }) => {

    const { params: { debitnoteid } } = match
    const [dndata, setdndata] = useState(null)
    const [updateddndata, setupdateddndata] = useState(null)
    const [editflag, seteditflag] = useState(false)
    const [loaddeletebox, setloaddeletebox] = useState(false)
    const [deleteid, setdeleteid] = useState("")
    const history = useHistory()
    const access = accesslevels.find(item => item.inward_debitnotes !== "NA")
    const editaccess = accesslevels.find(item => item.inward_debitnotes === "edit")

    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized")
        }
    }, [])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes?debitnoteid=' + debitnoteid, config).then(res => {
            setupdateddndata(res.data[0])
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [debitnoteid, getVendors])

    const editdebitnote = () => {
        setdndata(updateddndata)
        seteditflag(true)
    }

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updatedebitnote = (key, e) => {
        let tempdebitnote = { ...dndata }
        tempdebitnote[key] = e.target.value
        if (key === "gstin") {
            tempdebitnote.cgst = 0
            tempdebitnote.sgst = 0
            tempdebitnote.igst = 0
            tempdebitnote.totalprice = 0
        }
        setdndata(tempdebitnote)
    }

    const updatedebitnotedate = (date) => {
        let tempdebitnote = { ...dndata }
        tempdebitnote["debitnote_date"] = date
        setdndata(tempdebitnote)
    }

    const updateprice = (key, e) => {
        let tempdebitnote = { ...dndata }
        tempdebitnote[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempdebitnote[key])) {
            return tempdebitnote[key]
        }
        if (key === "totalprice" || key === "ittcs") {
            if (tempdebitnote.gstin === "") {
                tempdebitnote.cgst = 0
                tempdebitnote.sgst = 0
                tempdebitnote.igst = 0
            } else if (tempdebitnote.gstin.startsWith("36")) {
                tempdebitnote.cgst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.09, 2)
                tempdebitnote.sgst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.09, 2)
                tempdebitnote.igst = 0
            } else {
                tempdebitnote.cgst = 0
                tempdebitnote.sgst = 0
                tempdebitnote.igst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempdebitnote.sgst = e.target.value
        } else if (key === "igst") {
            tempdebitnote.cgst = 0
            tempdebitnote.sgst = 0
        }
        setdndata(tempdebitnote)
    }

    const submitdebitnote = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let debitnote = dndata

        debitnote["debitnote_no"] = debitnote["debitnote_no"].trim()

        for (let key of ["totalprice", "cgst", "sgst", "igst"]) {
            debitnote[key] = parseFloat(debitnote[key])
        }
        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes?update=update&debitnoteid=' + debitnote.debitnoteid, debitnote, config).then(res => {
            setupdateddndata(res.data[0])
        })
        setupdateddndata(debitnote)
        seteditflag(false)
    }

    const cancelupdates = () => {
        setdndata(updateddndata)
        seteditflag(false)
    }

    const deleteHandler = (debitnoteid) => {
        setloaddeletebox(true)
        setdeleteid(debitnoteid)
    }

    const deletedebitnote = (data) => {
        setloaddeletebox(false)
        setdeleteid("")
        history.push('/inward-debitnotes')

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.delete(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes?debitnoteid=' + data.debitnoteid, config)
    }

    const cancelHandler = () => {
        setloaddeletebox(false)
        setdeleteid("")
    }

    return (
        <div className="debitnote txt-light">
            <h2 className="txt-light-grey">Debit Note</h2>
            {editflag ? (
                [dndata !== null &&
                    <div className="edit-debitnote">
                        <table className='border table_mob' cellPadding="0" cellSpacing="0">
                            <tbody>
                            {userDetails.access === "Super" &&
                                <tr>
                                    <th>Debit Note ID</th>
                                    <td>{dndata.debitnoteid}</td>
                                </tr>
                            }
                                <tr>
                                    <th>Vendor</th>
                                    <td>{vendors !== null && vendors.filter(vendor => vendor.vendor_code === dndata.vendor)[0].name}</td>
                                </tr>
                                <tr>
                                    <th>Entity</th>
                                    <td>
                                        <select className="type" name="entity" value={dndata.entity} onChange={(e) => updatedebitnote("entity", e)} >
                                            {accesslevels.map((item, index) => (item.inward_creditnotes === "edit" || item.inward_creditnotes === "write") ? (<><option key={index} value={item.entity ? item.entity : null}>{item.entity}</option></>) : (null))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={dndata.gstin} onChange={(e) => updatedebitnote("gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && dndata.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === dndata.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Debit Note No.</th>
                                    <td>
                                        <input value={dndata.debitnote_no} onChange={(e) => updatedebitnote("debitnote_no", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Debit Note Date</th>
                                    <td>
                                        <DatePicker selected={dndata.debitnote_date === "" ? new Date() : new Date(dndata.debitnote_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updatedebitnotedate(date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Debit Note Total</th>
                                    <td>
                                        <input value={dndata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {(dndata.gstin.startsWith("36")) ? (
                                            <input value={dndata.cgst} onChange={(e) => updateprice("cgst", e)} />
                                        ) : (
                                            dndata.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {dndata.gstin === "" || dndata.gstin.startsWith("36") ? (
                                            dndata.igst
                                        ) : (
                                            <input value={dndata.igst} onChange={(e) => updateprice("igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>
                                        <select value={dndata.pay_status} onClick={(e) => updatedebitnote("pay_status", e)}>
                                            <option value="Payment Pending">Payment Pending</option>
                                            <option value="Paid">Paid</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>
                                        <select value={dndata.settled} onChange={(e) => updatedebitnote("settled", e)}>
                                            <option value="Yes">Yes</option>
                                            <option value="No" selected>No</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ul className="btns">
                            <li><button onClick={submitdebitnote}>Submit</button></li>
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="debitnote">
                    {editaccess && <ul className="btns">
                        <li><button onClick={editdebitnote}>Edit the Debit Note Details</button></li>
                    </ul>}
                    <p>&nbsp;</p>
                    {updateddndata !== null ? <table cellPadding="0" cellSpacing="0" className="border">
                        <tbody>
                            <tr>
                                <th>Debit Note ID</th>
                                <td>{updateddndata.debitnoteid}</td>
                            </tr>
                            <tr>
                                <th>Vendor</th>
                                <td>{vendors !== null && vendors.filter(vendor => vendor.vendor_code === updateddndata.vendor)[0]["name"] + ' [' + updateddndata.gstin + ']'}</td>
                            </tr>
                            <tr>
                                <th>entity</th>
                                <td>{updateddndata.entity}</td>
                            </tr>
                            <tr>
                                <th>Debit Note No.</th>
                                <td>{updateddndata.debitnote_no}</td>
                            </tr>
                            <tr>
                                <th>Debit Note Date</th>
                                <td>{updateddndata.debitnote_date === "" ? "" : moment(updateddndata.debitnote_date).format('DD-MM-YYYY')}</td>
                            </tr>
                            <tr>
                                <th>Debit Note Total</th>
                                <td>{CurrencyFormat(updateddndata.totalprice)}</td>
                            </tr>
                            <tr>
                                <th>CGST/SGST</th>
                                <td>{updateddndata.cgst}</td>
                            </tr>
                            <tr>
                                <th>IGST</th>
                                <td>{updateddndata.igst}</td>
                            </tr>
                            <tr>
                                <th>Payment Status</th>
                                <td>{updateddndata.pay_status}</td>
                            </tr>
                            <tr>
                                <th>Settled</th>
                                <td>{updateddndata.settled}</td>
                            </tr>
                        </tbody>
                    </table> : "loading ..."}
                    <p>&nbsp;</p>
                    <ul className='btns'>
                        {userDetails.access === "Super" && <li><button onClick={(e) => deleteHandler(updateddndata.debitnoteid)}>Delete Debit Note</button></li>}
                        <li><KuroLink to="/inward-debitnotes"><button>Back</button></KuroLink></li>
                    </ul>
                </div>
            )}
            {loaddeletebox && <DeleteBox msg="Are you sure you want to delete the Debit Note?" okhandler={deletedebitnote} cancelhandler={cancelHandler} data={{ "debitnoteid": deleteid }} />}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(InwardDebitNote)