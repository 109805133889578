import React, { useState, useEffect } from 'react';
import axios from 'axios';
import note from '../assets/img/button.png';
import EditableOrder from '../components/EditableOrder';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import admin from '../reducers/admin';


const Orders = ({ user: { userDetails }, admin: { accesslevels } }) => {
    const [orderData, setOrderData] = useState(null);
    const [limit, setLimit] = useState(50);
const history=useHistory();
const access=accesslevels.find(item => item.online_orders !== "NA");

useEffect(()=>{
if(access===undefined || access.length <= 0){
    history.push("/unauthorized")
    return;
}
}, [])
    useEffect(() => {
        // Fetch orders based on user's phone number and limit
        axios.get(`${process.env.REACT_APP_KG_API_URL}kuroadmin/orders?phone=9492540571&limit=${limit}`)
            .then(res => {
                setOrderData(res.data);
                // Adjust limit based on fetched data length
                if (res.data.length < limit) {
                    setLimit(0); // Set limit to 0 if all data has been fetched
                }
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setOrderData([]); // Set empty array or handle error state
            });
    }, [limit]);

    const LoadingOrders = () => (
        <div className='loading-wrapper'>
            <div className='loading-animation'></div>
        </div>
    );

    const limitHandler = (showAll) => {
        if (showAll) {
            setLimit(0); // Set limit to 0 to fetch all orders
        } else {
            setLimit(limit + 50); // Increase limit by 50 to fetch more orders
        }
    };

    return (
        <div className="orders txt-light-grey">
            <h2>Online Orders</h2>
            <div className="note">
                <p>Instructions</p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
            </div>
            <div>
                <p className='txt-right'>Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p><br /><br />
            </div>
            {orderData !== null ? (
                orderData.map((order) => (
                    <EditableOrder key={order.id} order={order} access={access}/> // Assuming order has a unique id
                ))
            ) : (
                <LoadingOrders />
            )}
            {limit !== 0 && orderData !== null ? (
                <ul className='btns'>
                    <li><button className='btn' onClick={() => limitHandler(false)}>Show More</button></li>
                    <li><button className='btn' onClick={() => limitHandler(true)}>Show All</button></li>
                </ul>
            ) : (
                <LoadingOrders />
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
});

export default connect(mapStateToProps)(Orders);
